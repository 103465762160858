import * as React from "react";
import CSSModules, { InjectedCSSModuleProps } from "react-css-modules";
import * as styles from "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CommonComponentProps } from "redi-types";
import { MergeStyles, shouldUpdate, autoBind, RegisterTheme, unwindFragments } from "redi-component-utils";
import { TwoWayFilter } from "redi-ui-utils";
import ClickOutside from "redi-click-outside";
import { ElementAnchor } from "redi-element-anchor";
import { Writeable, ElementRecursive } from "redi-types";

/**
 *
 *  Use:
 *
 *			<Select value={5} onChange={console.log}>
 *				<SelectItem key="a" value={5}>
 *					first
 *				</SelectItem>
 *				<SelectItem key="c" value={25}>
 *					second
 *				</SelectItem>
 *				<div>Any random element can appear within the menu</div>
 *				<SelectItem key="b" value={55}>
 *					third
 *				</SelectItem>
 *			</Select>
 *
 */

@RegisterTheme("redi-common-inputs/select")
@MergeStyles(styles)
export default class Select<T> extends React.Component<InjectedCSSModuleProps & SelectProps<T>, State> {
	static defaultProps: Partial<SelectProps<any>> = {
		value: "", // object | string
		zIndex: 5,
		showIcon: true,
		titleComponent: null, //component
		widthOfRoot: false, //make popup width of root elem
		absolutePosition: false, //use getAbsoluteBoundingRect to get select pos
		useShellPortal: false,
		disabled: false,
		showNone: true,

		menuWrapClass: "",
		menuClass: "",
		rowClass: ""
	};

	TitleComponent: new (p: Partial<SelectProps<T>>) => React.Component<Partial<SelectProps<T>>, any>;
	popup: React.RefObject<HTMLDivElement>;
	rowRefs: { [x: number]: SelectItem<T> };
	root: HTMLDivElement;

	private get getChildren() {
		return unwindFragments(React.Children.toArray(this.props.children as React.ReactElement) as any);
	}

	constructor(props: InjectedCSSModuleProps & SelectProps<T>) {
		super(props);
		autoBind(this);
		this.state = {
			open: false,
			currentFocus: 0
		};

		this.TitleComponent =
			this.props.titleComponent &&
			CSSModules(
				this.props.titleComponent,
				{ ...styles, ...this.props.styles },
				{
					allowMultiple: true
				}
			);

		this.popup = React.createRef<HTMLDivElement>();
		this.rowRefs = {};
	}

	onChange(e: React.MouseEvent | React.KeyboardEvent, val: T) {
		e.stopPropagation();
		this.props.onChange(val);
		this.close(e);
	}

	componentDidMount() {
		if (this.props.autoFocus) {
			setTimeout(() => {
				if (this.root) {
					this.root.focus();
				}
			});
		}
	}

	shouldComponentUpdate(nextProps: SelectProps<T>, nextState: State) {
		return shouldUpdate(this, nextProps, nextState, ["titleComponent", "titleTransform"]);
	}

	toggle(e: React.MouseEvent | MouseEvent) {
		this.props.noClickPropogation && e.stopPropagation();

		if (this.state.open) {
			this.close(e);
		} else if (!this.props.disabled) {
			this.setState({ open: true, currentFocus: 0 });
		}
	}

	rootRef(ref: HTMLDivElement) {
		this.root = ref;
		if (ref) {
			if (this.props.forwardedRef) {
				if (typeof this.props.forwardedRef === "function") {
					this.props.forwardedRef(ref);
				} else if ("current" in this.props.forwardedRef) {
					this.props.forwardedRef.current = ref;
				} else {
					throw new Error("Incorrect ref argument passed to Select");
				}
			}
		}
	}

	onFocus(e: React.FocusEvent) {
		if (!this.state.open && !this.props.disabled) {
			// Opening the menu is going to blur the el. It will be focused back when closed.
			this.setState({ open: true, currentFocus: 0 });
			this.props.onFocus && this.props.onFocus(e);
		}
	}

	onBlur(e: React.FocusEvent) {
		if (this.state.open) {
			this.setState({ open: false, currentFocus: 0 });
			this.props.onBlur && this.props.onBlur(e);
		}
	}

	close(e: any) {
		this.setState({ open: false });
		this.props.onBlur && this.props.onBlur(e);
	}

	handleKeyDown(event: React.KeyboardEvent) {
		if (this.state.open) {
			event.preventDefault();
			if (event.keyCode == 32 || event.key === "Enter") {
				//32 = space
				this.onChange(event, this.rowRefs[this.state.currentFocus].props.value);
			} else {
				switch (event.key) {
					case "Tab":
						this.close(event);
						break;
					case "ArrowDown": {
						let focus = this.state.currentFocus + 1;
						if (focus < React.Children.count(this.getChildren)) {
						} else {
							// wrap to start
							focus = 0;
						}
						this.setState({ currentFocus: focus });
						break;
					}
					case "ArrowUp":
						let focus = this.state.currentFocus - 1;
						if (focus >= 0) {
						} else {
							// wrap to end
							focus = React.Children.toArray(this.getChildren).filter((x:any) => x.type === SelectItem).length - 1;
						}
						this.setState({ currentFocus: focus });
						break;
				}
			}
		}
	}

	undefValue() {
		// return false if value === 0
		return this.props.value === null || this.props.value === undefined || (this.props.value as any) === "";
	}

	/** see if the prop value has a two way link */
	checkValueLink(): T | React.ReactNode {
		if (this.props.twoWayFilter && this.props.value !== null && this.props.value !== undefined) {
			return this.props.twoWayFilter.swap(this.props.value);
		} else {
			return this.props.value;
		}
	}

	/** see if the row value has a two way link */
	checkLink(item: T) {
		if (this.props.twoWayFilter && item !== null && item !== undefined) {
			return this.props.twoWayFilter.swap(item);
		} else {
			return item;
		}
	}

	renderMenuItems() {
		if (this.state.open) {
			const kids = this.getChildren;
			return kids.map((el: React.ReactElement<SelectItemProps<T>>, index) => {
				if (el.type === SelectItem) {
					const existingClick = el.props.onClick;
					const existingMouseEnter = el.props.onMouseEnter;
					return React.cloneElement(el, {
						className: [
							this.props.styles["_row"],
							el.props.className,
							...this.props.rowClass.split(" ").map(x => this.props.styles[x])
						].join(" "),
						onClick: (e: React.MouseEvent<HTMLDivElement>) => {
							this.onChange(e, el.props.value);
							existingClick && existingClick(e);
						},
						onMouseEnter: (e: React.MouseEvent<HTMLDivElement>) => {
							this.setState({ currentFocus: index });
							existingMouseEnter && existingMouseEnter(e);
						},
						ref: (ref: SelectItem<T>) => {
							this.rowRefs[index] = ref;
						},
						isFocussed: index === this.state.currentFocus
					});
				} else {
					return el;
				}
			});
		}
	}

	render() {
		this.rowRefs = {};
		return (
			<ClickOutside
				onClick={(e: React.MouseEvent) => {
					if (this.state.open) {
						this.toggle(e);
					}
				}}
			>
				<div
					className={this.props.className}
					styleName="_root"
					ref={this.rootRef}
					onMouseDown={e => {
						// stop initial focus
						e.preventDefault();
					}}
					onClick={() => {
						if (!this.state.open && !this.props.disabled) {
							// now focus
							this.root.focus();
							this.setState({ open: true, currentFocus: 0 });
						}
					}}
					onKeyDown={this.handleKeyDown}
					onFocus={this.onFocus}
					onBlur={this.onBlur}
					tabIndex={0}
					select="1"
					isopen={this.state.open ? "true" : "false"}
					disabled={this.props.disabled ? "1" : undefined}
				>
					{this.props.titleComponent ? (
						<this.TitleComponent {...this.props} />
					) : (
						<React.Fragment>
							{this.props.placeholder && this.undefValue() ? (
								<div styleName="_select-placeholder" selecttitle="1">
									{this.props.placeholder}
								</div>
							) : (
								<div styleName="_select_title" selecttitle="1">
									{this.props.titleTransform ? this.props.titleTransform(this.props.value) : this.checkValueLink()}
								</div>
							)}
							{this.props.showIcon && this.state.open && <FontAwesomeIcon icon="chevron-up"/>}
							{this.props.showIcon && !this.state.open && <FontAwesomeIcon icon="chevron-down"/>}
						</React.Fragment>
					)}
					<ElementAnchor
						show={this.state.open}
						anchor={this.root}
						position="bottom-left"
						zIndex={this.props.zIndex}
						useShellPortal={this.props.useShellPortal}
						widthOfAnchor={this.props.widthOfRoot}
					>
						<div styleName={`_menu-wrap ${this.props.menuWrapClass}`} open={this.state.open} ref={this.popup} selectpopup="1">
							<div styleName={`_menu ${this.props.menuClass}`} style={{ maxHeight: Math.min(window.innerHeight - 20, 500) }}>
								{this.renderMenuItems()}
								{this.props.showNone && React.Children.count(this.props.children) === 0 && (
									<div styleName={`_row ${this.props.rowClass}`} onClick={this.toggle}>
										<i styleName="none">None</i>
									</div>
								)}
							</div>
						</div>
					</ElementAnchor>
				</div>
			</ClickOutside>
		);
	}
}

export interface SelectProps<T> extends CommonComponentProps {
	/** Two way filters where right side is the dropdown display value and `T` is the actual value stored in `props.value` */
	twoWayFilter?: TwoWayFilter<T, React.ReactNode>;
	value: T;
	onChange: (s: T) => void;
	titleTransform?: (item: T, actions?: T[]) => React.ReactNode; //select title or name from value
	zIndex?: number;
	/** text to show if value === null */
	placeholder?: string;
	showIcon?: boolean;
	titleComponent?: new (p: Partial<SelectProps<T>>) => React.Component<Partial<SelectProps<T>>, any>; //component
	widthOfRoot?: boolean; //make popup width of root elem
	absolutePosition?: boolean; //use getAbsoluteBoundingRect to get select pos
	noClickPropogation?: boolean;
	onFocus?: (e: React.FocusEvent | MouseEvent) => void;
	onBlur?: (e: React.FocusEvent | MouseEvent) => void;
	/** useShellPortal on `ElementAnchor` */
	useShellPortal?: boolean;
	disabled?: boolean;
	autoFocus?: boolean;
	/** Show `None` if no menu items */
	showNone?: boolean;

	/** Children must be `SelectItem` elements */
	children: ElementRecursive<SelectItemProps<T>>;

	menuWrapClass?: string;
	menuClass?: string;
	rowClass?: string;
}

interface State {
	open: boolean;
	currentFocus: number;
}

export interface SelectItemProps<T> extends React.HTMLAttributes<HTMLDivElement> {
	key: React.ReactText;
	value: T;
	forwardedRef?: Writeable<React.RefObject<any>> | ((ref: any) => void);
}

/**
 * Represents a menu item within a `Select` menu. The value of `props.value` will be set as the value when the menu item is clicked
 */
export class SelectItem<T> extends React.PureComponent<SelectItemProps<T>> {
	render() {
		const { value, isFocussed, forwardedRef, ...props } = this.props;
		return (
			<div {...props} ref={forwardedRef} isfocussed={isFocussed ? "1" : undefined}>
				{this.props.children}
			</div>
		);
	}
}
