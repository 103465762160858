import * as React from "react";
import config from "config/config";
import { MergeStyles, shouldUpdate } from "utils";
import * as styles from "./styles.scss";
import * as PropTypes from "prop-types";
import { CommonComponentProps } from "redi-types";
import autoBind from "libs/react-autobind";
import { MediaFileLink } from "services/media/mediatypes";
import FileUpload from "../file/Upload";

@MergeStyles(styles)
export default class FilePreview extends React.Component<Props, State> {
	static defaultProps = {
		files: [],
		onChange: null,
		allowUpload: false,
		disabled: false,
		classes: null //styleName overrides
	};
	static propTypes = {
		files: PropTypes.array.isRequired,
		onChange: function(props, propName, componentName) {
			//required if sorting or paging (for sorting)
			const test = props.allowUpload ? PropTypes.func.isRequired : PropTypes.func;
			const result = test.apply(this, arguments);
			if (result) {
				result.message = "The prop `onChange` is required in `ImagePreview` if `allowUpload` is set to true";
			}
			return result;
		}
	};

	constructor(props) {
		super(props);
		autoBind(this);
		this.state = {};
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["onChange"]);
	}

	downloadFile(url: string, fileName: string) {
		fetch(url, {
			headers: new Headers({
			  'Origin': location.origin
			}),
			mode: 'cors'
		})
		.then((response) => response.blob())
		.then((blob) => {
			// Create blob link to download
			const downloadUrl = window.URL.createObjectURL(
			new Blob([blob]),
			);
		
			const a = document.createElement('a');
			a.download = fileName;
			a.href = downloadUrl;
			document.body.appendChild(a);
			a.click();
			a.remove();
		});
	}

	render() {
		return (
			<div className={this.props.className} styleName="_img_root">
				{this.props.allowUpload && (
					<FileUpload
						fileType="File"
						fileTypeRegex={/\.(doc|txt|csv|xlsx|docx|pdf|json|xml)$/i}
						inputFilter={".doc,.docx,.xml,.txt,.csv,.xlsx,.pdf,.json,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"}
						onChange={(files: MediaFileLink[]) => {
							this.props.onChange(this.props.files.concat(files));
						}}
					/>
				)}
				<div styleName="_img_preview" style={{flexDirection: "column", alignItems: "flex-start"}}>
					{this.props.files.map((x, i) => {
						return (
							<div key={"_file_upload_"+x.size+i} style={{marginBottom: "5px"}}>
								<div>
								{x.amazonUrl ? 
								<a style={{color: "blue", cursor: "pointer", textDecoration: "underline"}} onClick={() => this.downloadFile(config.amazonUrl + x.amazonUrl, x.name)}>{x.name}</a> :
								<a style={{color: "blue", cursor: "pointer", textDecoration: "underline"}} onClick={() => this.downloadFile(x.data, x.name)}>{x.name}</a>}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

interface Props extends CommonComponentProps {
	files: MediaFileLink[];
	onChange?: (newFiles: MediaFileLink[]) => void;
	allowUpload?: boolean;
	disabled?: boolean;
}

interface State {}
