import { http, DateTime, ManualPromise } from "../utils/index";
import config from "config/config";
import showToast from "components/Toast/Toast";
import { LocationDto } from "redi-types";

const route = "Vehicle/";
let canceller;
export default class vehicleservice {
	static GetClosestVehicles(location: LocationDto, searchAll?: boolean, query?: string) {
		if (canceller) {
			canceller.Reject();
		}

		canceller = new ManualPromise();

		let url = `${config.baseUrl + route}GetClosestVehicles`;

		return http({ url, method: "POST", data: location, searchAll, query}, false, canceller)
			.then(data => data)
			.catch(error => {
				if (error.error.type !== "HTTP Cancelled") {
					showToast("error", error.error);
				}
				return error;
			});
	}
}
