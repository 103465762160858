//initial redux
import { put, call, takeEvery, fork, all, take, cancel, select, takeLatest } from "redux-saga/effects";
import securityservice from "services/security/security";
import { types as setuptypes } from "boot/setupRedux";
import * as router from "connected-react-router";
import { persist, string } from "utils/index";
import { Action } from "redux";
import { ReduxAction, JobDto, FullJobDto, WorkflowSequenceDto } from "redi-types";
import scheduleservice from "services/schedule";
import jobservice from "services/job";
import config from "config/config";
import { stat } from "fs";
import { element } from "prop-types";
import {WorkflowStatuses} from "../ui/Job/Job";
import { HeaderButton } from "ui/Forms/Entry/Hoc";
import { dispatch } from "boot/configureStore";
import { push } from 'connected-react-router';
import {checkAuth} from "components/Auth/Auth";

const initialState = {
    job: null,
    sequences: [],
    nextStatuses: [],
    leftButtons: [],
    rightButtons: [],
    formValid: false,
    backRoute: null,
    error: "",
    isBusy: false
};

const persistConf = {
    whitelist: [],
	expireInNumHours: 0 //dont expire
};

const WorkflowStatus = {
    WORKFLOW_START: "WORKFLOW_START",
    WORKFLOW_END: "WORKFLOW_END",
    WORKFLOW_FORK: "WORKFLOW_FORK",
    NewForm: "NewForm",
    AwaitingAllocation: "AwaitingAllocation",
    AllocationRejected: "AllocationRejected",
    Scheduled: "Scheduled",
    InProgress: "InProgress",
    Closed: "Closed",
    Cancelled: "Cancelled",
    AwaitingInvoice: "AwaitingInvoice",
};

const LOCAL_STORAGE_JOB_KEY = "CS-Job";

/////////
//types
export const types = {
    LOAD_JOB: "job/LOAD_JOB",
    SET_JOB: "job/SET_JOB",
    UPDATE_STATUS: "job/UPDATE_STATUS",
    DELETE_JOB: "job/DELETE_JOB",
    SET_IS_BUSY: "job/SET_IS_BUSY",
    SET_NEXT_STATUSES: "job/SET_NEXT_STATUSES",
    UPDATE_NEXT_STATUSES: "job/UPDATE_NEXT_STATUSES",
    SET_LEFT_BUTTONS: "job/SET_LEFT_BUTTONS",
    SET_RIGHT_BUTTONS: "job/SET_RIGHT_BUTTONS",
    SET_FORM_VALID: "job/SET_FORM_VALID",
    SET_COMPANY_ID: "job/SET_COMPANY_ID",
    SET_TECHNICIAN_ID: "job/SET_TECHNICIAN_ID",
    SET_BACK_ROUTE: "job/SET_BACK_ROUTE",
    SET_WORKFLOW_SEQUENCE: "job/SET_WORKFLOW_SEQUENCE",
    ON_ERROR: "job/ON_ERROR"
};

///////////
//reducers
const reducers = {
	reducer(state = initialState, action: ReduxAction) {
		switch (action.type) {
            case types.SET_JOB:
                return {...state, job: action.payload.job}
            case types.LOAD_JOB:
                return state;
            case types.UPDATE_STATUS:
                return state;
            case types.DELETE_JOB:
                return state;
            case types.SET_NEXT_STATUSES:
                return {...state, nextStatuses: action.payload.nextStatuses};
            case types.SET_LEFT_BUTTONS:
                return {...state, leftButtons: action.payload.leftButtons};
            case types.SET_RIGHT_BUTTONS:
                return {...state, rightButtons: action.payload.rightButtons};
            case types.SET_FORM_VALID:
                return {...state, formValid: action.payload.formValid};
            case types.SET_IS_BUSY:
                return {...state, isBusy: action.payload.isBusy}
            case types.SET_COMPANY_ID:
                return {...state, job: {...state.job, companyId: action.payload.companyId}};
            case types.SET_TECHNICIAN_ID:
                return {...state, job: {...state.job, technicianId: action.payload.technicianId}};
            case types.SET_BACK_ROUTE:
                return {...state, backRoute: action.payload.backRoute};
            case types.SET_WORKFLOW_SEQUENCE:
                return {...state, job: {...state.job, workflowStatusCode: action.payload.workflowSequence.workflowStatusCode, workflowSequenceId: action.payload.workflowSequence.workflowSequenceId}};
            case types.ON_ERROR:
                return {...state, error: action.payload.error}
			default:
				return state;
		}
	}
};
export const reducer = persist("job", reducers.reducer, persistConf);

let backFunc = function(uri: string): (e: any) => void{
    let derp: string = (' ' + uri).slice(1);
    return function(e: any){
        dispatch(push(derp));
    };
};

//////////
//sagas
export const sagas = {
	*rootSaga() {
		yield all([
            this.onCompanyChange(),
            this.onTechnicianChange(),
            this.onLoadJob(),
            this.onUpdateStatusCode(),
            this.updateButtons(),
            this.updateNextStatuses(),
            this.onDeleteJob()
		]);
    },
    *onCompanyChange(){
        yield takeLatest<ReduxAction>(types.SET_COMPANY_ID, function*({payload}){
            let job: FullJobDto = yield select<any>(x => x.job.job);
            yield call(jobservice.UpdateCompany, job.jobId, payload.companyId);
        });
    },
    *onTechnicianChange(){
        yield takeLatest<ReduxAction>(types.SET_TECHNICIAN_ID, function*({payload}){
            let job: FullJobDto = yield select<any>(x => x.job.job);
            yield call(jobservice.UpdateTechnician, job.jobId, payload.technicianId);
        });
    },
    *updateButtons(){
        yield takeLatest<ReduxAction>([types.SET_NEXT_STATUSES, types.SET_FORM_VALID], function*({payload}){
            let leftButtons: Array<HeaderButton> = [];
            let rightButtons: Array<HeaderButton> = [];
            let next: Array<WorkflowSequenceDto> = yield select<any>(x => x.job.nextStatuses);
            let formValid: boolean = yield select<any>(x => x.job.formValid);
            let job: FullJobDto = yield select<any>(x => x.job.job);
            if(next && next.length){
                for(var ii = 0; ii < next.length; ii++){
                    let wkSequence: WorkflowSequenceDto = next[ii];
                    let updateFunc = function(e: any){
                        dispatch(actions.updateStatus(job.jobId, wkSequence.workflowStatusCode));
                    };
                    leftButtons.push({
                        theme: "primary",
                        text: wkSequence.customDisplay || wkSequence.workflowStatusCode,
                        style: {},
                        onClick: updateFunc,
                        disabled: !formValid,
                        nfi: wkSequence.customDisplay || wkSequence.workflowStatusCode,
                    });
                }
            }
            var uri = yield select<any>(x => x.job.backRoute);
            if(!uri){
                let authed: boolean = checkAuth("SystemAdmin",{disable: false, checkLogin: true,extender: null, args: null });
                uri = authed ? "/Schedule" : "/TechSchedule";
            }
            let buttonText = "< JOB LIST";
            if(uri == "/Schedule"){

            }else if(uri == "/TechSchedule"){
                buttonText = buttonText + "  ";
            }else if(uri == "/CompanySchedule"){
                buttonText = buttonText + " ";
            }
            rightButtons.push({
                theme: "secondary",
                text: "< JOB LIST",
                style: {},
                onClick: backFunc(uri),
                disabled: false,
                nfi: uri
            });
            yield put(actions.setLeftButtons(leftButtons));
            yield put(actions.setRightButtons(rightButtons));
        });
    },
    *onUpdateStatusCode(){
        yield takeLatest<ReduxAction>(types.UPDATE_STATUS, function*({payload}){
            let jobId: string = payload.jobId;
            let workflowStatusCode: string = payload.workflowStatusCode;
            yield put(actions.setIsBusy(true));
            let job: FullJobDto = yield select<any>(x => x.job.job);
            let sequences: Array<WorkflowSequenceDto> = job.workflowSequences;
            let nextSequences: Array<WorkflowSequenceDto> = sequences.filter(s => s.previousWorkflowSequenceId == job.workflowSequenceId);
            let nextSequence: WorkflowSequenceDto = null;
            for(var ii = 0; ii < nextSequences.length; ii++){
                if(nextSequences[ii].workflowStatusCode == workflowStatusCode){
                    nextSequence = nextSequences[ii];
                }else if(nextSequences[ii].workflowStatusCode == WorkflowStatus.WORKFLOW_FORK){
                    let forkedSequences: Array<WorkflowSequenceDto> = sequences.filter(s => s.previousWorkflowSequenceId == nextSequences[ii].workflowSequenceId);
                    for(var jj = 0; jj < forkedSequences.length; jj++){
                        if(forkedSequences[jj].workflowStatusCode == workflowStatusCode){
                            nextSequence = forkedSequences[jj];
                            break;
                        }
                    }
                }
                if(nextSequence){
                    break;
                }
            }

            if(!nextSequence){
                throw new Error("Cannot move job into workflow status " + workflowStatusCode + " from the current workflow status of " + job.workflowStatusCode);
            }

            yield put(actions.setWorkflowSequence(nextSequence));

            yield call(jobservice.UpdateStatus, jobId, workflowStatusCode);

            if(workflowStatusCode == WorkflowStatus.Closed || workflowStatusCode == WorkflowStatus.Cancelled || workflowStatusCode == WorkflowStatus.AwaitingAllocation){
                let uri = yield select<any>(x => x.job.backRoute);
                yield put(router.push(uri));
            }else{
                yield put(actions.updateNextStatuses(job.workflowSequences, workflowStatusCode));
                //location.reload();
                //NOTE: reload page (routing to same page does nothing)
                //let uri: string = "/Job/" + jobId;
                //yield put(router.push(uri));
            }
            yield put(actions.setIsBusy(false));
        });
    },
    *onDeleteJob(){
        yield takeLatest<ReduxAction>(types.DELETE_JOB, function*({payload}){
            let jobId: string = payload.jobId;
            yield put(actions.setIsBusy(true));
            yield call(jobservice.DeleteJob, jobId);
            yield put(actions.setIsBusy(false));
            let uri = yield select<any>(x => x.job.backRoute);
            yield put(router.push(uri));
        });
    },
    *onLoadJob(){
        yield takeLatest<ReduxAction>(types.LOAD_JOB, function*({payload}){
            yield put(actions.setIsBusy(true));
            let storedJobString: string  = localStorage.getItem(LOCAL_STORAGE_JOB_KEY);
            let storedJob: FullJobDto = null;
            if(storedJobString){
                storedJob = JSON.parse(storedJobString);
            }
            let jobId: string = payload.jobId;
            let data = yield call(jobservice.GetJob, jobId);
            if (data.error) {
				console.error(data.error);
				yield put(actions.onError(data.error));
			} else {
                yield put(actions.setJob(data.data));
            }

            let job: FullJobDto = data.data;
            let nextSequences = [];
            if(!job || !job.workflowSequences){
                yield put(actions.setNextStatuses(nextSequences));
                return;
            }
            yield put(actions.updateNextStatuses(job.workflowSequences, job.workflowStatusCode));
            yield put(actions.setIsBusy(false));
        });
    },
    *updateNextStatuses(){
        yield takeLatest<ReduxAction>(types.UPDATE_NEXT_STATUSES, function*({payload}){
            let nextSequences = [];
            let sequences = payload.statuses;
            let workflowStatusCode = payload.workflowStatusCode;
            let currentSequence = sequences.find(s => s.workflowStatusCode == workflowStatusCode);
            for(var ii = 0; ii < sequences.length; ii++){
                let wkSequence = sequences[ii];
                if(wkSequence.previousWorkflowSequenceId == currentSequence.workflowSequenceId){
                    if(wkSequence.workflowStatusCode == WorkflowStatuses.WORKFLOW_FORK){
                        let forked: Array<WorkflowSequenceDto> = sequences.filter(s => s.previousWorkflowSequenceId == wkSequence.workflowSequenceId);
                        forked.forEach(s => {
                            if(!nextSequences.find(t => t.workflowStatusCode == s.workflowStatusCode)){
                                nextSequences.push(s);
                            }
                        });
                    }else if(wkSequence.workflowStatusCode != WorkflowStatuses.WORKFLOW_START && wkSequence.workflowStatusCode != WorkflowStatuses.WORKFLOW_END){
                        if(!nextSequences.find(s => s.workflowStatusCode == wkSequence.workflowStatusCode)){
                            nextSequences.push(wkSequence);
                        }
                    }
                }
            }
            yield put(actions.setNextStatuses(nextSequences));
        });
    }
};

////////
//actions
export const actions = {
    loadJob(jobId: string){
        return {
            type: types.LOAD_JOB,
            payload: {jobId}
        };
    },
    setCompanyId(companyId: string){
        return {
            type: types.SET_COMPANY_ID,
            payload: {companyId}
        };
    },
    setTechnicianId(technicianId: string){
        return {
            type: types.SET_TECHNICIAN_ID,
            payload: {technicianId}
        };
    },
    setNextStatuses(nextStatuses: Array<WorkflowSequenceDto>){
        return {
            type: types.SET_NEXT_STATUSES,
            payload: {nextStatuses}
        };
    },
    updateNextStatuses(statuses: Array<WorkflowSequenceDto>, workflowStatusCode: string) {
        return {
            type: types.UPDATE_NEXT_STATUSES,
            payload: {statuses, workflowStatusCode}
        };
    },
    setFormValid(formValid: boolean){
        return {
            type: types.SET_FORM_VALID,
            payload: {formValid}
        };
    },
    setLeftButtons(leftButtons: Array<HeaderButton>){
        return {
            type: types.SET_LEFT_BUTTONS,
            payload: {leftButtons}
        };
    },
    setRightButtons(rightButtons: Array<HeaderButton>){
        return {
            type: types.SET_RIGHT_BUTTONS,
            payload: {rightButtons}
        };
    },
    setJob(job: FullJobDto){
        return {
            type: types.SET_JOB,
            payload: {job}
        };
    },
    updateStatus(jobId:string, workflowStatusCode: string){
        return {
            type: types.UPDATE_STATUS,
            payload: {jobId, workflowStatusCode}
        };
    },
    deleteJob(jobId:string){
        return {
            type: types.DELETE_JOB,
            payload: {jobId}
        };
    },
    setIsBusy(isBusy: boolean){
        return {
            type: types.SET_IS_BUSY,
            payload: {isBusy}
        };
    },
    setBackRoute(backRoute: string){
        return {
            type: types.SET_BACK_ROUTE,
            payload: {backRoute}
        };
    },
    setWorkflowSequence(workflowSequence: WorkflowSequenceDto){
        return {
            type: types.SET_WORKFLOW_SEQUENCE,
            payload: {workflowSequence}
        };
    },
	onError(error) {
		return {
			type: types.ON_ERROR,
			payload: error
		};
	}
};
