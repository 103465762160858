import * as React from "react";
import * as PropTypes from "prop-types";
import autoBind from "libs/react-autobind/index";
import { CommonComponentProps } from "redi-types";
import * as ReactDOM from "react-dom";

/**
	Portal all children elements to the `PortalAnchor` that matches `this.props.to`.

	Many `Portals` can point to one `PortalAnchor`
*/
export class Portal extends React.PureComponent<PortalProps, PortalState> {
	static defaultProps = {};
	static propTypes = {
		to: PropTypes.string.isRequired
	};

	constructor(props) {
		super(props);
		autoBind(this);
		portalStore.push({ id: this.props.to, portal: this });
		this.state = {
			anchor: this.tryFindAnchors()
		};
	}

	port(anchor: IAnchor) {
		this.setState({ anchor });
	}

	rerender() {
		this.forceUpdate();
	}

	private tryFindAnchors() {
		if (this.props.to in anchorStore && anchorStore[this.props.to].hasNode) {
			return anchorStore[this.props.to];
		}
	}

	render() {
		if (this.props.disable || (this.state.anchor && this.state.anchor.disabled)) {
			if (this.props.showNothingOnDisable) {
				return null;
			} else {
				return this.props.children || null;
			}
		} else if (this.state.anchor) {
			return ReactDOM.createPortal(this.props.children, this.state.anchor.node);
		} else {
			return null;
		}
	}

	componentWillUnmount() {
		portalStore.splice(portalStore.findIndex(x => x.portal === this), 1);
	}
}

interface PortalState {
	anchor: IAnchor;
}

interface PortalProps extends CommonComponentProps {
	to: string;
	/**Dont port child elements */
	disable?: boolean;
	/**If disabled, or the anchor is disabled, render nothing instead of rendering in place */
	showNothingOnDisable?: boolean;
}

/*=========================================================================*/

/**
 * Any `Portal`s that match this anchors id shall have their child elements ported to the sole child element of this component.
 *
 * This component must have exactly one child element where elements can be ported to
 */
export class PortalAnchor extends React.PureComponent<PortalAnchorProps, PortalAnchorState> {
	static defaultProps = {};
	static propTypes = {
		id: PropTypes.string.isRequired
	};
	unmounted: boolean;

	constructor(props) {
		super(props);
		autoBind(this);
		if (!this.props.id) {
			throw new Error("Portal anchor id null");
		}

		if (this.props.id in anchorStore) {
			throw new Error("Multiple Portal anchors with the same id detected. " + this.props.id);
		}

		anchorStore[this.props.id] = {
			hasNode: false,
			disabled: this.props.disable
		};
	}

	cloneChildren() {
		if (this.props.children) {
			//anchor must only have one child
			const child = React.Children.only<React.ReactElement<any>>(this.props.children as any);

			return React.cloneElement(child, {
				ref: (ref: Element) => {
					if (!this.unmounted) {
						anchorStore[this.props.id].hasNode = true;
						anchorStore[this.props.id].node = ref;
						anchorStore[this.props.id].disabled = this.props.disable;

						this.notifyPortals(anchorStore[this.props.id]);

						if (typeof child.props.ref === "object") {
							if ("current" in child.props.ref) {
								child.props.ref.current = ref;
							}
						} else if (child.props.ref) {
							//is function
							child.props.ref(ref);
						}
					}
				},
				"is-portal": this.props.id
			});
		} else {
			return null;
		}
	}

	notifyPortals(ref: IAnchor) {
		for (let index = 0; index < portalStore.length; index++) {
			const portal = portalStore[index];

			if (portal.id === this.props.id) {
				portal.portal.port(ref);
			}
		}
	}

	rerenderPortals() {
		for (let index = 0; index < portalStore.length; index++) {
			const portal = portalStore[index];

			if (portal.id === this.props.id) {
				portal.portal.rerender();
			}
		}
	}

	render() {
		return this.cloneChildren();
	}

	componentDidUpdate(prevProps: PortalAnchorProps, prevState: PortalAnchorState) {
		if (this.props.disable !== prevProps.disable) {
			anchorStore[this.props.id].disabled = this.props.disable;
			this.rerenderPortals();
		}
	}

	componentWillUnmount() {
		this.unmounted = true;
		delete anchorStore[this.props.id];
	}
}

interface PortalAnchorState {}

interface PortalAnchorProps extends CommonComponentProps {
	id: string;
	disable?: boolean;
}

/*=========================================================================*/

interface AnchorStore {
	[id: string]: IAnchor;
}

interface IAnchor {
	node?: Element;
	hasNode: boolean;
	disabled: boolean;
}

interface PortalStore {
	id: string;
	portal: Portal;
}

let anchorStore: AnchorStore = {};
let portalStore: PortalStore[] = [];
