import { FormDataField, FormSubmissionField } from "redi-types";
import { array } from "utils";
import * as uuid4 from "uuid/v4";

/**
 * Create a formsubmission data field from a FormDataField
 *
 * recursivly create subfields so the entry form generates properly
 * @param f
 * @param i
 */
export function createFormSubmissionField(f: FormDataField, i: number): FormSubmissionField {
	if (f.fieldType === "RepeatingGroup") {
		return {
			id: f.id,
			submissionFieldId: uuid4(),
			includeInSummary: f.includeInSummary,
			isReference: f.isReference,
			recordValueinSubmissionKeyValue: f.recordValueinSubmissionKeyValue,
			fieldType: f.fieldType,
			recordValueInSubmissionFieldValue: false,
			//generate minRowsRequired amount of table rows. result is an array of arrays for table rows then cells
			subGroupedValues: array.fill(f.minRowsRequired || 0, () => f.subFields.map(createFormSubmissionField)),
			rowIndex: i,
			fieldOptions: {
				groupByColumns: f.groupByColumns,
				required: f.isRequired,
				hide: f.hide
			}
		};
	} else {
		return {
			id: f.id,
			submissionFieldId: uuid4(),
			includeInSummary: f.includeInSummary,
			isReference: f.isReference,
			recordValueinSubmissionKeyValue: f.recordValueinSubmissionKeyValue,
			recordValueInSubmissionFieldValue: f.fieldType === "TypeaheadText",
			value: f.defaultValue,
			fieldType: f.fieldType,
			fieldOptions: {
				groupByColumns: f.groupByColumns,
				required: f.isRequired,
				hide: f.hide
			},
			subGroupedValues: f.fieldType === "Matrix" ? f.matrixFields.map(x => x.fields.map(createFormSubmissionField)) : undefined
		};
	}
}
