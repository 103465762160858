import React, { PureComponent } from "react";
import { Field, reduxForm, formValueSelector, reset } from "redux-form";
import styles from "./styles.scss";
import CSSModules from "react-css-modules";
import PropTypes from "prop-types";
import RadioButton from "components/Radio/radio";
import Select from "components/Select/select";
import { DateTime, TimeRange, array, shouldUpdate, MergeStyles } from "utils/index";
import TimeSliderImpl from "components/TimeSlider/timeslider";
import Checkbox from "components/Checkbox/checkbox";
import { Calendar, DateRangePicker } from "libs/react-date-range/index";
import Button from "components/Button/Button";
import MultiSelect from "components/MultiSelect/multiselect";
import Radio from "components/Radio/radio";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class FormTimePicker extends React.PureComponent {
	static defaultProps = {
		inputClass: ""
	};
	render() {
		let {
			input,
			label,
			required,
			meta: { touched, error },
			disabled,
			...other
		} = this.props;

		if (input.value === "true") {
			//default to now
			input.onChange(DateTime.format(new Date(), "HH:mm"));
			//return nothing until rerender with default val
			return null;
		}

		return (
			<div className={other.className} styleName="input-group">
				<div formlabel="1">
					{label}
					{required && <span> *</span>}
				</div>
				<input
					size={1}
					styleName={`_form_input ${this.props.inputClass}`}
					label={label}
					{...input}
					type="time"
					style={{ fontFamily: "inherit" }}
					// fallback pattern
					pattern="[0-9]{2}:[0-9]{2}"
					disabled={disabled}
				/>
				{touched && error && <div styleName="_input-error">{error}</div>}
			</div>
		);
	}
}

export const RenderTimePicker = MergeStyles(styles, { forwardRef: false })(FormTimePicker);
