import * as React from "react";
import { connect } from "react-redux";

import * as CSSModules from "react-css-modules";
import { AuthHoC } from "components/Auth/Auth";
import { CommonUIProps, JobTypeDto, WorkflowSummaryDto, WorkflowStatusSummaryDto, JobSummaryDto, CurrentDetailsDto, FullJobDto, WorkflowSequenceDto} from "redi-types";
import * as styles from "./styles.scss";
import { ReduxState } from "config/reduxRoot";
import { actions as jobactions } from "redux/jobRedux";
import SmartTable from "components/SmartTable/SmartTable";
import { actions as smarttableactions } from "components/SmartTable/redux";
import scheduleservice from "services/schedule";
import { DateTime, string } from "utils/index";
import { QueryGroup, QueryPart } from "services/query/buildquery";
import FormsEntry, { HeaderButton } from "../Forms/Entry/Hoc";
import * as router from "connected-react-router";
import Spinner from "libs/spinner";

export const WorkflowStatuses = {
	AllocationRejected: "AllocationRejected",
	AwaitingAllocation: "AwaitingAllocation",
	AwaitingInvoice: "AwaitingInvoice",
	Cancelled: "Cancelled",
	Closed: "Closed",
	InProgress: "InProgress",
	NewForm: "NewForm",
	Scheduled: "Scheduled",
	WORKFLOW_END: "WORKFLOW_END",
	WORKFLOW_FORK: "WORKFLOW_FORK",
	WORKFLOW_START: "WORKFLOW_START",
};
let id: number = 0;

@CSSModules(styles, { allowMultiple: true })
class Job extends React.PureComponent<Props, State> {
    actions: Actions;
	constructor(props) {
        super(props);
        this.state = {
						jobId: this.props.match.params.jobId,
			};
			this.actions = this.props.exposeActions(this);
			this.updateFormValidity = this.updateFormValidity.bind(this);
			this.callOnChange = this.callOnChange.bind(this);
    }
    
    componentDidMount(){
		this.actions.loadJob(this.state.jobId);
	}
	
	componentWillUnmount(){
		this.actions.setJob(null);
	}

	updateFormValidity(valid: boolean){
		this.actions.setFormValid(valid);
	}

	callOnChange(fieldId: string, value: any){
			if(value && fieldId == "2eedd03e-a54f-4a50-9bcc-80cadeebc191"){
				this.actions.setCompanyId(value.companyId);
			}else if(value && fieldId == "bd1a1005-8090-4feb-8699-a72a2fa8d1df"){
				this.actions.setTechnicianId(value.id);
			}
		
	}

	render() {
		return (
			<div style={{width: '100%'}}>
				{this.props.job && this.props.job.formSubmission && !this.props.isBusy &&
					<FormsEntry 
					jobId={this.props.job.jobId}
					inFormVersionDto={this.props.job.formSubmission.formVersion} 
					inFormSubmissionId={this.props.job.formSubmissionId} 
					updateFormValidity={this.updateFormValidity}
					callOnChange={this.callOnChange}
					>
					</FormsEntry>
				}
				{this.props.isBusy &&
					<div styleName="spinner-wrapper">
						<Spinner size={100}></Spinner>
					</div>
				}
			</div>
		);
	}
}

const bindAction = dispatch => {
	return {
		exposeActions: (component: Job) => {
			return {
				loadJob: (jobId) => dispatch(jobactions.loadJob(jobId)),
				setJob: (job) => dispatch(jobactions.setJob(job)),
				updateStatus: (jobId: string, workflowStatusCode: string) => dispatch(jobactions.updateStatus(jobId, workflowStatusCode)),
				setFormValid: (formValid: boolean) => dispatch(jobactions.setFormValid(formValid)),
				setCompanyId: (companyId: string) => dispatch(jobactions.setCompanyId(companyId)),
				setTechnicianId: (technicianId: string) => dispatch(jobactions.setTechnicianId(technicianId))
			};
		}
	};
};

const mapStateToProps = (state: ReduxState) => ({
	job: state.job.job,
	isBusy: state.job.isBusy
});

export default AuthHoC(
	connect(
		mapStateToProps,
		bindAction
	)(Job)
);

interface Props extends CommonUIProps<{ jobId: string }>  {
	exposeActions: (component: Job) => Actions;
	job: FullJobDto;
	isBusy: boolean;
}

interface State {
	jobId: string;
}

interface Actions {
	loadJob: (jobId: string) => void;
	setJob: (job: FullJobDto) =>void;
	updateStatus: (jobId: string, workflowStatusCode: string) => void;
	navigate: (uri, data?) => void;
	setFormValid: (formValid: boolean) => void;
	setCompanyId: (companyId: string) => void;
	setTechnicianId: (technicianId: string) => void;
}

