import React from 'react';
import {geo, MergeStyles} from "utils/index";
import styles from "./styles.scss";
import { Button } from 'redi-common-inputs';

class FormTimeLocation extends React.PureComponent { 

    state = {
        time: null,
        coords: null
    }

    getCurrentTime = () => {
        const currentTime = new Date();
        const Hours = currentTime.getHours().toString();
        const Mins = currentTime.getMinutes().toString();
        const Seconds = currentTime.getSeconds().toString();
        const result = Hours + ":" + Mins + ":" + Seconds +  " " + currentTime.toLocaleDateString();
        return result;
    }
    handleButtonClick = async () => {
        const currentTime = this.getCurrentTime();
        const currentLocation = await geo.getPosition();
        const locationValue = {
            latitude: currentLocation.latitude,
            longitude: currentLocation.longitude
        }
        console.log(locationValue);
        this.setState({ 
            time: currentTime,
            coords: locationValue
        });
        this.props.input.onChange({
            time: currentTime,
            location: locationValue
        }); 
    }

    formatNumber = (num, precision) => {
        return parseFloat(num).toFixed(precision);
    };

    render() {
        const {
            label,
            required,
            input,
            disabled,
            meta: { touched, error }
        } = this.props;
        const isValueEmpty = !input.value || (input.value.time === null && !input.value.location);
        console.log(input.value.location);

        return (
            <div>
                <div formlabel="1">
					{label}
					{required && <span> *</span>}
				</div>
                {isValueEmpty && (
                    <Button style={{
                        backgroundColor: "#E5091C",
                        color:"#FFFFFF"
                    }} onClick={this.handleButtonClick} disabled={disabled}>
                        Get Current Time and Location
                    </Button>
                )}
                { !isValueEmpty && (
                    <div>
                        <div>{input.value.time}</div>
                        <div>
                            {`${this.formatNumber(input.value.location.latitude, 4)}, ${this.formatNumber(input.value.location.longitude, 4)}`}
                        </div>
                    </div>
                )}
                {touched && error && <div styleName="_input-error">{error}</div>}
            </div>
        );
    }

}

export const RenderTimeLocation = MergeStyles(styles, { forwardRef: false })(FormTimeLocation);
