//initial redux
import { put, call, takeEvery, fork, all, take } from "redux-saga/effects";
import * as router from "connected-react-router";
import { Children as ReactChildren } from "react";
import { ReduxAction } from "redi-types";
import { QueryGroup, QueryPart } from "services/query/buildquery";

function arrayLastItem(arr) {
	return arr[arr.length - 1];
}

export class SortableColumn {
	constructor(th, name, dir) {
		this.th_Key = th.key;
		this.dataName = name;
		this.sortDir = dir;
	}
	th_Key = null;
	dataName = null;
	sortDir = 0; //0 = none; 1 = ASC; -1 = DESC
}

class TableIdentifier {
	constructor(_id, tableHeadBody) {
		this.id = _id;

		if (tableHeadBody.length === 2) {
			this.tableHead = tableHeadBody[0];
		}
		this.tableBody = arrayLastItem(tableHeadBody);
		this.genSortableColumns();
	}

	genSortableColumns() {
		this.sortableColumns = [];
		if (this.tableHead) {
			const headChildren = ReactChildren.toArray(this.tableHead.props.children);

			for (let i = 0; i < headChildren.length; i++) {
				const tr: any = headChildren[i];
				if (!tr) {
					return null;
				}
				const children = ReactChildren.toArray(tr.props.children);
				//Children.Foreach no longer creates keys so use toArray and iterate
				children.forEach((th: any, index) => {
					if (th.props.sortable) {
						let dir = 0;
						if (th.props["default-sort"]) {
							if (this.hasDefaultSort) {
								throw new Error("Smart table cannot have multiple default sort columns");
							}
							if (th.props["default-sort"].toLowerCase() === "asc") {
								this.hasDefaultSort = true;
								dir = 1;
							} else if (th.props["default-sort"].toLowerCase() === "desc") {
								this.hasDefaultSort = true;
								dir = -1;
							}
						}

						this.sortableColumns.push(new SortableColumn(th, th.props.sortable, dir));
					}
				});
			}
		}
	}

	currentPageNumber = 0; //first page
	tableHead = null;
	tableBody = null;
	sortableColumns = null;
	hasDefaultSort = false;
	id;
}

interface State {
	tables: { [name: string]: TableIdentifier };
}

const initialState: State = {
	tables: {}
};

/////////
//types
export const types = {
	REGISTER_TABLE: "components/smarttable/REGISTER_TABLE",
	SORT_COLUMN: "components/smarttable/SORT_COLUMN",
	REFRESH_DATA: "components/smarttable/REFRESH_DATA",
	// UPDATE_DATA: "components/smarttable/UPDATE_DATA",
	UPDATE_TABLE: "components/smarttable/UPDATE_TABLE",
	UPDATE_SORTABLE_COLUMNS: "components/smarttable/UPDATE_SORTABLE_COLUMNS",
	CHANGE_PAGE: "components/smarttable/CHANGE_PAGE"
};

///////////
//reducers
export const reducers = {
	reducer(state = initialState, action: ReduxAction): State {
		switch (action.type) {
			case types.REGISTER_TABLE: {
				const exists = !!state.tables[action.payload.tableId];
				//if a table was persisted use that instead
				const table = exists
					? { ...state.tables[action.payload.tableId] }
					: new TableIdentifier(action.payload.tableId, action.payload.tableHeadBody);
				return {
					...state,
					tables: {
						...state.tables,
						[action.payload.tableId]: table
					}
				};
			}

			case types.SORT_COLUMN: {
				let table = { ...state.tables[action.payload.tableId] };
				let sortableData = table.sortableColumns.find(x => x.th_Key === action.payload.thKey);
				if (sortableData) {
					table.sortableColumns.forEach(x => {
						x.sortDir = 0;
					});
					sortableData.sortDir = action.payload.direction;
				}
				return {
					...state,
					tables: { ...state.tables, [action.payload.tableId]: table }
				};
			}

			case types.UPDATE_TABLE: {
				let table = state.tables[action.payload.tableId];
				return {
					...state,
					tables: { ...state.tables, [action.payload.tableId]: { ...table, ...action.payload.data } }
				};
			}

			case types.UPDATE_SORTABLE_COLUMNS: {
				//copy table
				let table =new TableIdentifier(action.payload.tableId, action.payload.tableHeadBody);
				table.currentPageNumber = state.tables[action.payload.tableId].currentPageNumber;
				table.hasDefaultSort = false;
				return {
					...state,
					tables: { ...state.tables, [action.payload.tableId]: table }
				};
			}

			case types.REFRESH_DATA: {
				return state;
			}

			case types.CHANGE_PAGE: {
				let table = { ...state.tables[action.payload.tableId] };
				table.currentPageNumber += action.payload.pageIncrement;
				// make sure page number doesnt exit the range 0 - totalPages
				table.currentPageNumber = Math.max(0, Math.min(table.currentPageNumber, action.payload.totalPages - 1));
				return {
					...state,
					tables: { ...state.tables, [action.payload.tableId]: table }
				};
			}

			default:
				return state;
		}
	}
};

//////////
//sagas
export const sagas = {
	*rootSaga() {
		yield all([]);
	}
};

////////
//actions
export const actions = {
	registerTable(tableId, tableHeadBody) {
		return {
			type: types.REGISTER_TABLE,
			payload: {
				tableId,
				tableHeadBody
			}
		};
	},

	setSortColumn(tableId, thKey, direction) {
		return {
			type: types.SORT_COLUMN,
			payload: {
				tableId,
				thKey,
				direction
			}
		};
	},

	/**
	 * Refresh data from server. Does nothing for client side data tables
	 * @param tableId
	 * @param query Add a query filter or pass `"reset"` to clear any filters
	 * @param args Args to be concatenated to end of `callServer.func` prop's existing args, if any
	 */
	refreshData(tableId, query?: QueryGroup | QueryPart | "reset", ...args: any[]) {
		return {
			type: types.REFRESH_DATA,
			payload: {
				tableId,
				query,
				args
			}
		};
	},

	changePage(tableId, pageIncrement, totalPages) {
		return {
			type: types.CHANGE_PAGE,
			payload: {
				tableId,
				pageIncrement,
				totalPages
			}
		};
	},

	updateTable(tableId, data) {
		return {
			type: types.UPDATE_TABLE,
			payload: {
				tableId,
				data
			}
		};
	},

	updateSortableColumns(tableId, tableHeadBody) {
		return {
			type: types.UPDATE_SORTABLE_COLUMNS,
			payload: {
				tableId,
				tableHeadBody
			}
		};
	}
};
