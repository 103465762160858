import * as React from "react";
import * as styles from "./styles.scss";

//import { WrappedFieldInterface } from "redi-form";
//import { Typeahead, TypeaheadProps, FloatingLabel } from "redi-common-inputs";
import { shouldUpdate, MergeStyles, RegisterTheme } from "redi-component-utils";
import Typeahead, { TypeaheadProps } from "components/Typeahead/Typeahead";
//import { Typeahead, TypeaheadProps } from "redi-common-inputs";

class FormTypeahead extends React.Component<any, State> {
	static defaultProps: Partial<RenderTypeaheadProps<any>> = {
		typeaheadClass: "",
		errorClass: ""
	};
	typeaheadRef: React.RefObject<Typeahead<any>>;

	constructor(props: any) {
		super(props);

		this.typeaheadRef = React.createRef<Typeahead<any>>();

		/*this.props.form.setClearCallback(() => {
			// on clear we need to manually clear a typeahead
			setTimeout(() => {
				if (this.typeaheadRef.current?.inputRef?.value) {
					this.typeaheadRef.current.inputRef.value = "";
				}
			});
		});*/
	}

	render() {
		let {
            input,
            meta: { touched, error },
            classes,
            label,
            className,
            getData,
            typeaheadClass,
            errorClass,
            required,
			children,
            ...other
        } = this.props;
		//const props = this.props.componentProps();

		return (
            <div className={className} styleName="input-group">
                <div formlabel="1">{label}{ required && <span> *</span> }</div>
				<Typeahead
					{...other}
					//{...input}
					name={input.name}
					value={input.value}
					onChange={input.onChange}
					styleName={`__typohead ${typeaheadClass}`}
					getData={getData}
					ref={this.typeaheadRef}
				>
					{children as any}
				</Typeahead>
				{touched && error && <div styleName={`_input-error ${errorClass}`}>{error}</div>}
			</div>

		);
	}

	shouldComponentUpdate(nextProps: any, nextState: State) {
		return shouldUpdate(this, nextProps, nextState, ["onBlur", "onChange", "getData"]);
	}
}

export const RenderTypeahead = RegisterTheme("redi-form-components/typeahead")(MergeStyles(styles, { forwardRef: false })(FormTypeahead));

export interface RenderTypeaheadProps<T> extends TypeaheadProps<T> {
	label?: string;

	typeaheadClass?: string;
	errorClass?: string;
	required?: boolean;
	disabled?: boolean;
	alwaysRenderErrorElement?: boolean;
	/**Info icon appears that when clicked will show supplementary information on the field */
	infoText?:React.ReactText;
	/** Align info text box */
	infoTextAlign?: "top" | "bottom";
}

interface State {}
