import * as React from "react";
import config from "config/config";
import { MergeStyles, number, shouldUpdate, http } from "utils";
import * as styles from "./styles.scss";
import * as PropTypes from "prop-types";
import Modal from "components/BaseModal/ModalImpl/ModalImpl";
import { CommonUIProps, CommonComponentProps } from "redi-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import autoBind from "libs/react-autobind";
import ImageLoader from "../ImageLoader";
import FileUpload from "../file/Upload";
import ImageViewer from "../viewer/ImageViewer";
import { MediaImage } from "services/media/mediatypes";

@MergeStyles(styles)
export default class ImagePreview extends React.Component<Props, State> {
	static defaultProps = {
		images: [],
		onChange: null,
		allowUpload: false,
		disabled: false,
		classes: null //styleName overrides
	};
	static propTypes = {
		images: PropTypes.array.isRequired,
		onChange: function(props, propName, componentName) {
			//required if sorting or paging (for sorting)
			const test = props.allowUpload ? PropTypes.func.isRequired : PropTypes.func;
			const result = test.apply(this, arguments);
			if (result) {
				result.message = "The prop `onChange` is required in `ImagePreview` if `allowUpload` is set to true";
			}
			return result;
		}
	};

	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			previewOpen: false,
			currentViewedImg: null
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["onChange"]);
	}

	componentDidUpdate() {
		if (this.state.previewOpen && this.props.images.length === 0) {
			this.setState({ previewOpen: false });
		}
	}

	render() {
		return (
			<div className={this.props.className} styleName="_img_root">
				{this.props.allowUpload && (
					<FileUpload
						onChange={(files: MediaImage[]) => {
							this.props.onChange(this.props.images.concat(files));
						}}
					/>
				)}
				<div styleName="_img_preview">
					{this.props.images.map((x, i) => {
						return (
							<div key={i}>
								<ImageLoader
									src={config.amazonUrl + (x.thumbnailUrl || x.amazonUrl)}
									fallBackSrc={x.data}
									styleName="_img_margin"
									onClick={e => this.openImg(x)}
								/>
							</div>
						);
					})}
					{/* fullscreen viewer */}
					<ImageViewer
						open={this.state.previewOpen}
						onClose={() => this.setState({ previewOpen: false })}
						images={this.props.images}
						onChange={this.props.onChange}
						allowUpload={this.props.allowUpload}
						disabled={this.props.disabled}
					/>
				</div>
			</div>
		);
	}

	openImg(img: MediaImage): void {
		this.setState({ currentViewedImg: img, previewOpen: true });
	}
}

interface Props extends CommonComponentProps {
	images: MediaImage[];
	onChange?: (newImgs: MediaImage[]) => void;
	allowUpload?: boolean;
	disabled?: boolean;
}

interface State {
	previewOpen: boolean;
	currentViewedImg?: MediaImage;
}
