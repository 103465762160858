//initial redux
import { put, call, takeEvery, fork, all, take, cancel, select, takeLatest } from "redux-saga/effects";
import securityservice from "services/security/security";
import { types as setuptypes } from "boot/setupRedux";
import * as router from "connected-react-router";
import { persist, string } from "utils/index";
import { Action } from "redux";
import { ReduxAction, FormDto, FormVersionDto } from "redi-types";
import formservice from "services/forms";
import config from "config/config";

const initialState = {
	forms: [],
	currentForm: null,

	errors: [],
	isBusy: false
};

const persistConf = {
	whitelist: ["forms"],
	expireInNumHours: 0
};

/////////
//types
export const types = {
	GET_FORMS_LIST: "forms/GET_FORMS_LIST",
	ON_GET_FORMS_LIST: "forms/ON_GET_FORMS_LIST",

	CREATE_FORM: "forms/CREATE_FORM",
	UPDATE_FORM: "forms/UPDATE_FORM",

	GET_FORM: "forms/GET_FORM",
	SET_CURRENT_FORM: "forms/SET_CURRENT_FORM",

	GET_FORM_VERSION: "forms/GET_FORM_VERSION",

	ON_ERROR: "forms/ON_ERROR"
};

///////////
//reducers
const reducers = {
	reducer(state = initialState, action: ReduxAction) {
		switch (action.type) {
			case types.GET_FORM:
			case types.GET_FORMS_LIST:
			case types.CREATE_FORM:
			case types.UPDATE_FORM:
				return { ...state, isBusy: true };
			case types.GET_FORM_VERSION:
				return state;
			case types.ON_GET_FORMS_LIST:
				return { ...state, isBusy: false, forms: action.payload.forms };

			case types.SET_CURRENT_FORM:
				return { ...state, isBusy: false, currentForm: action.payload.form };

			case types.ON_ERROR:
				return { ...state, errors: state.errors.concat(action.payload).slice(-10), isBusy: false };

			default:
				return state;
		}
	}
};
export const reducer = persist("forms", reducers.reducer, persistConf);

//////////
//sagas
export const sagas = {
	*rootSaga() {
		yield all([
			this.getFormsList(), 
			this.getForm(), 
			this.updateForm(), 
			this.createForm(),
			this.getFormVersion()
		]);
	},
	*getFormVersion(){
		yield takeLatest<ReduxAction>(types.GET_FORM_VERSION, function*({payload}){
			let data = yield call(formservice.GetFormVersion, payload.formVersionId);
			if(data.error){
				console.error(data.error);
				yield put(actions.onError(data.error));
			}else{
				yield put(actions.setCurrentForm(data.data));
			}
		});
	},
	*createForm() {
		yield takeEvery<ReduxAction>(types.CREATE_FORM, function*({ payload }) {
			let data = yield call(formservice.Create, payload.form);
			if (data.error) {
				console.error(data.error);
				yield put(actions.onError(data.error));
			} else {
				router.push(config.defaultRoute)
				// yield put(actions.setCurrentForm(data.data));
			}
		});
	},

	*updateForm() {
		yield takeEvery<ReduxAction>(types.UPDATE_FORM, function*({ payload }) {
			let data = yield call(formservice.Update, payload.form);
			if (data.error) {
				console.error(data.error);
				yield put(actions.onError(data.error));
			} else {
				yield put(actions.setCurrentForm(data.data));
			}
		});
	},

	*getForm() {
		yield takeEvery<ReduxAction>(types.GET_FORM, function*({ payload }) {
			let data = yield call(formservice.GetForm, payload.id);
			if (data.error) {
				console.error(data.error);
				yield put(actions.onError(data.error));
			} else {
				yield put(actions.setCurrentForm(data.data));
			}
		});
	},

	*getFormsList() {
		yield takeEvery<ReduxAction>(types.GET_FORMS_LIST, function*({ payload }) {
			let data = yield call(formservice.GetFormsList, payload.query);
			if (data.error) {
				console.error(data.error);
				yield put(actions.onError(data.error));
			} else {
				yield put(actions.onGetFormsList(data.data));
			}
		});
	}
};

////////
//actions
export const actions = {
	createForm(form: FormVersionDto) {
		return {
			type: types.CREATE_FORM,
			payload: { form }
		};
	},
	getFormVersion(formVersionId){
		return {
			type: types.GET_FORM_VERSION,
			payload: {formVersionId}
		};
	},
	updateForm(form: FormVersionDto) {
		return {
			type: types.UPDATE_FORM,
			payload: { form }
		};
	},

	getForm(id: string) {
		return {
			type: types.GET_FORM,
			payload: { id }
		};
	},
	setCurrentForm(form: FormVersionDto) {
		return {
			type: types.SET_CURRENT_FORM,
			payload: {
				form
			}
		};
	},

	getFormsList(query: string) {
		return {
			type: types.GET_FORMS_LIST,
			payload: { query }
		};
	},
	onGetFormsList(forms: FormVersionDto[]) {
		return {
			type: types.ON_GET_FORMS_LIST,
			payload: {
				forms
			}
		};
	},

	onError(error) {
		return {
			type: types.ON_ERROR,
			payload: error
		};
	}
};
