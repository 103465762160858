import React, { PureComponent } from "react";
import * as router from "connected-react-router";
import { connect } from "react-redux";
import { actions as loginactions } from "redux/loginRedux";
import CSSModules from "react-css-modules";
import { actions as menuactions } from "../Menu/redux";
import config from "config/config";

import styles from "./styles.scss";
import { checkAuth } from "components/Auth/Auth";
import Button from "components/Button/Button";
import { DEFAULT_ROUTE } from "boot/setupRedux";
import logo from "assets/logo_momentum.png";
import Menu, { MenuAction } from "components/PopupMenu/menu";
import Tabs, { TabAction } from "components/Tabs/tabs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

@CSSModules(styles, { allowMultiple: true })
class Header extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			showReloadBar: false,
			swEvent: null
		};

		this.userActions = [
			new MenuAction("My Account", () => this.props.navigate("/Account"), () => this.props.isLoggedIn),
			new MenuAction("Log Out", () => this.props.logout(), () => this.props.isLoggedIn)
		];

		this.menuActions = [
			new TabAction(
				(
					<div styleName="tab-icon">
						<FontAwesomeIcon icon="file-alt" />
						Forms
					</div>
				),
				() => this.props.navigate("/Form/List"),
				() => this.props.route === "/Form/List"
			),
			new TabAction(
				(
					<div styleName="tab-icon">
						<FontAwesomeIcon icon="file-alt" />
						My Tasks
					</div>
				),
				() => this.props.navigate("/TechSchedule"),
				() => this.props.route === "/TechSchedule"
			),
			new TabAction(
				(
					<div styleName="tab-icon">
						<FontAwesomeIcon icon="file-alt" />
						Company Tasks
					</div>
				),
				() => this.props.navigate("/CompanySchedule"),
				() => this.props.route === "/CompanySchedule"
			),
			new TabAction(
				(
					<div styleName="tab-icon">
						<FontAwesomeIcon icon="building" />
						Momentum
					</div>
				),
				() => {
					window.location = config.clientSiteUrl;
				},
				() => { return false; },
				() => { 
					return !!this.props.currentDetails.ClientId;
				},
			)
		];
	}

	componentDidMount() {
		//add a listener to the service worker here
		//we will show a reload bar below the header if site needs reloading
		if ("serviceWorker" in navigator) {
			// Handler for messages coming from the service worker
			navigator.serviceWorker.addEventListener("message", event => {
				const message = JSON.parse(event.data);
				console.log("Client 1 Received Message: ", message);
				if (message && message.type === "refresh" && !this.state.showReloadBar) {
					this.setState({ showReloadBar: true, swEvent: event });
				} else if (message.type === "onskipwaiting") {
					setTimeout(() => {
						location.reload();
					});
				}
			});
		}
	}

	render() {
		const isMobile = window.innerWidth < 960;
		return (
			<div styleName="header">
				<div styleName="header-content">

					{this.props.toggleMenu && isMobile && this.props.isLoggedIn && (
						<div styleName="menubtn" onClick={this.props.toggleMenu}>
							<FontAwesomeIcon icon="bars" />
						</div>
					)}

					<div styleName="logo">
						<img src={this.props.clientConfig.logoUrl} onError={(e)=>{e.target.onError = null; e.target.src=logo}} />
					</div>

					<div styleName="main">
						{!isMobile && this.props.isLoggedIn && (
							<Tabs actions={this.menuActions} classes={styles} showFooterBars={false} />
						)}
					</div>

					<div styleName="namewrap">
						{this.props.isLoggedIn && (
							<Menu actions={this.userActions}>
								<div styleName="user-icon-wrap">
									<FontAwesomeIcon icon="user-circle" styleName="user-icon" />
								</div>
								<div styleName="username-wrap">{this.props.currentUsername}</div>
							</Menu>
						)}

						{!this.props.isLoggedIn && (
							<Button raised={false} onClick={() => this.props.navigate("/Login")}>
								<strong>Login</strong>
							</Button>
						)}
					</div>
				</div>
				{this.state.showReloadBar && (
					<div
						styleName="reload-bar"
						onClick={() => {
							if ("serviceWorker" in navigator && this.state.swEvent) {
								//tell new sw to become main sw
								this.state.swEvent.ports[0].postMessage("skipwaiting");
							} else {
								location.reload(true);
							}
						}}
					>
						New content is available. Click here to reload.
					</div>
				)}
			</div>
		);
	}
}

const bindAction = dispatch => {
	return {
		logout: () => dispatch(loginactions.logout()),
		navigate: (uri, data) => dispatch(router.push(uri, data)),
		goBack: () => dispatch(router.goBack())
	};
};

const mapStateToProps = state => ({
	route: state.router.location.pathname,
	isLoggedIn: state.login.isLoggedIn,
	currentDetails: state.login.currentDetails,
	currentUsername: state.login.currentUsername,
	roles: state.login.currentRoles, //add this rerender header when roles change
	clientConfig: state.client.clientConfig
});

export default connect(
	mapStateToProps,
	bindAction
)(Header);
