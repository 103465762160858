import config from "config/config";
import * as datefns from "date-fns";
import { ManualPromise, deepClone } from "utils/index";
import { Time } from "./dateTime";
import SecurityService from "services/security/security";

const TIMEOUT = 30000;

export type Methods = "POST" | "GET";

export class HttpError {
	type: "HTTP Cancelled" | "HTTP Error";
	summary: string;
	response: any;
	constructor(type, summary, response) {
		this.type = type;
		this.summary = summary;
		this.response = response;
	}
}

/**
 * 	Supports `Date` - isoString conversion
 */
export interface IHttpObject {
	/***/
	url: string;
	/**'GET', 'POST' etc */
	method: Methods;
	/** query string objects optional */
	params?: object | null;
	/** body data optional */
	data?: any | null;
	/** optional header overrider */
	headers?: object | null;

	/**
	 *    All other props passed will be put into the query string.
	 */
	[x: string]: any;
}

export type HttpResult<T> = { data?: T; error?: HttpError };

/**
 *
 * HTTP call
 *
 * @param {IHttpObject} httpObject
 *    All other props passed will be put into the query string.
 * 	Supports `Date` - isoString conversion
 * @param {boolean} addNullToParams add any undefined or null params to the query string as 'param='
 * @param {ManualPromise} canceller pass a ManualPromise that if rejected, cancelles the request
 * @param {boolean} useTimeout default true
 * @returns {Promise<{data?, error?}|null>} Promise
 */
export default async function http<T>(
	httpObject: IHttpObject,
	addNullToParams: boolean = false,
	canceller: ManualPromise<void> = new ManualPromise(),
	useTimeout: boolean = true
): Promise<HttpResult<T>> {
	let { url, method, data, headers, ...params } = httpObject;

	if (!canceller) {
		canceller = new ManualPromise();
	}

	let token =  SecurityService.iframeToken ? SecurityService.iframeToken : localStorage.getItem(config.tokenKey);
	let setHeaders: any = headers || {
		Accept: "application/json",
		"Content-Type": "application/json"
	};

	if (token) {
		setHeaders.Authorization = "Bearer " + token;
	}

	if (!HttpUtils.isEmpty(params)) {
		const querys = HttpUtils.serialize(params, addNullToParams);
		if (querys) {
			url = url + "?" + querys;
		}
	}

	let body: any = null;
	if (data) {
		if (typeof data === "string" || data instanceof FormData || data instanceof Blob) {
			//dont stringify a string or certian objects
			body = data;
		} else {
			body = JSON.stringify(HttpUtils.convertDate(deepClone(data), true), function(key, val) {
				if (val instanceof Time) {
					//str time compatable with .NET TimeSpan
					return `${HttpUtils.minDigits(val.hours, 2)}:${HttpUtils.minDigits(val.mins, 2)}:${HttpUtils.minDigits(val.secs, 2)}`;
				} else {
					return val;
				}
			});
		}
	}

	// if (config.debug) {
	//    console.log(`HTTP: ${method} ${url} ${body ? "\n" + body : ""}`);
	// }

	return new Promise<HttpResult<T>>((resolve, reject) => {
		let xhr = new XMLHttpRequest();
		let cancelled = false;

		//kill http if canceller is rejected
		canceller.then(
			() => null,
			() => {
				cancelled = true;
				xhr.abort();
			}
		);

		xhr.onreadystatechange = function() {
			if (xhr.readyState === XMLHttpRequest.DONE) {
				// if (config.debug) {
				//    console.log(`Request done: ${xhr.status} ${method} ${url} \n`);
				// }
				if (xhr.status === 200) {
					if (xhr.responseText) {
						canceller.Resolve();
						resolve({ data: HttpUtils.convertDate(JSON.parse(xhr.responseText), false) });
					} else {
						canceller.Resolve();
						resolve({ data: null });
					}
				} else {
					canceller.Resolve();
					const error = new HttpError(
						cancelled ? "HTTP Cancelled" : "HTTP Error",
						xhr.status + " " + xhr.statusText + " " + xhr.responseURL,
						xhr.response && xhr.response.startsWith("{") ? JSON.parse(xhr.response) : "{}"
					);

					reject({ error: error });
				}
			}
		};
		xhr.open(method, url, true);

		if (useTimeout) {
			xhr.timeout = TIMEOUT;
		}

		for (var p in setHeaders) {
			if (setHeaders.hasOwnProperty(p)) {
				xhr.setRequestHeader(p, setHeaders[p]);
			}
		}

		xhr.send(body);
	});
}

export class HttpUtils {
	static convertDate(response: any, stringify: boolean) {
		if (response && typeof response === "object") {
			var data = response;
			for (let key in data) {
				const value = data[key];
				if (!stringify && typeof value === "string") {
					if (value && HttpUtils.isDateString(value)) {
						if (/utc/i.test(key)) {
							data[key] = HttpUtils.toLocal(datefns.parse(value.substr(0, 19), "yyyy-MM-dd'T'HH:mm:ss", new Date()));
						} else {
							data[key] = datefns.parse(value.substr(0, 19), "yyyy-MM-dd'T'HH:mm:ss", new Date());
						}
					} else if (value && HttpUtils.isTimeString(value)) {
						const date = datefns.parse(value, "HH:mm:ss", new Date());
						data[key] = new Time(date.getHours(), date.getMinutes(), date.getSeconds(), 0);
					}
				} else if (stringify && value instanceof Date) {
					if (/utc/i.test(key)) {
						data[key] = value.toISOString();
					} else {
						data[key] = datefns.format(value, "yyyy-MM-dd'T'HH:mm:ss");
					}
				} else if (value && typeof value === "object") {
					HttpUtils.convertDate(value, stringify); // Recursively process any objects
				}
			}
		}
		return response;
	}

	static toLocal(utcDate: Date) {
		return datefns.addMinutes(utcDate, -utcDate.getTimezoneOffset());
	}

	static isDateString(str: string) {
		if (str.length >= 19) {
			const date = datefns.parse(str.substr(0, 19), "yyyy-MM-dd'T'HH:mm:ss", new Date());
			return date instanceof Date && !isNaN(date.getTime());
		} else {
			return false;
		}
	}

	static isTimeString(str: string) {
		if (str.length === 8) {
			const date = datefns.parse(str, "HH:mm:ss", new Date());
			return date instanceof Date && !isNaN(date.getTime());
		} else {
			return false;
		}
	}

	static isEmpty(obj: any) {
		if (!obj) {
			return true;
		}

		if (typeof obj === "object") {
			for (var p in obj) {
				if (obj.hasOwnProperty(p)) {
					return false;
				}
			}
		}
		return true;
	}

	static serialize(obj: any, addNull: boolean) {
		var str = [];
		for (var p in obj)
			if (obj.hasOwnProperty(p)) {
				const item = obj[p];
				if (Array.isArray(item)) {
					for (let index = 0; index < item.length; index++) {
						const subitem = item[index];
						str.push(HttpUtils.serialize({ [p]: subitem }, addNull));
					}
				} else if (item instanceof Date) {
					const value = !isNaN(item.getTime())
						? encodeURIComponent(/*	/utc/i.test(p) ? item.toISOString() :*/ datefns.format(item, "yyyy-MM-dd'T'HH:mm:ss"))
						: "";
					if (value || addNull) {
						str.push(encodeURIComponent(p) + "=" + value);
					}
				} else if (typeof item !== "object" || item === null) {
					const value = item !== null && item !== undefined ? encodeURIComponent(item) : "";
					if (value || addNull) {
						str.push(encodeURIComponent(p) + "=" + value);
					}
				} else {
					str.push(HttpUtils.serialize(item, addNull));
				}
			}
		return str.join("&");
	}

	static minDigits(number: number, amount: number): string {
		const str = number.toString();
		if (str.length < amount) {
			return new Array(amount).join("0").concat(...str.split("").slice(-amount));
		} else {
			return str;
		}
	}
}
