import config from "config/config";
import {  http } from "utils/http";

export default function sendError(state = { login: {} }, action, type) {
	const url = `${config.baseUrl}Error/LogUIError`;

	try {
		if (!/redux\-form/.test(action.type)) {
			let data = {
				application: "TSA Attendance web",
				host: window.location.host,
				type: type,
				stack: action.payload.stackTrace || action.payload.stack || "",
				message: action.payload.message || JSON.stringify(action.payload),
				user: state.login.currentDetails ? state.login.currentDetails.userName || "nobody" : "nobody",
				timeLocal: new Date().toISOString(),
				// reduxState: CircularJSON.stringify(state, null, null, true)
			};

			//dont send http errors
			if (
				data.message !== "HTTP Cancelled" &&
				!data.message.includes("HTTP Error") &&
				!data.stack.includes("XMLHttpRequest.xhr.onreadystatechange")
			) {
				return http({ url, method: "POST", data })
					.then(data => data)
					.catch(error => (error));
			}
		}
	} catch (e) {
		console.log(e);
	}
}
