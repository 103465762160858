import { http, DateTime, string } from "../utils/index";
import config from "config/config";
import showToast from "components/Toast/Toast";
import { JobSummaryDto } from "redi-types";
import { HttpResult } from "utils/http";

const route = "Schedule/";

export default class scheduleservice {
	static GetJobTypes() {
		let url = `${config.baseUrl + route}GetJobTypes`;

		return http({ url, method: "GET" })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static StartJob(jobTypeCode: string){
		let url = `${config.baseUrl + route}StartJob`;

		return http({ url, method: "GET",  jobTypeCode})
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static LoadScheduleSummary(){
		let url = `${config.baseUrl + route}LoadScheduleSummary`;

		return http({ url, method: "GET" })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static LoadCompanyScheduleSummary(){
		let url = `${config.baseUrl + route}LoadCompanyScheduleSummary`;

		return http({ url, method: "GET" })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static GetWorkflowSummary(jobTypeCode){
		let url = `${config.baseUrl + route}GetWorkflowSummary`;

		return http({ url, method: "GET",  jobTypeCode})
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static GetJobSummary(jobTypeCode: string, workflowStatusCode: string, pagingParams: PagingParameters): Promise<HttpResult<DataSourceResult<JobSummaryDto>>> {
		let url = `${config.baseUrl + route}GetJobSummary`;
		return http({ url, method: "GET", jobTypeCode, workflowStatusCode, pagingParams })
			.then(data => {
				return data;
			})
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static GetTechJobSummary(statusCategory: string, pagingParams: PagingParameters): Promise<HttpResult<DataSourceResult<JobSummaryDto>>>{
		let url = `${config.baseUrl + route}GetTechJobSummary`;
		return http({ url, method: "GET", statusCategory, pagingParams })
			.then(data => {
				return data;
			})
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static GetCompanyJobSummary(statusCategory: string, pagingParams: PagingParameters): Promise<HttpResult<DataSourceResult<JobSummaryDto>>>{
		let url = `${config.baseUrl + route}GetCompanyJobSummary`;
		return http({ url, method: "GET", statusCategory, pagingParams })
			.then(data => {
				return data;
			})
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}
}
