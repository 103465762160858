//initial redux
import { put, call, takeEvery, fork, all, take, cancel, select, takeLatest } from "redux-saga/effects";
import securityservice from "services/security/security";
import { types as setuptypes } from "boot/setupRedux";
import * as router from "connected-react-router";
import { persist, string } from "utils/index";
import { Action } from "redux";
import { ReduxAction, FormDto, FormVersionDto, JobTypeDto, WorkflowSummaryDto, TechScheduleDto } from "redi-types";
import scheduleservice from "services/schedule";
import config from "config/config";

const initialState = {
    techSchedule: [],
	selectedTechSchedule: {},
    error: "",
};

const persistConf = {
    whitelist: ["selectedJobType"],
	expireInNumHours: 0 //dont expire
};

/////////
//types
export const types = {
    LOAD_TECH_SCHEDULE: "techschedule/LOAD_TECH_SCHEDULE",
	SET_TECH_SCHEDULE: "techschedule/SET_TECH_SCHEDULE",
	SET_SELECTED_TECH_SCHEDULE: "techschedule/SET_SELECTED_TECH_SCHEDULE",
	EDIT_JOB: "techschedule/EDIT_JOB",

    ON_ERROR: "techschedule/ON_ERROR"
};

///////////
//reducers
const reducers = {
	reducer(state = initialState, action: ReduxAction) {
		switch (action.type) {
			case types.LOAD_TECH_SCHEDULE:
				return state;
			case types.SET_TECH_SCHEDULE:
				return {...state, techSchedule: action.payload.techSchedule};
			case types.SET_SELECTED_TECH_SCHEDULE:
				return {...state, selectedTechSchedule: action.payload.selectedTechSchedule};
            case types.ON_ERROR:
                return {...state, error: action.payload.error}
			default:
				return state;
		}
	}
};
export const reducer = persist("techschedule", reducers.reducer, persistConf);

//////////
//sagas
export const sagas = {
	*rootSaga() {
		yield all([
            this.loadTechSchedule(),
			this.onSetTechSchedule(),
			this.editJob()
		]);
    },
    *loadTechSchedule(){
        yield takeLatest<ReduxAction>(types.LOAD_TECH_SCHEDULE, function*({payload}){
            let data = yield call(scheduleservice.LoadScheduleSummary);
            if(data.error){
				console.error(data.error);
				yield put(actions.onError(data.error));
			}else{
                yield put(actions.setTechSchedule(data.data));
			}
        });
    },
	*editJob(){
		yield takeLatest<ReduxAction>(types.EDIT_JOB, function*({payload}){
			let jobId: string = payload.jobId;
			let uri = "/Job/" + jobId;
			yield put(router.push(uri));
		});
	},
	*onSetTechSchedule(){
		yield takeLatest<ReduxAction>([types.SET_TECH_SCHEDULE], function*({payload}){
            let selectedTechSchedule: TechScheduleDto = yield select<any>(x => x.techschedule.selectedTechSchedule);
            let techSchedule: Array<TechScheduleDto> = (yield select<any>(x => x.techschedule.techSchedule)) as Array<TechScheduleDto>;
            if(techSchedule && techSchedule.length){
                if(selectedTechSchedule){
                    let existing = techSchedule.find(s => s.statusCategory == selectedTechSchedule.statusCategory);
                    if(existing){
                        yield put(actions.setSelectedTechSchedule(existing));
                    }else{
                        yield put(actions.setSelectedTechSchedule(techSchedule[0]));
                    }
                }else{
                    yield put(actions.setSelectedTechSchedule(techSchedule[0]));
                }
            }
		});
	},
};

////////
//actions
export const actions = {
    loadScheduleSummary(){
        return {
            type: types.LOAD_TECH_SCHEDULE,
            payload: {}
        };
    },
	editJob(jobId: string){
		return {
			type: types.EDIT_JOB,
			payload: {jobId}
		};
	},
	setTechSchedule(techSchedule){
		return {
			type: types.SET_TECH_SCHEDULE,
			payload: {techSchedule}
		};
	},
	setSelectedTechSchedule(selectedTechSchedule){
		return {
			type: types.SET_SELECTED_TECH_SCHEDULE,
			payload: {selectedTechSchedule}
		};
	},
	onError(error) {
		return {
			type: types.ON_ERROR,
			payload: error
		};
	}
};
