//initial redux
import { put, call, takeEvery, fork, all, take, cancel, select } from "redux-saga/effects";
import config from "config/config";
import * as router from "connected-react-router";
import { types as logintypes } from "redux/loginRedux";
import routes from "config/routeConfig";
import { Persistor } from "redux-persist";
import { ReduxState } from "config/reduxRoot";

export let persistor: { persistor: Persistor } = { persistor: null };

const initialState = {
	uri: config.defaultRoute,
	params: null,
	$persist: [],

	loaded: false
};

/////////
//types
export const types = {
	STORE_URI: "boot/setup/STORE_URI",
	PURGE_PERSIST: "boot/setup/PURGE_PERSIST",
	CLEAR_STATE: "boot/setup/CLEAR_STATE"
};

///////////
//reducers
export const reducers = {
	reducer(state = initialState, action) {
		switch (action.type) {
			case types.STORE_URI:
				return {
					...state,
					uri: action.payload.uri,
					params: action.payload.params,
					$persist: [],
					loaded: true
				};
			case "persist/REHYDRATE":
				return { ...state, $persist: state.$persist.concat(action.payload) };
			default:
				return state;
		}
	}
};

//////////
//sagas
export const sagas = {
	*rootSaga() {
		yield all([this.onBoot(), this.purgePersist()]);
	},

	*purgePersist() {
		yield takeEvery([types.CLEAR_STATE, types.PURGE_PERSIST], function*(action) {
			if (persistor.persistor && persistor.persistor.purge) {
				yield call(persistor.persistor.purge);
				console.log("Purge Complete");
			}
		});
	},

	*onBoot() {
		yield takeEvery(
			[types.STORE_URI, logintypes.ON_LOGIN],
			function*(action) {
				yield call(this.nav, action.type === logintypes.ON_LOGIN);
			}.bind(this)
		);
	},

	*nav(isLogin) {
		//logged in goto home or pasted url
		const route = yield select<ReduxState>(x => x.setup);
		console.log("Initial route: " + route.uri);

		//nav to initial route only if it exists
		const routeObj = routes.find(x => {
			let path = x.path;
			if (path.includes(":")) {
				const index = path.indexOf(":");
				path = path.substr(0, index);
				return route.uri.substr(0, index) === path;
			} else {
				return path === route.uri;
			}
		});

		if (!routeObj) {
			route.uri = config.defaultRoute;
			route.params = null;
		}

		if (isLogin && route.uri === "/Login") {
			//goto home instead
			yield put(router.push(config.defaultRoute));
		} else {
			yield put(router.push(route.uri, route.params));
		}
	}
};

////////
//actions
export const actions = {
	clearState() {
		return {
			type: types.CLEAR_STATE,
			payload: {}
		};
	},

	genericAction(type, data) {
		return {
			type,
			payload: data
		};
	}
};
