import React from "react";
import styles from "./styles.scss";
import { shouldUpdate, MergeStyles, ImageUtil } from "utils/index";
import SignatureCanvas from "react-signature-canvas";
import mediaservice from "services/media/mediaservice";
import config from "config/config";
import Button from "components/Button/Button";
import autoBind from "libs/react-autobind";

class FormSig extends React.PureComponent {
	static defaultProps = {
		//any class added to the canvas must be wrapped in :global or added to the global css sheet (not hashed)
		signatureClass: ""
	};
	constructor(props) {
		super(props);
		autoBind(this);
		this.ref = null;
		this._reinitialized = false;
	}
	render() {
		let {
			input,
			label,
			required,
			meta: { touched, error },
			className,
			disabled,
			...other
		} = this.props;

		//https://github.com/agilgur5/react-signature-canvas#props
		return (
			<div className={className} styleName="input-group">
				<div formlabel="1">{label}{ required && <span> *</span> }</div>
				<div styleName="__signature-root">
					<SignatureCanvas
						onEnd={x => {
							this._reinitialized = true;
							const img = this.ref.toDataURL();
							const file = mediaservice.dataURLtoFile(img, "sig.jpg");
							mediaservice.createFileLink(file).then(filelink => {
								input.onChange(filelink);
							});
						}}
						clearOnResize={true}
						ref={ref => {
							if (ref) {
								this.ref = ref;

								if (disabled) {
									this.ref.off();
								}

								if (input.value && !this._reinitialized) {
									//if form is populated with existing data then insert exisitng sig image into canvas
									if (typeof input.value === "string") {
										this.ref.fromDataURL(input.value);
									} else if (input.value.amazonUrl) {
										ImageUtil.loadUrl(config.amazonUrl + input.value.amazonUrl).then(image => {
											const canvas = this.ref.getCanvas();
											const ctx = canvas.getContext("2d");
											ctx.drawImage(
												image,
												0,
												0,
												image.width,
												image.height, // source rectangle
												0,
												0,
												canvas.width,
												canvas.height
											); // destination rectangle
										});
									}

									this._reinitialized = true;
								}
							}
						}}
						canvasProps={{ width: 300, height: 150, className: `__input-signature ${this.props.signatureClass}`, disabled: disabled}}
						disabled={disabled}
					/>
					{this.ref && 
						<div styleName="_clearBtnContainer">
							<Button theme="secondary" raised={false} onClick={this.clear}>Clear</Button>
						</div>
					}
				</div>
				{touched && error && <div styleName="_input-error">{error}</div>}
			</div>
		);
	}

	clear(){
		this.ref.clear();
		this.props.input.onChange(null);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.input.value && !this.props.input.value && this.ref) {
			//clear the canvas if redux form was also cleared
			this.clear();
		}
	}
}

export const RenderSignaturePad = MergeStyles(styles, { forwardRef: false })(FormSig);
