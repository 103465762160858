import * as React from "react";
import config from "config/config";
import { MergeStyles, number, shouldUpdate, http, string, ImageUtil } from "utils";
import * as styles from "./styles.scss";
import * as PropTypes from "prop-types";
import { CommonUIProps, CommonComponentProps } from "redi-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import autoBind from "libs/react-autobind";
import mediaservice from "services/media/mediaservice";
import ImageLoader from "../ImageLoader";
import FileUpload from "../file/Upload";
import Button from "components/Button/Button";
import { MediaImage } from "services/media/mediatypes";
import Modal from "components/BaseModal/Modal";
import SignatureCanvas from "react-signature-canvas";

@MergeStyles(styles)
export default class ImageEdit extends React.Component<ImageEditProps, State> {
	static defaultProps = {
		penColor: "#f00",
		//any class added to the canvas must be wrapped in :global or added to the global css sheet (not hashed)
		canvasClass: ""
	};
	ref: any;

	constructor(props) {
		super(props);
		autoBind(this);

		const d = this.genHeightWidth();

		this.state = {
			height: d.height,
			width: d.width,
			showClearConfirm: false
		};
		window.addEventListener("resize", this.onresize);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.onresize);
	}

	genHeightWidth() {
		const width1 = Math.min(this.props.image.width, 1000, window.innerWidth);
		const ratio1 = width1 / this.props.image.width;
		const height1 = this.props.image.height * ratio1;

		const height2 = Math.min(this.props.image.height, 1000, window.innerHeight);
		const ratio2 = height2 / this.props.image.height;
		const width2 = this.props.image.width * ratio2;

		return height1 > height2 ? { height: height2, width: width2 } : { height: height1, width: width1 };
	}

	onresize() {
		const d = this.genHeightWidth();

		if (this.state.width !== d.width || this.state.height !== d.height) {
			this.setState({ height: d.height, width: d.width }, this.loadBackgroundImage);
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["onChange"]);
	}

	change(): any {
		if (this.ref) {
			const img = this.ref.toDataURL();
			const file = mediaservice.dataURLtoFile(img, "sig.jpg");
			mediaservice.createFileLink(file).then(filelink => {
				this.props.onChange(filelink as MediaImage);
			});
		}
	}

	loadBackgroundImage(clear?: boolean) {
		if (this.ref) {
			const dpi = Math.max(window.devicePixelRatio || 1, 1);
			const canvas: HTMLCanvasElement = this.ref.getCanvas();

			if (this.props.value && !clear) {
				return ImageUtil.loadUrl(config.amazonUrl + this.props.value.amazonUrl).then(image => {
					this.contextDraw(image, canvas);
				});
			} else {
				return ImageUtil.loadUrl(config.amazonUrl + this.props.image.amazonUrl).then(image => {
					this.contextDraw(image, canvas);
				});
			}
		}
	}

	render() {
		return (
			<div className={this.props.className}>
				<SignatureCanvas
					onEnd={x => {
						this.change();
					}}
					clearOnResize={true}
					penColor={this.props.penColor}
					ref={ref => {
						if (ref) {
							this.ref = ref;

							if (this.props.disabled) {
								this.ref.off();
							}

							this.loadBackgroundImage();
						}
					}}
					canvasProps={{
						width: this.state.width,
						height: this.state.height,
						className: `__markimage ${this.props.canvasClass}`,
						disabled: this.props.disabled
					}}
					disabled={this.props.disabled}
				/>
				{this.ref && (
					<div styleName="_clearBtnContainer" clearbutton="1">
						<Button theme="secondary" raised={false} onClick={() => this.setState({ showClearConfirm: true })}>
							Clear
						</Button>
					</div>
				)}

				<Modal
					zIndex={this.props.zIndex}
					isOpen={this.state.showClearConfirm}
					onClose={() => this.setState({ showClearConfirm: false })}
					onConfirm={() => {
						this.setState({ showClearConfirm: false });
						this.loadBackgroundImage(true).then(r => {
							this.change();
						});
					}}
					title="Confirm Clear"
					bodyText="Are you sure?"
				/>
			</div>
		);
	}

	componentDidUpdate(prevProps: ImageEditProps) {
		if (prevProps.value && !this.props.value && this.ref) {
			//clear the canvas if redux form was also cleared
			this.loadBackgroundImage().then(r => {
				this.change();
			});
		}
	}

	contextDraw(image: HTMLImageElement, canvas: HTMLCanvasElement) {
		const ctx = canvas.getContext("2d");
		ctx.drawImage(
			image,
			0,
			0,
			image.width,
			image.height, // source rectangle
			0,
			0,
			canvas.width,
			canvas.height
		); // destination rectangle
	}
}

export interface ImageEditProps extends CommonComponentProps {
	/** image with current marks */
	value?: MediaImage;
	/** The base image, without marks, to clear back to */
	image: MediaImage;
	onChange: (newImg: MediaImage) => void;
	disabled?: boolean;
	penColor?: string;
	zIndex?: number;

	/**
	 * any class added to the canvas must be wrapped in :global or added to the global css sheet (not hashed)
	 **/
	canvasClass?: string;
}

interface State {
	height: number;
	width: number;
	showClearConfirm: boolean;
}
