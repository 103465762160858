import React, { Component } from "react";
import ReactDOM from "react-dom";

/**
 * Renders children elements under #shell-portal
 * 
 * Use:

                     <div>
                        <ShellPortal>
                         ... w/e
                        </ShellPortal>
                     </div>
  
 */
export default class ShellPortal extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			target: document.getElementById("shell-portal")
		};
	}

	componentDidMount() {
		if(!this.state.target){
			//try one more time to find the element
			this.setState({ target: document.getElementById("shell-portal") });
		}
	}

	render() {
		if (this.state.target) {
			return ReactDOM.createPortal(this.props.children, this.state.target);
		} else {
			return null;
		}
	}
}
