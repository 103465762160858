import { FormSubmissionDto, FormFieldTypes, FormDataField, FormSubmissionField, FormDataSection } from "redi-types";
import { InjectedFormProps, RecusiveFormErrors } from "redux-form";
import { FormEntryProps } from "./Hoc";

//put in a new file to prevent clutter

export function validate(v: FormSubmissionDto, props: FormEntryProps): any {
	const errors: RecusiveFormErrors<FormSubmissionDto> = { data: { sections: [] } };

	v.data.sections.forEach((section, sectionIndex) => {
		let sectionErrors: RecusiveFormErrors<FormDataSection> = { fields: [] };
		const sectionData = props.currentForm.formDefinition.sections[sectionIndex];

		if (!section.fieldOptions || !section.fieldOptions.hide) {
			//fields validation
			section.fields.forEach((field, fieldIndex) => {
				const fieldData = sectionData.fields[fieldIndex];
				let fieldErrors: RecusiveFormErrors<FormSubmissionField> = {
					subGroupedValues:
						field.subGroupedValues && !!fieldData.subFields
							? field.subGroupedValues.map(x => new Array(fieldData.subFields.length))
							: !!fieldData.matrixFields
							? field.subGroupedValues.map(x => new Array(x.length))
							: []
				};

				if (!field.fieldOptions || !field.fieldOptions.hide) {
					if (fieldData.fieldType === "RepeatingGroup") {
						if (fieldData.minRowsRequired && fieldData.minRowsRequired > field.subGroupedValues.length) {
							fieldErrors.subGroupedValues = { _error: `Minimum ${fieldData.minRowsRequired} row(s) required` };
						} else {
							field.subGroupedValues.forEach((subrow, rowIndex) => {
								subrow.forEach((cellValue, cellIndex) => {
									const cellData = fieldData.subFields[cellIndex];
									if (!cellValue.fieldOptions.hide) {
										//dont test hidden fields
										fieldErrors.subGroupedValues[rowIndex][cellIndex] = {
											value: validateFieldType(
												cellData.fieldType,
												cellValue.fieldOptions.required,
												cellValue.fieldOptions.requiredOverride,
												cellValue
											)
										};
									}
								});
							});
						}
					} else if (fieldData.fieldType === "Matrix") {
						field.subGroupedValues.forEach((subrow, rowIndex) => {
							subrow.forEach((cellValue, cellIndex) => {
								const cellData = fieldData.matrixFields[rowIndex].fields[cellIndex];
								if (!cellValue.fieldOptions.hide) {
									//dont test hidden fields
									fieldErrors.subGroupedValues[rowIndex][cellIndex] = {
										value: validateFieldType(
											cellData.fieldType,
											cellValue.fieldOptions.required,
											cellValue.fieldOptions.requiredOverride,
											cellValue
										)
									};
								}
							});
						});
					} else {
						if (!field.fieldOptions.hide) {
							//dont test hidden fields
							fieldErrors.value = validateFieldType(
								fieldData.fieldType,
								field.fieldOptions.required,
								field.fieldOptions.requiredOverride,
								field
							);
						}
					}
				}

				sectionErrors.fields[fieldIndex] = fieldErrors;
			});
		}

		errors.data.sections[sectionIndex] = sectionErrors;
	});

	return errors;
}

function validateFieldType(type: FormFieldTypes, isReq: boolean, isReqOverride: boolean, data: FormSubmissionField): any {
	if (isReqOverride !== undefined && isReqOverride !== null) {
		isReq = isReqOverride;
	}
	switch (type) {
		case "VehicleRegoId":
		case "Text":
		case "TypeaheadText":
		case "Dropdown":
		case "Time":
		case "Radio":
		case "YesNo":
		case "Number":
		case "YesNoNA":
		case "GenericTypeahead":
		case "GenericTypeaheadObject":
		case "ButtonList":
		case "Date":
		case "TimeAndGPS":
		case "TextMultiLine": {
			if (isReq && (nullOrUndef(data.value) || data.value === "")) {
				return "Required";
			}
			break;
		}

		case "Checkbox":
		case "ButtonListMulti":
		case "DropdownMultiChoice": {
			if (isReq && (nullOrUndef(data.value) || !data.value.length)) {
				return "Required";
			}
			break;
		}
		case "MarkPicture":
		case "Signature": {
			if (isReq && (nullOrUndef(data.value) || !data.value.amazonUrl)) {
				return "Required";
			}
			break;
		}
		case "Photo": {
			if (isReq && (nullOrUndef(data.value) || !data.value.length || data.value.some(x => !x.amazonUrl))) {
				return "Required";
			}
			break;
		}
		case "RepeatingGroup":
		case "Matrix": {
			throw new Error("process RepeatingGroup /matrix in other func");
		}
		case "HtmlRender":
		case "StaticImage":
		case "Label":
		case "GPS":
		default:
			return undefined;
			
	}
}

function nullOrUndef(item: any) {
	return item === null || item === undefined;
}
