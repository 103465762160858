import securityservice from "services/security/security";
import databaseservice from "./database";
import config from "config/config";
import * as uuid4 from "uuid/v4";
import { deepClone, number, string } from "utils";
import { HttpUtils, IHttpObject } from "utils/http";
import { OfflineHttpRecord } from "./typings";
import SecurityService from "services/security/security";
const MeWorker = require("services/offline/workers2/httprequest.worker.js");

export interface WorkerNotification {
	type: "request_failed" | "request_success";
	key: string;
	value: any;
	status: number;
}

export default class offlinerequest {
	private static tableName = "httpRequest";
	private static httpRequestWorker: Worker = null;
	private static notifys = {};
	private static id = 0;
	private static forceClearToken = false;

	static initialize() {
		//create the worker
		this.httpRequestWorker = new MeWorker();
		this.httpRequestWorker.onmessage = e => {
			if (e.data) {
				switch (e.data.type) {
					case "request_success":
					case "request_failed":
						for (const key in this.notifys) {
							if (this.notifys.hasOwnProperty(key)) {
								const func = this.notifys[key];
								func(e.data);
							}
						}
						break;
					default:
						throw new Error("unknown command sent from offline request web worker");
				}
			}
		};

		this.startWorker();
		if (this.forceClearToken === true) {
			this.clearToken();
		}
	}

	private static startWorker() {
		this.httpRequestWorker.postMessage({ type: "start", dbName: config.indexedDbName, dbVersion: databaseservice.dbVersion });
	}

	static stopWorker() {
		this.httpRequestWorker.postMessage({ type: "stop" });
	}

	/**
	 * Subscribe to offline request events. returns an unsubscirbe func
	 * @param func
	 */
	static subscribe(func: (data?: WorkerNotification) => void) {
		const id = this.id++;
		this.notifys[id] = func;
		return () => {
			delete this.notifys[id];
		};
	}

	static setToken(token: string) {
		this.httpRequestWorker.postMessage({ type: "token", token });
	}

	static clearToken() {
		if (this.httpRequestWorker) {
			this.httpRequestWorker.postMessage({ type: "cleartoken" });
			this.forceClearToken = false;
		}
		else {
			this.forceClearToken = true;
		}
	}

	/**
		url: string required - the url to make the request to.
		method: string required - the http request method.
		params: object optional - name-value pairs of request params. Default null.
		data: object optional - request content. Default null.
		requestId: string optional - the database id of theis request. Useful to set if expecting to update entries and need a handle on it. Must be unique(i.e guid). Default - guid is generated 
		RETURNS: promise. resolved if request is successfully added to database, else rejects with reason.
  */
	static saveRequest(httpObject: IHttpObject, requestId?: string, addNullToParams?: boolean, timeoutms?: number) {
		let { url, method, data, headers, ...params } = httpObject;

		let token = SecurityService.iframeToken ? SecurityService.iframeToken : localStorage.getItem(config.tokenKey);
		let exp = SecurityService.iframeExpiry ? SecurityService.iframeExpiry : localStorage.getItem(config.tokenExpDateKey);
		
		let setHeaders: any = headers || {
			Accept: "application/json",
			"Content-Type": "application/json"
		};

		if (token) {
			setHeaders.Authorization = "Bearer " + token;
		}

		let querystring: string = null;
		if (!HttpUtils.isEmpty(params)) {
			const querys = HttpUtils.serialize(params, addNullToParams);
			if (querys) {
				querystring = querys;
			}
		}

		let body: any = null;
		if (data) {
			if (typeof data === "string" || data instanceof FormData || data instanceof Blob || data instanceof ArrayBuffer) {
				//dont stringify a string or certian objects
				body = data;
			} else {
				body = JSON.stringify(HttpUtils.convertDate(deepClone(data), true));
			}
		}

		return new Promise((resolve, reject) => {
			if (!url) {
				reject("Must supply url creating offline request");
			} else if (!method) {
				reject("Must supply request method creatign offline request");
			} else {
				//create the record
				let queueRecord: OfflineHttpRecord = {
					url: url,
					method: method,
					querystring: querystring,
					data: body,
					timeout: timeoutms,
					requestId: requestId ? requestId : uuid4(),
					retryCount: 0,
					headers: setHeaders
				};
				databaseservice.upsert(this.tableName, queueRecord, true).then(x => {
					//tell thread to upload now
					this.httpRequestWorker.postMessage({ type: "process" });
					console.log("Offline Request Added -> " + url);
					resolve(x);
				}, reject);
			}
		});
	}
}
