import { http } from "../utils/index";
import config from "config/config";
import showToast from "components/Toast/Toast";

const route = "Client/";

export default class clientservice {
    static GetClientConfig(clientId: string) {
        let url = `${config.baseUrl + route + clientId}/GetClientConfig`;

        return http({url, method: 'GET'})
            .then(data => data)
            .catch(error => {
                showToast("error", error.error);
                return error;
            });
    }
}