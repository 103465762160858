import { http, DateTime } from "../utils/index";
import config from "config/config";
import showToast from "components/Toast/Toast";
import { FormVersionDto } from "redi-types";

const route = "Form/";

export default class formservice {
	static GetFormsList(query) {
		let url = `${config.baseUrl + route}Get`;

		return http({ url, method: "GET", query })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static GetFormVersion(formVersionId: string){
		let url = `${config.baseUrl + route}/GetFormVersion`;

		return http({ url, method: "GET", formVersionId})
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static GetForm(id) {
		let url = `${config.baseUrl + route + id}/Get`;

		return http({ url, method: "GET" })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static Create(form: FormVersionDto) {
		let url = `${config.baseUrl + route}Create`;

		return http({ url, method: "POST", data: form })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static Update(form: FormVersionDto) {
		let url = `${config.baseUrl + route + form.formId}/Form/${form.formVersionId}/Update`;

		return http({ url, method: "POST", data: form })
			.then(data => {
				showToast("success", "Save Successful");
				return data;
			})
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}
}
