import clientservice from "services/client";
import { put, call, takeEvery, all, select } from "redux-saga/effects";
import { persist } from "utils/index";
import { ReduxAction, ClientConfigDataDto } from "redi-types";
import { types as logintypes } from "./loginRedux";

const initialState = {
    clientConfig: {
		logoUrl: "",
        defaultBackgroundUrl: "",
        formAccess: []
    },

    errors: [],
    isBusy: false
}

const persistConf = {
	whitelist: ["client"],
	expireInNumHours: 0
};

/////////
//types
export const types = {
	GET_CLIENT_CONFIG: "client/GET_CLIENT_CONFIG",
	ON_GET_CLIENT_CONFIG: "client/ON_GET_FORMS_LIST",

	ON_ERROR: "client/ON_ERROR"
};

///////////
//reducers
const reducers = {
	reducer(state = initialState, action: ReduxAction) {
		switch (action.type) {
			case types.GET_CLIENT_CONFIG:
				return { ...state, isBusy: true };
			case types.ON_GET_CLIENT_CONFIG:
				return { ...state, isBusy: false, clientConfig: action.payload.clientConfig };
			case types.ON_ERROR:
				return { ...state, errors: state.errors.concat(action.payload).slice(-10), isBusy: false };
			default:
				return state;
		}
	}
};
export const reducer = persist("client", reducers.reducer, persistConf);

//////////
//sagas
export const sagas = {
	*rootSaga() {
		yield all([this.getClientConfig()]);
    },

    *getClientConfig() {
        yield takeEvery<ReduxAction>(logintypes.STORE_DETAILS, function*({payload}) {
                let currentDetails = yield select<any>(x => x.login.currentDetails);
                if (currentDetails.ClientId) {
                let data = yield call(clientservice.GetClientConfig, currentDetails.ClientId);
                if (data.error) {
                    console.error(data.error);
                    yield put(actions.onError(data.error));
                } else {
                    yield put(actions.onGetClientConfig(data.data));
                }
            }
        });
    }
};


////////
//actions
export const actions = {
    getClientConfig(clientId: string) {
        return {
            type: types.GET_CLIENT_CONFIG,
            payload: { clientId }
        }
    },
    onGetClientConfig(clientConfig: ClientConfigDataDto) {
        return {
            type: types.ON_GET_CLIENT_CONFIG,
            payload: { clientConfig }
        }
    },
    onError(error) {
		return {
			type: types.ON_ERROR,
			payload: error
		};
	}
};