import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import CSSModules from "react-css-modules";
import styles from "./styles.scss";
import { MergeStyles } from "utils/index";
/*

Use:


*/

@MergeStyles(styles)
export default class Radio extends PureComponent {
	static defaultProps = {
		actions: [],
		value: "", // object | string
		titleTransform: item => item, //select title or name from value
		disabled: false,
		titleClass: "",
		radioClass: "",
		radioWrapClass: ""
	};
	static propTypes = {
		actions: PropTypes.array.isRequired,
		onChange: PropTypes.func.isRequired,
		titleTransform: PropTypes.func,
		classes: PropTypes.object //style overrides
	};

	constructor(props) {
		super(props);
	}

	onChange(e, selected) {
		e.stopPropagation();
		if (!this.props.disabled) {
			this.props.onChange(selected);
		}
	}

	handleKeyDown(event, item) {
		// event.preventDefault();
		if (event.keyCode == 32 || event.key === "Enter") {
			//32 = space
			this.onChange(item);
		}
	}

	render() {
		return (
			<div className={this.props.className} styleName="_root">
				{this.props.actions.map((x, i) => {
					const isColourCoded = x.hasOwnProperty("colour");
					let selectColour = undefined;
					let value = undefined;
					if (isColourCoded) {
						selectColour = x.colour;
						value = x.value;
					} else {
						value = x;
					}
					return (
						<div
							key={i}
							styleName={`_radio ${this.props.radioWrapClass}`}
							onClick={e => this.onChange(e, value)}
							tabIndex={0}
							onKeyDown={e => this.handleKeyDown(e, value)}
							isselected={this.props.value === value ? 1 : undefined}
							style={{ backgroundColor: this.props.value === value ? selectColour : undefined }}
						>
							<div
								styleName={`_radio-button ${this.props.radioClass}`}
								isselected={this.props.value === value ? 1 : undefined}
							>
								<div />
							</div>
							<div styleName={`_radio-title ${this.props.titleClass}`}>
								{this.props.titleTransform(value)}
							</div>
						</div>
					);
				})}
			</div>
		);
	}
}
