import * as React from "react";

import * as styles from "./styles.scss";

import * as CSSModules from "react-css-modules";
import ReactHtmlParser from 'react-html-parser';
import { number, string } from "utils/index";
import {
	FormDataField,
	FormDataSection,
	FormSubmissionData,
	FormMatrixRow,
	AssetDto,
	FormSubmissionDto,
	FormSubmissionField
} from "redi-types";
import { Field, WrappedFieldArrayProps, FieldArray } from "redux-form";
import {
	RenderInput,
	RenderDropdown,
	RenderMultiDropdown,
	RenderCalender,
	RenderRadio,
	RenderSignaturePad,
	RenderPhoto,
	RenderFile,
	RenderTimePicker,
	RenderMultiCheckBox,
	RenderButtonList,
	RenderButtonListMulti,
	RenderMarkImage,
	RenderTypeahead,
	RenderCheckBox,
	RenderVehicleTypeahead,
	RenderTimeLocation,
} from "components/FormInputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormEntryContext } from "./FormEntry";
import { RenderFormTableFields, RenderFormTableFieldsProps } from "./RenderSectionsGroups";
import formsubmissionservice, { ITypeaheadObject } from "services/formsubmission";
import { Portal } from "components/Portal/portal";
import vehicleservice from "services/vehicle";
import Button from "components/Button/Button";
import { RenderTypeaheadProps } from "components/FormInputs/FormTypeahead";
import { RenderMarkImageProps } from "components/FormInputs/FormMarkImage";
import { RenderVehicleTypeaheadProps } from "components/FormInputs/FormVehicleTypeAhead";
import { IFormEntryContext } from "./Hoc";
import { SelectItem, /*, TypeaheadMenuItem*/} from "redi-common-inputs";
import { TypeaheadMenuItem } from "components/Typeahead/Typeahead";
import * as uuid4 from "uuid/v4";
import { geo} from "utils/index";
import SecurityService from "services/security/security";

export type RenderFormFieldsProps = {
	section?: FormDataSection;
	groupData?: FormDataField;
	repeatingGroupRow?: number;
	matrixRow?: FormMatrixRow;
	isPorted?: boolean;
	portalIds?: string[];
};

/**
 * render fields in sections
 */
@CSSModules(styles, { allowMultiple: true })
export class RenderFormFields extends React.PureComponent<RenderFormFieldsProps & WrappedFieldArrayProps<any>, {}> {
	static contextType = FormEntryContext;
	context: IFormEntryContext;

	//map the path of the 'Vehicle Make' and 'Vehicle Model' input fields
	vehicleMakePath: string;
	vehicleModelPath: string;
	isMobile: boolean;
	constructor(props) {
		super(props);
	}
	render() {
		const {
			fields,
			meta: { error, submitFailed }
		} = this.props;

		this.isMobile = window.innerWidth < styles.small;

		if (this.props.groupData) {
			//is a repeating group render as table
			return fields.map((member, index) => {
				const field: FormDataField = this.props.groupData.subFields
					? this.props.groupData.subFields[index]
					: this.props.matrixRow.fields[index];

				return (
					//when on mobile and collapseByColumn, we need to sort the cards by column and by row, as they are by default. So port these cells to
					//   the respective PortalAnchor inside RenderFormTableFields component. the form json structure is still in rows->columns doing it this way
					//   re arranges the UI without modifying the form json or template in any way
					<Portal
						to={this.props.portalIds ? this.props.portalIds[index] : this.props.groupData.id}
						disable={!this.props.isPorted}
						key={index}
					>
						<td key={index} fieldtype={field.fieldType}>
							{this.props.matrixRow && (
								<label htmlFor={member}>
									{this.props.isPorted ? this.props.matrixRow.label : field.label}
									{field.isRequired && <span> *</span>}
								</label>
							)}
							{this.checkFieldForRepeating(field, member, true, member, this.props.repeatingGroupRow)}
							{submitFailed && error && <div styleName="input-error">{error}</div>}
						</td>
					</Portal>
				);
			});
		} else {
			return (
				<div>
					{fields.map((member, index) => {
						const field: FormDataField = this.props.section
							? this.props.section.fields[index]
							: this.props.groupData.subFields[index];
						return (
							<div styleName="field-sections" key={index}>
								{this.checkFieldForRepeating(field, member, false)}
							</div>
						);
					})}
					{submitFailed && error && <div styleName="input-error">{error}</div>}
				</div>
			);
		}
	}

	checkFieldForRepeating(field: FormDataField, member: string, insideRepeating: boolean, id?: string, repeatingGroupRow?: number) {
		if (field.fieldType === "RepeatingGroup") {
			return (
				<FieldArray<RenderFormTableFieldsProps>
					name={`${member}.subGroupedValues`}
					component={RenderFormTableFields}
					props={{
						path: member,
						groupData: field,
						repeatingGroupRow
					}}
				/>
			);
		} else if (field.fieldType === "Matrix") {
			return (
				<FieldArray<RenderFormTableFieldsProps>
					name={`${member}.subGroupedValues`}
					component={RenderFormTableFields}
					props={{
						path: member,
						groupData: field,
						repeatingGroupRow
					}}
				/>
			);
		} else {
			return this.renderType(field, member + ".value", insideRepeating, id, repeatingGroupRow);
		}
	}

	/**
	 * @param data form field definition
	 * @param path path to field
	 * @param insideRepeating inside repeating group or matrix
	 * @param id id attribute to pass to input components that support it
	 */
	renderType(data: FormDataField, path: string, insideRepeating: boolean, id?: string, repeatingGroupRow?: number) {
		return (
			<RenderActualField
				data={data}
				path={path}
				insideRepeating={insideRepeating}
				id={id}
				isMobile={this.isMobile}
				repeatingGroupRow={repeatingGroupRow}
			/>
		);
	}
}

interface RenderActualFieldProps {
	data: FormDataField;
	path: string;
	insideRepeating: boolean;
	repeatingGroupRow?: number;
	id?: string;
	isMobile: boolean;
}

@CSSModules(styles, { allowMultiple: true })
class RenderActualField extends React.PureComponent<RenderActualFieldProps> {
	static contextType = FormEntryContext;
	context: IFormEntryContext;

	yesNo: { text: string; value: boolean; color: string }[];
	yesNoNA: { text: string; value: any; color: string }[];

	constructor(props, context: IFormEntryContext) {
		super(props);
		this.yesNo = [
			{ text: "Yes", value: true, color: "#00A17E" },
			{ text: "No", value: false, color: "#EA4335" }
		];
		this.yesNoNA = [
			{ text: "Yes", value: true, color: "#00A17E" },
			{ text: "No", value: false, color: "#EA4335" },
			{ text: "N/A", value: "N/A", color: "#FBBC05" }
		];

		context.registerField(this.props.path, {
			id: this.props.data.id,
			logic: this.props.data.logic,
			actions: this.props.data.actions,
			repeatingGroupRow: this.props.repeatingGroupRow
		});

		const actions = this.props.data.actions;
		if (actions && actions.length) {
			const performNow = actions.filter(x => x.when === "onLoad");
			for (let index = 0; index < performNow.length; index++) {
				const action = performNow[index];
				context.performAction(this.props.path, action);
			}
		}
		
	}

	componentWillUnmount() {
		this.context.unRegisterField(this.props.path);
	}
	
	render() {
		const { data, path, insideRepeating, id, isMobile } = this.props;
		const fieldOptions: FormSubmissionField["fieldOptions"] = this.context.getValue(path.replace(/value$/, "fieldOptions"));
		const changeCb = data.callOnChange ? (val: any) => {
			this.context.callOnChange(data.id, val); 
		}: undefined;
		let isRequired = data.isRequired;
		if (fieldOptions && fieldOptions.hide) {
			return null;
		} else if (fieldOptions && fieldOptions.requiredOverride !== undefined && fieldOptions.requiredOverride !== null) {
			isRequired = fieldOptions.requiredOverride;
		}
		
		//Todo: For Daily Timesheet Form, calculate total hours based on start and end time
		const getCalculatedHours = (start, end) => {
			 // Convert timestamps into Date objects
			 const startDate = new Date(start);
			 const endDate = new Date(end);
		 
			 // Calculate time difference in milliseconds
			 const diffInMilliseconds = endDate.getTime() - startDate.getTime();
		 
			 // Convert milliseconds into hours, minutes, and seconds
			 const hours = Math.floor(diffInMilliseconds / 3600000);
			 const minutes = Math.floor((diffInMilliseconds % 3600000) / 60000);
			 const seconds = Math.floor((diffInMilliseconds % 60000) / 1000);
			 const result = `${hours} hours ${minutes} mins ${seconds} seconds`;
			 console.log(result);
			 return result;
		}
		switch (data.fieldType) {
			case "HtmlRender":
				return (
					<div>
						<div formlabel="1">{ReactHtmlParser(data.label)}</div>
						<pre styleName="pre-wrap">{ReactHtmlParser(data.defaultValue)}</pre>
					</div>
				);
			case "Label":
				return (
					<div>
						<div formlabel="1">{data.label}</div>
						<pre styleName="pre-wrap">{data.defaultValue}</pre>
					</div>
				);
			case "StaticImage":
				return (
					<div>
						<div formlabel="1">{data.label}</div>
						<br />
						<img
							src={data.imgSrc}
							style={{
								height: data.height ? data.height : "auto",
								width: data.width ? data.width : "auto"
							}}
						/>
					</div>
				);
			case "Text":
				return (
					<Field
						name={path}
						component={RenderInput as any}
						label={data.label}
						required={isRequired}
						onChange={changeCb}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						id={id}
					/>
				);
			case "TypeaheadText":
				return (
					<Field<any>
						name={path}
						component={RenderTypeahead}
						label={data.label}
						required={isRequired}
						onChange={changeCb}
						allowBlankSelections={(item: string) => item}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						getData={val => {
							return formsubmissionservice.GetTypeaheadFieldValues(data.id, this.context.formId, val);
						}}
						id={id}
					>
						{data =>
							data.map(c => (
								<TypeaheadMenuItem key={c} value={c}>
									{c}
								</TypeaheadMenuItem>
							))
						}
					</Field>
				);
			case "GenericTypeahead":
				return (
					<Field<any>
						name={path}
						component={RenderTypeahead}
						label={data.label}
						required={isRequired}
						onChange={changeCb}
						allowBlankSelections={(item: string) => item}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						getData={val => {
							return formsubmissionservice.GenericTypeaheadFetch(data.id, this.context.formId, val, data.endpointUri);
						}}
						id={id}
					>
						{data =>
							data.map((c, i) => (
								<TypeaheadMenuItem key={c + i} value={c}>
									{c}
								</TypeaheadMenuItem>
							))
						}
					</Field>
				);
			case "GenericTypeaheadObject":
				return (
					<Field<any>
						name={path}
						component={RenderTypeahead}
						label={data.label}
						required={isRequired}
						titleTransform={f => {
							if (typeof f === "object") {
								return f.displayValue;
							} else {
								return f;
							}
						}}
						onChange={f => changeCb(f)}
						allowBlankSelections={
							data.disableBlankField
								? undefined
								: (item: string) => {
										return { id: uuid4(), displayValue: item };
								  }
						}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						getData={val => {
							let objectData = null;
							if (data.pullValueFromFieldId) {
								objectData = this.context.getValue(this.context.getPath(data.pullValueFromFieldId));
							}
							return val
								? formsubmissionservice.GenericTypeaheadObjectFetch(
										data.id,
										this.context.formId,
										val,
										data.endpointUri,
										objectData
								  )
								: Promise.resolve({});
						}}
						id={id}
					>
						{data =>
							data.map(c => (
								<TypeaheadMenuItem key={c.id} value={c}>
									{c.displayValue}
								</TypeaheadMenuItem>
							))
						}
					</Field>
				);

			case "Number":
				return (
					<Field
						name={path}
						component={RenderInput as any}
						label={data.label}
						type="number"
						required={isRequired}
						onChange={changeCb}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						min={data.minValue}
						max={data.maxValue}
						id={id}
					/>
				);
			case "TextMultiLine":
				return (
					<Field
						name={path}
						component={RenderInput as any}
						label={data.label}
						multiline={true}
						required={isRequired}
						onChange={changeCb}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						id={id}
					/>
				);
			case "Dropdown":
				return data.btnListOnMobile && isMobile ? (
					<Field
						name={path}
						component={RenderButtonList}
						label={data.label}
						actions={data.choices}
						menuClass="field-dropdown-menus"
						classes={styles}
						required={isRequired}
						onChange={changeCb}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						id={id}
					/>
				) : (
					<Field
						name={path}
						component={RenderDropdown}
						label={data.label}
						menuClass="field-dropdown-menus"
						classes={styles}
						required={isRequired}
						onChange={changeCb}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						id={id}
						useShellPortal={true}
					>
						{data.choices.map((val, index) => (
							<SelectItem key={val} value={val}>
								{val}
							</SelectItem>
						))}
					</Field>
				);
			case "DropdownMultiChoice":
				return (
					<Field
						name={path}
						component={data.btnListOnMobile && isMobile ? RenderButtonListMulti : RenderMultiDropdown}
						label={data.label}
						actions={data.choices}
						menuClass="field-dropdown-menus"
						classes={styles}
						required={isRequired}
						onChange={changeCb}
						titleComponent={props => {
							let checked = props.actions.filter(g => g.checked).map(g => props.titleTransform(g.item));
							if (checked.length === props.actions.length) {
								checked = ["All"];
							}
							return (
								<div styleName="multichevron">
									<div>{checked.join(", ")}</div>
									{props.open ? <FontAwesomeIcon icon="chevron-up" /> : <FontAwesomeIcon icon="chevron-down" />}
								</div>
							);
						}}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						id={id}
					/>
				);
			case "Photo":
				return (
					<Field
						name={path}
						component={RenderPhoto as any}
						label={data.label}
						required={isRequired}
						onChange={changeCb}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						id={id}
					/>
				);
			case "File":
				return (
					<Field
						name={path}
						component={RenderFile as any}
						label={data.label}
						required={isRequired}
						onChange={changeCb}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						id={id}
					/>
				);
			case "Radio":
				//Type of choices can be [value, value] or [{value: value, colour: colour}, {value: value, colour: colour}]
				//e.g. type IChoice =  (string | boolean | number)[] | { value: string | boolean | number, colour: string }[];
				return (
					<Field
						name={path}
						component={RenderRadio as any}
						label={data.label}
						actions={data.choices}
						required={isRequired}
						onChange={changeCb}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						id={id}
					/>
				);
			case "Checkbox":
				return (
					<Field
						name={path}
						component={RenderCheckBox as any}
						label={data.label}
						required={isRequired}
						onChange={changeCb}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						id={id}
					/>
				);
			case "CheckboxMultiChoice":
				return (
					<Field
						name={path}
						component={RenderMultiCheckBox as any}
						label={data.label}
						actions={data.choices}
						required={isRequired}
						onChange={changeCb}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						id={id}
					/>
				);
			case "GPS": {
				const val = this.context.getValue(path);
				if (!val && this.context.coords) {
					setTimeout(() => {
						this.context.change(path, {
							latitude: this.context.coords.latitude,
							longitude: this.context.coords.longitude
						});
					});
				}
				return (
					<div>
						<div formlabel="1">
							{data.label} {isRequired && <span> *</span>}
						</div>
						{this.context.coords && (
							<div styleName="gpsval">{`${number.formatNumber(
								this.context.coords.latitude,
								4
							)}, ${number.formatNumber(this.context.coords.longitude, 4)}`}</div>
						)}
					</div>
				);
			}
			case "Date":
				return (
					<Field
						name={path}
						component={RenderCalender as any}
						label={data.label}
						required={isRequired}
						onChange={changeCb}
						widthOfRoot={!insideRepeating}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						id={id}
					/>
				);
			case "Time":
				return (
					<Field
						name={path}
						component={RenderTimePicker as any}
						label={data.label}
						required={isRequired}
						onChange={changeCb}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						id={id}
					/>
				);
			case "TimeAndGPS":
				return (
					<Field
						name={path}
						component={RenderTimeLocation as any}
						label={data.label}
						required={isRequired}
						onChange={changeCb}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						id={id}
					/>
				);
			//spefic for the total hours field of the daily timesheet.
			case "TotalHours":{
				//get the start time from start field, end tile from the end field.m then do the caculation.
				const startTime = this.context.getValue("data.sections[0].fields[0].value")?.time;
				const endTime = this.context.getValue("data.sections[0].fields[1].value")?.time;
				const val = this.context.getValue(path);
				const totalHours =startTime&&endTime? getCalculatedHours(startTime, endTime): null
				if (!val&& totalHours) {
					setTimeout(() => {
						this.context.change(path, totalHours);
					});
				}
				return (
						<div>
							<div formlabel="1">
								{data.label} {isRequired && <span> *</span>}
							</div>
							{totalHours && (
								<div styleName="gpsval">{totalHours}</div>
							)}
						</div>
					)
			};
			case "Signature":
				return (
					<Field
						name={path}
						component={RenderSignaturePad as any}
						label={data.label}
						required={isRequired}
						onChange={changeCb}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						id={id}
					/>
				);
			case "MarkPicture":
				return (
					<Field<RenderMarkImageProps>
						name={path}
						component={RenderMarkImage}
						label={data.label}
						required={isRequired}
						onChange={changeCb}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						backgroundImage={data.backgroundImage}
					/>
				);
			case "ButtonList":
				return (
					<Field
						name={path}
						component={RenderButtonList as any}
						label={data.label}
						actions={data.choices}
						required={isRequired}
						onChange={changeCb}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						id={id}
					/>
				);
			case "ButtonListMulti":
				return (
					<Field
						name={path}
						component={RenderButtonListMulti as any}
						label={data.label}
						actions={data.choices}
						required={isRequired}
						onChange={changeCb}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						id={id}
					/>
				);
			case "YesNo":
				return (
					<Field
						name={path}
						component={RenderButtonList as any}
						label={data.label}
						actions={this.yesNo}
						titleTransform={x => x.text}
						valueTransform={x => x.value}
						required={isRequired}
						onChange={changeCb}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						id={id}
					/>
				);
			case "YesNoNA":
				return (
					<Field
						name={path}
						component={RenderButtonList as any}
						label={data.label}
						actions={this.yesNoNA}
						titleTransform={x => x.text}
						valueTransform={x => x.value}
						required={isRequired}
						onChange={changeCb}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						id={id}
					/>
				);
			case "VehicleRegoId":
				return (
					<Field<RenderVehicleTypeaheadProps<any>>
						name={path}
						component={RenderVehicleTypeahead}
						label={data.label}
						required={isRequired}
						onChange={changeCb}
						allowBlankSelections={(item: string) => {
							return { assetId: uuid4(), value: item };
						}}
						disabled={this.context.disabled || data.readonly || fieldOptions.readonly}
						titleTransform={obj => {
							if (typeof obj === "string") {
								return obj;
							} else {
								return obj.value;
							}
						}}
						coords={this.context.coords}
					/>
				);

			case "RepeatingGroup":
			case "Matrix":
				throw new Error("RepeatingGroup/matrix processed outside this func");
			default:
				break;
		}
	}
}
