import React from "react";
import styles from "./styles.scss";
import { MergeStyles } from "utils/index";
import FilePreview from "components/Media/preview/FilePreview";

class FormFile extends React.PureComponent {
	static defaultProps = {
		single: false
	};
	constructor(props) {
		super(props);
		this.onUpload = this.onUpload.bind(this);
	}
	render() {
		let {
			input,
			label,
			required,
			meta: { touched, error },
			disabled,
			single
		} = this.props;

		const value = Array.isArray(input.value) ? input.value : input.value ? [input.value] : [];

		return (
			<div className={this.props.className} styleName="input-group">
				<div formlabel="1">{label}{ required && <span> *</span> }</div>
				<FilePreview allowUpload={!disabled} disabled={disabled} onChange={this.onUpload} files={value} />
				{touched && error && <div styleName="_input-error">{error}</div>}
			</div>
		);
	}

	onUpload(filelinks) {
		this.props.input.onChange(this.props.single ? filelinks.last() : filelinks);
	}
}

export const RenderFile = MergeStyles(styles, { forwardRef: false })(FormFile);
