import * as React from "react";
import config from "config/config";
import { MergeStyles, number, shouldUpdate, http } from "utils";
import * as styles from "./styles.scss";
import * as PropTypes from "prop-types";
import Modal from "components/BaseModal/ModalImpl/ModalImpl";
import { CommonUIProps, CommonComponentProps } from "redi-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import autoBind from "libs/react-autobind";
import mediaservice from "services/media/mediaservice";
import ImageLoader from "../ImageLoader";
import FileUpload from "../file/Upload";
import Button from "components/Button/Button";
import { MediaImage } from "services/media/mediatypes";
import ImageEdit, { ImageEditProps } from "../edit/ImageEdit";

@MergeStyles(styles)
export default class ImageEditor extends React.Component<Props, State> {
	static defaultProps = {
		showName: true
	};

	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {};
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["onChange", "onClose"]);
	}

	render() {
		return (
			<Modal isOpen={this.props.open} onClose={this.props.onClose} rootClass="_imgp_modal" styleName="_impg_modal" classes={styles}>
				<div className={this.props.className} styleName="_imgp_root">
					<div styleName="_imp_content-edit-wrap">
						<div styleName="_imgp_content">
							<ImageEdit {...this.props} zIndex={199} styleName="_imgp_main" />
							{this.props.showName && <div styleName="_impg_name">{this.props.image.name}</div>}
						</div>
						<div styleName="_imgp_sides-right">
							<div styleName="_impg_buttons">
								<FontAwesomeIcon icon="times" size="3x" color="#fff" onClick={this.props.onClose} />
							</div>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

interface Props extends ImageEditProps {
	open: boolean;
	onClose: () => void;
	showName?: boolean;
}

interface State {}
