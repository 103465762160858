import asyncLoader from "utils/asyncLoader";

const FormsEntry = asyncLoader(() => import("ui/Forms/Entry/Hoc"));
const FormsList = asyncLoader(() => import("ui/Forms/List/FormList"));
const FormsEdit = asyncLoader(() => import("ui/Forms/Edit/FormEdit"));
const NewFromLink = asyncLoader(() => import("ui/Forms/NewSubFromLink/NewFromLink"));
const Login = asyncLoader(() => import("ui/Login/Login"));
const Account = asyncLoader(() => import("ui/Account/Account"));
const Schedule = asyncLoader(() => import("ui/Schedule/Schedule"));
const TechSchedule = asyncLoader(() => import("ui/TechSchedule/TechSchedule"));
const CompanySchedule = asyncLoader(() => import("ui/CompanySchedule/CompanySchedule"));
const Job = asyncLoader(() => import("ui/Job/Job"));

export default [
	{
		path: "/TechSchedule",
		exact: true,
		component: TechSchedule
	},
	{
		path: "/CompanySchedule",
		exact: true,
		component: CompanySchedule
	},
	{
		path: "/Schedule",
		exact: true,
		component: Schedule
	},
	{
		path: "/Form/List",
		exact: true,
		component: FormsList
	},
	{
		path: "/Form/Entry/:formId/Submission/:formSubmissionId",
		exact: true,
		component: FormsEntry
	},
	{
		path: "/Form/Entry/:formId",
		exact: true,
		component: NewFromLink
	},
	{
		path: "/Form/Edit/:formId",
		exact: true,
		component: FormsEdit
	},
	{
		path: "/Form/Edit",
		exact: true,
		component: FormsEdit
	},
	{
		path: "/Account",
		exact: true,
		component: Account
	},
	{
		path: "/Login",
		exact: true,
		component: Login
	},
	{
		path: "/Job/:jobId",
		exact: true,
		component: Job
	}
];
