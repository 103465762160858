import * as React from "react";
import * as styles from "./styles.scss";
import { shouldUpdate, MergeStyles, ImageUtil, string, number } from "utils/index";
import mediaservice from "services/media/mediaservice";
import config from "config/config";
import Button from "components/Button/Button";
import { CommonComponentProps } from "redi-types";
import { WrappedFieldProps } from "redux-form";
import { MediaImage } from "services/media/mediatypes";
import autoBind from "libs/react-autobind";
import Modal from "components/BaseModal/Modal";
import ImageEdit from "components/Media/edit/ImageEdit";
import ImageEditor from "components/Media/fullscreenEdit/ImageEditor";
import ImageLoader from "components/Media/ImageLoader";

class MarkImg extends React.PureComponent<RenderMarkImageProps & WrappedFieldProps, State> {
	static defaultProps = {};
	ref: any;
	_reinitialized: boolean;
	constructor(props) {
		super(props);
		autoBind(this);
		this.ref = null;
		this._reinitialized = false;

		this.state = {
			editorOpen: false
		};
	}

	render() {
		let {
			input,
			label,
			required,
			meta: { touched, error },
			className,
			disabled,
			...other
		} = this.props;

		const isMobile = window.innerWidth < styles.small;

		//https://github.com/agilgur5/react-signature-canvas#props
		return (
			<div className={className} styleName="input-group">
				<div formlabel="1">
					{label}
					{required && <span> *</span>}
				</div>
				<div styleName="__signature-root">
					{isMobile ? (
						<React.Fragment>
							<ImageLoader
								src={config.amazonUrl + (input.value ? input.value.amazonUrl : this.props.backgroundImage.amazonUrl)}
								fallBackSrc={this.props.backgroundImage.data}
								styleName="__markimage-preview"
								style={{ height: this.props.backgroundImage.height / 2 }}
								onClick={() => this.setState({ editorOpen: true })}
							/>
							<ImageEditor
								onClose={() => this.setState({ editorOpen: false })}
								open={this.state.editorOpen}
								image={this.props.backgroundImage}
								onChange={input.onChange}
								value={input.value}
								showName={false}
							/>
						</React.Fragment>
					) : (
						<ImageEdit image={this.props.backgroundImage} onChange={input.onChange} value={input.value} />
					)}
				</div>
				{touched && error && <div styleName="_input-error">{error}</div>}
			</div>
		);
	}
}

export const RenderMarkImage = MergeStyles(styles, { forwardRef: false })(MarkImg);

export interface RenderMarkImageProps extends CommonComponentProps {
	label?: string;
	required?: boolean;
	disabled?: boolean;
	backgroundImage: MediaImage;
	penColor?: string;

	signatureClass?: string;
	removeAllMarksOnClear?: boolean;
}

interface State {
	editorOpen: boolean;
}
