import React, { PureComponent, Component } from "react";
import PropTypes from "prop-types";
import * as router from "connected-react-router";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import styles from "./styles.scss";
import { shouldUpdate } from "utils/index";
import Button from 'components/Button/Button';
import { __authImp as authImp } from "./authimplement";


/**
 * Config options available for `checkAuth()`
 */
const defaultConf = {
	disable: false, //always authenticate as true
	checkLogin: true, //auth false if not logged in
	extender: null, //function `(state, authenticationRowData, ...args) => bool` run after base authcheck. can be an array of functions that must all return true to be authed
	args: null //args to send to extender funcs. must be  AuthArgs type
};

/**
 * Config options available for `<Auth />`
 */
const defaultConfElement = {
	...defaultConf,
	roles: null,
	navToLogin: false, //nav if unauthed
	showUnauthed: false, //show 401 message if unauthed. Overridden by `navToLogin`
	warn: false, //warn if unauthed
	updateOnChildrenChange: false //rerender when `props.children` change
};

/**
 * Config options available for `AuthHoC()`
 */
const defaultConfHoC = {
	...defaultConfElement,
	navToLogin: true, //since HoC most likely used for entire pages, set this to default true
	showUnauthed: true,
	forwardRef: false //forward refs to `props.forwardedRef`
};

/*************************************************************************************** */

/**
 * Authenticator function.
 * @param {string} roles
 * @param {defaultConf} conf See `defaultConf` object
 * @returns {boolean} Is authed
 */
export function checkAuth(roles, conf) {
	conf = { ...defaultConf, ...conf };
	return authImp(roles, conf);
}

/*************************************************************************************** */

/**
 * Authentication HOC function.
 * `export default AuthHoC(connect(mapStateToProps, bindAction)(Sites));`
 *
 * OR
 *
 * `export default AuthHoC(connect(mapStateToProps, bindAction)(Sites), {options...});`
 * @param {Component} ImportComponent
 * @param {defaultConfHoC} config Options. see `defaultConfHoC` object
 */
export function AuthHoC(ImportComponent, config = null) {
	config = { ...defaultConfHoC, ...config };

	return config.forwardRef
		? React.forwardRef((props, ref) => (
				<Auth config={config}>
					<ImportComponent {...props} forwardedRef={ref} />
				</Auth>
		  ))
		: props => (
				<Auth config={config}>
					<ImportComponent {...props} />
				</Auth>
		  );
}

/*************************************************************************************** */

@CSSModules(styles, { allowMultiple: true })
class AuthComp extends Component {
	static defaultProps = {
		config: Object.assign({}, defaultConfElement)
	};
	static propTypes = {
		config: PropTypes.object
	};
	
	check() {
		let authed = authImp(this.conf.roles, this.conf);
		return authed;
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, this.conf.updateOnChildrenChange ? null : ["children"]);
	}

	render() {
		this.conf = { ...defaultConf, ...this.props.config };
		const authed = this.check();

		if (authed) {
			return this.props.children;
		} else {
			this.conf.warn && console.warn("Unautharized " + (this.conf.roles.join(", ") || ""));
			if (this.conf.navToLogin && !this.props.auth_isLoggedIn) {
				setTimeout(() => {
					this.props.navigate("/Login");
				});
				return null;
			} else if (this.conf.showUnauthed && this.props.auth_isLoggedIn) {
				return (
					<div styleName="content">
						403 Unauthorized
						{!this.props.auth_isLoggedIn && (
							<Button
								onClick={() => {
									this.props.navigate("/Login");
								}}
							>
								Login
							</Button>
						)}
					</div>
				);
			} else {
				return null;
			}
		}
	}
}

const bindAction = dispatch => {
	return {
		navigate: (uri, data) => dispatch(router.push(uri, data)),
		goBack: () => dispatch(router.goBack())
	};
};

const mapStateToProps = state => ({
	auth_route: state.router.location.pathname,
	auth_isLoggedIn: state.login.isLoggedIn,
	auth_loaded: state.login.loaded,
	auth_roles: state.login.roles,
});

/**
 * Element Wrapper to auth specific elements.
 * See `defaultConfElement` for options
 */
export const Auth = connect(
	mapStateToProps,
	bindAction
)(AuthComp);
