import React, { PureComponent } from "react";
import { Field, reduxForm, formValueSelector, reset } from "redux-form";
import styles from "./styles.scss";
import CSSModules from "react-css-modules";
import PropTypes from "prop-types";
import RadioButton from "components/Radio/radio";
import Select from "components/Select/select";
import { DateTime, TimeRange, array, shouldUpdate, MergeStyles } from "utils/index";
import TimeSliderImpl from "components/TimeSlider/timeslider";
import Checkbox from "components/Checkbox/checkbox";
import { Calendar, DateRangePicker } from "libs/react-date-range/index";
import Button from "components/Button/Button";
import MultiSelect from "components/MultiSelect/multiselect";
import Radio from "components/Radio/radio";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


class FormRadio extends React.Component {
	static defaultProps = {
		actions: [],
		value: "", // object | string
		titleTransform: item => item, //select title or name from value

		titleClass: "",
		rootStyle: "",
		radioClass: "",
		radioWrapClass: ""
	};
	render() {
		let {
			input,
			label,
			required,
			placeholder,
			type,
			actions,
			inputClass,
			meta: { touched, error },
			className,
			rootStyle,
			classes,
			disabled,
			...other
		} = this.props;

		return (
			<div className={className} styleName="input-group">
				<div formlabel="1">{label}{ required && <span> *</span> }</div>
				<Radio actions={actions} {...other} {...input} styleName={rootStyle} classes={{ ...styles, ...classes }} disabled={disabled} />
				{touched && error && <div styleName="_input-error">{error}</div>}
			</div>
		);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["titleTransform", "onBlur", "onChange"]);
	}
}

export const RenderRadio = MergeStyles(styles, { forwardRef: false })(FormRadio);
