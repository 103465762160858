import React, { PureComponent } from "react";
import { Field, reduxForm, formValueSelector, reset } from "redux-form";
import styles from "./styles.scss";
import CSSModules from "react-css-modules";
import PropTypes from "prop-types";
import RadioButton from "components/Radio/radio";
import Select from "components/Select/select";
import { DateTime, TimeRange, array, shouldUpdate, MergeStyles } from "utils/index";
import TimeSliderImpl from "components/TimeSlider/timeslider";
import Checkbox from "components/Checkbox/checkbox";
import { Calendar, DateRangePicker } from "libs/react-date-range/index";
import Button from "components/Button/Button";
import MultiSelect from "components/MultiSelect/multiselect";
import Radio from "components/Radio/radio";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class FormCalanderRange extends React.Component {
	static defaultProps = {
		inputClass: "",
		actions: [],
		titleTransform: undefined
	};
	render() {
		let {
			input,
			label,
			required,
			placeholder,
			type,
			actions,
			inputClass,
			multiRange,
			meta: { touched, error },
			classes,
			...other
		} = this.props;

		input.value = input.value || new TimeRange();

		return (
			<div className={other.className} styleName="input-group">
				<div formlabel="1">{label}{ required && <span> *</span> }</div>
				<DateRangePicker
					onChange={d => {
						input.onChange(d.range1);
					}}
					ranges={[input.value]}
					dragSelectionEnabled={false}
					showSelectionPreview={false}
				/>
				{touched && error && <div styleName="_input-error">{error}</div>}
			</div>
		);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["onBlur", "onChange"]);
	}
}

export const RenderDateRange = MergeStyles(styles, { forwardRef: false })(FormCalanderRange);
