//initial redux
import { put, call, takeEvery, fork, all, take, cancel, select } from "redux-saga/effects";
import securityservice from "services/security/security";
import { types as setuptypes } from "boot/setupRedux";
import * as router from "connected-react-router";
import { persist, string, array, createFormSubmissionField } from "utils/index";
import { Action } from "redux";
import { ReduxAction, FormDto, FormVersionDto, FormSubmissionDto, FormDataSection, FormDataField } from "redi-types";
import formservice from "services/forms";
import formsubmissionservice from "services/formsubmission";
import config from "config/config";
import { ReduxState } from "config/reduxRoot";

interface State {
	pendingFormSubmissions: { [x: string]: FormSubmissionDto };
	completedFormSubmissions: FormSubmissionDto[];
	outbox: FormSubmissionDto[];
	deletedFormSubmissionId: string;

	errors: any[];
	isBusy: boolean;
}

const initialState: State = {
	//dictionary of pending submission
	pendingFormSubmissions: {},
	completedFormSubmissions: [],
	outbox: [],

	deletedFormSubmissionId: null,
	errors: [],
	isBusy: false
};

const persistConf = {
	whitelist: ["pendingFormSubmissions", "outbox"],
	expireInNumHours: 0
};

/////////
//types
export const types = {
	//Ensure pending forms submissions is updated everytime a change occurs
	UPDATE_PENDING_FORM_SUBMISIONS: "formsubmission/UPDATE_PENDING_FORM_SUBMISIONS",

	//submit is save of a completed form
	SUBMIT_FORMSUBMISSION: "formsubmission/SUBMIT_FORMSUBMISSION",
	INIT_NEW_FORMSUBMISSION: "formsubmission/INIT_NEW_FORMSUBMISSION",

	//save is called automaitcly every 5 secs when the user fills out a form
	SAVE_FORMSUBMISSION: "formsubmission/SAVE_FORMSUBMISSION",

	DELETE_FORMSUBMISSION: "formsubmission/DELETE_FORMSUBMISSION",

	GET_FORMSUBMISSION: "formsubmission/GET_FORMSUBMISSION",
	ON_GET_FORMSUBMISSION: "formsubmission/ON_GET_FORMSUBMISSION",

	GET_PENDING_FORMSUBMISSION_LIST: "formsubmission/GET_PENDING_FORMSUBMISSION_LIST",
	ON_GET_PENDING_FORMSUBMISSION_LIST: "formsubmission/ON_GET_PENDING_FORMSUBMISSION_LIST",

	GET_COMPLETED_FORMSUBMISSION_LIST: "formsubmission/GET_COMPLETED_FORMSUBMISSION_LIST",
	ON_GET_COMPLETED_FORMSUBMISSION_LIST: "formsubmission/ON_GET_COMPLETED_FORMSUBMISSION_LIST",

	//remove a submission from formSubmissions. since the persist never expires, this would grow in size forever
	REMOVE_FORMSUBMISSION: "formsubmission/REMOVE_FORMSUBMISSION",

	ADD_TO_OUTBOX: "formsubmission/ADD_TO_OUTBOX",
	REMOVE_FROM_OUTBOX: "formsubmission/REMOVE_FROM_OUTBOX",

	ON_SUCCESS: "formsubmission/ON_SUCCESS",
	ON_ERROR: "formsubmission/ON_ERROR"
};

///////////
//reducers
const reducers = {
	reducer(state = initialState, action: ReduxAction): State {
		switch (action.type) {
			case types.UPDATE_PENDING_FORM_SUBMISIONS: {
				//Update (UI specific field) Last Update Date
				let dto = { ...action.payload.submission, lastUpdated: new Date() };
				return {
					...state,
					//update array with new formsubmissiondto
					pendingFormSubmissions: {
						...state.pendingFormSubmissions,
						[action.payload.submission.formSubmissionId]: dto
					}
				};
			}
			case types.SAVE_FORMSUBMISSION:
			case types.GET_FORMSUBMISSION:
			case types.GET_PENDING_FORMSUBMISSION_LIST:
			case types.GET_COMPLETED_FORMSUBMISSION_LIST:
			case types.DELETE_FORMSUBMISSION:
				return { ...state, isBusy: true };
			case types.SUBMIT_FORMSUBMISSION:
				return {
					...state,
					isBusy: true,
					//update array with new formsubmissiondto
					pendingFormSubmissions: {
						...state.pendingFormSubmissions,
						[action.payload.submission.formSubmissionId]: action.payload.submission
					}
				};

			case types.ADD_TO_OUTBOX:
				return { ...state, outbox: state.outbox.concat(action.payload.formSubmission) };
			case types.REMOVE_FROM_OUTBOX: {
				const outbox = state.outbox.filter(x => x.formSubmissionId !== action.payload.formSubmissionId);
				return {
					...state,
					outbox: outbox
				};
			}

			case types.REMOVE_FORMSUBMISSION: {
				let submissions = { ...state.pendingFormSubmissions };
				//Delete the form submission record but keep a reference of it to exclude it when retrieving the pending form submission list
				delete submissions[action.payload.formSubmissionId];
				return { ...state, pendingFormSubmissions: submissions, deletedFormSubmissionId: action.payload.formSubmissionId };
			}

			case types.INIT_NEW_FORMSUBMISSION: {
				const newId = action.payload.newSubmissionId;
				let sub: FormSubmissionDto = {
					clientId: action.payload.clientId,
					formSubmissionId: newId,
					entryFormId: "formsubmission-" + newId,
					formVersion: action.payload.form,
					formId: action.payload.form.formId,
					formVersionId: action.payload.form.formVersionId,
					completedByName: action.payload.userName,
					lastUpdated: new Date(),
					data: {
						sendCopy: false,
						sections: action.payload.form.formDefinition.sections.map((sect: FormDataSection) => {
							return {
								id: sect.id,
								fields: sect.fields.map(createFormSubmissionField)
							};
						})
					}
				};

				return { ...state, pendingFormSubmissions: { ...state.pendingFormSubmissions, [sub.formSubmissionId]: sub } };
			}

			case types.ON_GET_FORMSUBMISSION:
				return {
					...state,
					isBusy: false,
					pendingFormSubmissions: {
						...state.pendingFormSubmissions,
						[action.payload.formSubmission.formSubmissionId]: action.payload.formSubmission
					}
				};

			case types.ON_GET_PENDING_FORMSUBMISSION_LIST: {
				//Copy of existing pending submissions
				let subs = { ...state.pendingFormSubmissions };
				//Add and Update any pending form submissions
				for (let index = 0; index < action.payload.formSubmissions.length; index++) {
					const fs = action.payload.formSubmissions[index];
					//Ensures the pending submissions list does not hold old data yet to be saved on the DB
					//Also ensures a deleted form submission is not reintroduced
					if (
						state.deletedFormSubmissionId != fs.formSubmissionId &&
						(!subs[fs.formSubmissionId] ||
							!subs[fs.formSubmissionId].lastUpdated ||
							fs.modifiedOnUtc >= subs[fs.formSubmissionId].lastUpdated)
					) {
						subs[fs.formSubmissionId] = fs;
					}
				}

				//Remove any pending form submissions that do not exist
				for (var formSubmissionId in subs) {
					var exists = action.payload.formSubmissions.find(x => x.formSubmissionId == formSubmissionId);
					if (!exists) {
						delete subs[formSubmissionId];
					}
				}

				return { ...state, isBusy: false, pendingFormSubmissions: subs, deletedFormSubmissionId: null };
			}

			case types.ON_GET_COMPLETED_FORMSUBMISSION_LIST: {
				return { ...state, isBusy: false, completedFormSubmissions: action.payload.formSubmissions };
			}

			case types.ON_SUCCESS:
				return { ...state, isBusy: false };

			case types.ON_ERROR:
				return { ...state, errors: state.errors.concat(action.payload).slice(-10), isBusy: false };

			default:
				return state;
		}
	}
};
export const reducer = persist("formsubmission", reducers.reducer, persistConf);

//////////
//sagas
export const sagas = {
	*rootSaga() {
		yield all([this.submit(), this.delete(), this.getFormSubmission(), this.getPendinglist(), this.getCompletedlist(), this.save()]);
	},

	*save() {
		yield takeEvery<ReduxAction>([types.SAVE_FORMSUBMISSION, types.INIT_NEW_FORMSUBMISSION], function*(action) {
			if (action.type === types.INIT_NEW_FORMSUBMISSION) {
				//save the initial form
				action.payload.submission = yield select<any>(x => x.formsubmission.pendingFormSubmissions[action.payload.newSubmissionId]);
			}

			let data = yield call(formsubmissionservice.Save, action.payload.submission);
			if (data.error) {
				console.error(data.error);
				yield put(actions.onError(data.error));
			}
		});
	},

	*delete() {
		yield takeEvery<ReduxAction>(types.DELETE_FORMSUBMISSION, function*({ payload }) {
			let data = yield call(formsubmissionservice.Delete, payload.formSubmissionId);
			if (data.error) {
				console.error(data.error);
				yield put(actions.onError(data.error));
			} else {
				yield put(actions.removeFormSubmission(payload.formSubmissionId));
			}
		});
	},

	*getPendinglist() {
		yield takeEvery<ReduxAction>(types.GET_PENDING_FORMSUBMISSION_LIST, function*({ payload }) {
			let data = yield call(formsubmissionservice.GetPending, payload.query);
			if (data.error) {
				console.error(data.error);
				yield put(actions.onError(data.error));
			} else {
				yield put(actions.onGetPendingFormSubmissions(data.data));
			}
		});
	},

	*getCompletedlist() {
		yield takeEvery<ReduxAction>(types.GET_COMPLETED_FORMSUBMISSION_LIST, function*({ payload }) {
			let data = yield call(formsubmissionservice.GetCompleted, payload.pagingParams);
			if (data.error) {
				console.error(data.error);
				yield put(actions.onError(data.error));
			} else {
				yield put(actions.onGetCompletedFormSubmissions(data.data));
			}
		});
	},

	*getFormSubmission() {
		yield takeEvery<ReduxAction>(types.GET_FORMSUBMISSION, function*({ payload }) {
			let data = yield call(formsubmissionservice.Get, payload.id);
			if (data.error) {
				console.error(data.error);
				yield put(actions.onError(data.error));
			} else {
				yield put(actions.onGetFormSubmission(data.data));
			}
		});
	},

	*submit() {
		yield takeEvery<ReduxAction>(types.SUBMIT_FORMSUBMISSION, function*({ payload }) {
			let copy: FormSubmissionDto = {...payload.submission};
			copy.formVersion = yield select<ReduxState>(x => x.forms.currentForm)
			yield put(actions.addToOutbox(copy));
			let data = yield call(formsubmissionservice.Submit, payload.submission);
			if (data.error) {
				console.error(data.error);
				yield put(actions.onError(data.error));
			} else {
				yield put(actions.removeFormSubmission(payload.submission.formSubmissionId));
				yield put(router.push(config.defaultRoute));
			}
		});
	}
};

////////
//actions
export const actions = {
	submit(submission: FormSubmissionDto) {
		return {
			type: types.SUBMIT_FORMSUBMISSION,
			payload: { submission }
		};
	},

	addToOutbox(formSubmission: FormSubmissionDto) {
		return {
			type: types.ADD_TO_OUTBOX,
			payload: { formSubmission }
		};
	},
	removeFromOutbox(formSubmissionId: string) {
		return {
			type: types.REMOVE_FROM_OUTBOX,
			payload: { formSubmissionId }
		};
	},

	save(submission: FormSubmissionDto) {
		return {
			type: types.SAVE_FORMSUBMISSION,
			payload: { submission }
		};
	},

	initNewSubmission(newSubmissionId: string, form: FormVersionDto, userName: string, clientId?: string) {
		return {
			type: types.INIT_NEW_FORMSUBMISSION,
			payload: { newSubmissionId, form, userName, clientId }
		};
	},

	updatePendingFormSubmissions(submission: FormSubmissionDto) {
		return {
			type: types.UPDATE_PENDING_FORM_SUBMISIONS,
			payload: { submission }
		};
	},

	removeFormSubmission(formSubmissionId: string) {
		return {
			type: types.REMOVE_FORMSUBMISSION,
			payload: { formSubmissionId }
		};
	},

	delete(formSubmissionId: string) {
		return {
			type: types.DELETE_FORMSUBMISSION,
			payload: { formSubmissionId }
		};
	},

	getFormSubmission(id: string) {
		return {
			type: types.GET_FORMSUBMISSION,
			payload: { id }
		};
	},

	onGetFormSubmission(formSubmission: FormSubmissionDto) {
		return {
			type: types.ON_GET_FORMSUBMISSION,
			payload: { formSubmission }
		};
	},

	getPendingFormSubmissions(query: string) {
		return {
			type: types.GET_PENDING_FORMSUBMISSION_LIST,
			payload: { query }
		};
	},

	onGetPendingFormSubmissions(formSubmissions: FormSubmissionDto[]) {
		return {
			type: types.ON_GET_PENDING_FORMSUBMISSION_LIST,
			payload: { formSubmissions }
		};
	},

	getCompletedFormSubmissions(pagingParams: PagingParameters) {
		return {
			type: types.GET_COMPLETED_FORMSUBMISSION_LIST,
			payload: { pagingParams }
		};
	},

	onGetCompletedFormSubmissions(formSubmissions: FormSubmissionDto[]) {
		return {
			type: types.ON_GET_COMPLETED_FORMSUBMISSION_LIST,
			payload: { formSubmissions }
		};
	},

	onSuccess() {
		return {
			type: types.ON_SUCCESS,
			payload: {}
		};
	},
	onError(error) {
		return {
			type: types.ON_ERROR,
			payload: error
		};
	}
};
