import config from "config/config";
import { OfflineHttpRecord } from "./typings";
import { string } from "utils";

interface EventResult extends Event {
	//pro typescript bug doesnt have result on eventtarget
	readonly target: EventTarget & { result: any } | null;
}

export class DatabaseTable {
	dbVersion = 5;
	db: IDBDatabase = null;
	tableName: string;
	indexes: string[];
	primaryKey: string;

	constructor(tableName: string, primaryKey: string, indexes?: string[]) {
		this.tableName = tableName;
		this.primaryKey = primaryKey;
		this.indexes = indexes || [];
	}

	initialize() {
		return new Promise((resolve, reject) => {
			let request = window.indexedDB.open(config.indexedDbName, this.dbVersion);

			request.onupgradeneeded = (event: any) => {
				console.warn("upgrade needed");
				this.db = event.target.result;
				if (!this.db.objectStoreNames.contains(this.tableName)) {
					console.warn("not contains table names, creating store for " + this.tableName);
					this.db.createObjectStore(this.tableName, { keyPath: this.primaryKey });
					const objectStore: IDBObjectStore = event.target.transaction.objectStore(this.tableName);
					for (let i = 0; i < this.indexes.length; i++) {
						const index = this.indexes[i];
						if (!objectStore.indexNames.contains(index)) {
							objectStore.createIndex(index, index, { unique: false });
						}
					}
				}
			};

			request.onsuccess = (event: EventResult) => {
				this.db = event.target.result;
				console.warn("init success");
				resolve();
			};

			request.onerror = (event: EventResult) => {
				console.warn("init failed");
				reject(event);
			};
		});
	}

	deleteForKey(table, key): Promise<any>{	
		let promise: Promise<any> = new Promise((resolve, reject) => {
			var transaction = this.db.transaction([table], "readwrite");

			transaction.oncomplete = function(event){
				//do nothign
			}
	
			transaction.onerror = function(event){
				reject("indexed db deletion failed with a transaction error. " + transaction.error);
			}

			var objectStore = transaction.objectStore(table);

			var objectStoreRequest = objectStore.delete(key);

			objectStoreRequest.onerror = function(event){
				reject("indexed db deletion failed with a store request error");
			}

			objectStoreRequest.onsuccess = function(event){
				resolve();
			}
		});
		return promise;
	}

	//given the table(object store) and index, get all keys for that index in the database
	getKeysForIndex(table, index) {
		return new Promise((resolve, reject) => {
			let result = [];
			console.warn(this);
			if (this.db.objectStoreNames.contains(this.tableName)) {
				var transaction = this.db.transaction(table, "readonly");
				transaction.oncomplete = function(event){
					//do nothign
				}
		
				transaction.onerror = function(event){
					reject("indexed db get keys for index failed with a transaction error. " + transaction.error);
				}

				var objectStore = transaction.objectStore(table);
				const _index = objectStore.index(index);
				_index.openKeyCursor().onsuccess = (event: EventResult) => {
					let cursor = event.target.result;
					if (cursor) {
						result.push(cursor.key);
						cursor.continue();
					} else {
						resolve(result);
					}
				};
				_index.openKeyCursor().onerror = (event: EventResult) => {
					reject(event);
				};
            } else {
                resolve(result);
            }
		});
	}

	//Table > the name of teh target object store
	//index > the name of the index
	//match > the value to match the index on
	//Returns: A promise that is resolved with the entry in the database
	//NOTE: If the index is not unique then there could be multiple value. This  only returns the first result=>.
	getValueForIndex(table, index, match) {
		return new Promise((resolve, reject) => {
			const objectStore = this.db.transaction([table], "readonly").objectStore(table);
			const _index = objectStore.index(index);
			_index.get(match).onsuccess = (event: EventResult) => {
				resolve(event.target.result);
			};
			_index.get(match).onerror = (event: EventResult) => {
				reject(event);
			};
		});
	}

	/*
        Given table name and entry id, returns a promise that resolves with the entry
        */
	get(table: string, id: string) {
		return new Promise((resolve, reject) => {
			let objectStore = this.db.transaction([table], "readonly").objectStore(table);
			let request = objectStore.get(id);
			request.onsuccess = (event: EventResult) => {
				resolve(event.target.result);
			};
			request.onerror = error => {
				reject(error);
			};
		});
	}

	/*
	  given table name, id of entry to update and data for new entry updates the entry and returns the updated entry.
	  optionally merge.
	  */
	update(table: string, newData: OfflineHttpRecord, merge: boolean) {
		return new Promise((resolve, reject) => {
			let current = null;
			let objectStore = this.db.transaction([table], "readwrite").objectStore(table);
			let request = objectStore.get(newData.requestId);
			request.onerror = error => {
				reject(error);
			};
			request.onsuccess = (event: EventResult) => {
				current = event.target.result;
				if (merge) {
					current = { ...current, ...newData };
				} else {
					current = newData;
				}
				let requestUpdate = objectStore.put(current);
				requestUpdate.onerror = error => {
					reject(error);
				};
				requestUpdate.onsuccess = (event: EventResult) => {
					resolve(current);
				};
			};
		});
	}

	insert(table: string, data: OfflineHttpRecord) {
		return new Promise((resolve, reject) => {
			let transaction = this.db.transaction([table], "readwrite");
			transaction.oncomplete = (event: EventResult) => {
				resolve(event);
			};
			transaction.onerror = (event: EventResult) => {
				reject(event);
			};
			let objectStore = transaction.objectStore(table);
			objectStore.add(data);
		});
	}

	//updates if alreayd exists otehrwise insterts
	upsert(table: string, newData: OfflineHttpRecord, merge: boolean) {
		return new Promise((resolve, reject) => {
			this.get(table, newData.requestId).then(existing => {
				if (existing) {
					return this.update(table, newData, merge).then(resolve, reject);
				} else {
					return this.insert(table, newData).then(resolve, reject);
				}
			});
		});
	}

	// return the total size of the table in bytes
	getTableSize(table) {
		return new Promise((resolve, reject) => {
			if (this.db != null) {
				let size = 0;

				let transaction = this.db
					.transaction([table])
					.objectStore(table)
					.openCursor();

				transaction.onsuccess = (event: EventResult) => {
					let cursor = event.target.result;
					if (cursor) {
						let storedObject = cursor.value;
						if (storedObject && storedObject.data != null && storedObject.data.byteLength != null) {
							size += storedObject.data.byteLength;
						}
						let json = JSON.stringify(storedObject);
						size += json.length;
						cursor.continue();
					} else {
						resolve(size);
					}
				};
				transaction.onerror = err => {
					reject("Database error: " + err);
				};
			} else {
				reject("Database not found");
			}
		});
	}
}

const instance = new DatabaseTable("httpRequest", "requestId", ["retryCount"]);

export const jobInstance = new DatabaseTable("job", "jobId", ["jobId"]);

export default instance;
