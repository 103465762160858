import * as React from "react";
import * as PropTypes from "prop-types";
import * as CSSModules from "react-css-modules";
import * as styles from "./styles.scss";
import { MergeStyles } from "utils/index";
import autoBind from "libs/react-autobind/index";
import * as theme from "config/theme/theme.scss";
import * as uuid from "uuid/v4";
import { CommonComponentProps } from "redi-types";

interface Props extends CommonComponentProps {
	checked: boolean;
	onChange: (val?: boolean) => void;
	onFocus?: (e?: React.FocusEvent<HTMLElement>) => void;
	onBlur?: (e?: React.FocusEvent<HTMLElement>) => void;
	disabled?: boolean;
	name?: string;
	size?: number;
	color?: string;
	boxColor?: string;
	id?: string;
	label?: string;
	tabIndex?: number;

	labelClass?: string;
	boxClass?: string;
	checkClass?: string;
}

/**

Use:

									<Checkbox
										checked={this.state.sendCopy}
										label="Send copy"
										onChange={(val) => this.setState({ sendCopy: val })}
									/>

*/
@MergeStyles(styles)
export default class CheckBox extends React.PureComponent<Props, State> {
	static defaultProps = {
		checked: false,
		onChange: null,
		disabled: false,
		name: "",
		size: 25,
		color: theme.primaryColor,
		boxColor: "#333",
		label: null,
		tabIndex: 0,

		labelClass: "",
		boxClass: "",
		checkClass: ""
	};
	static propTypes = {
		label: PropTypes.string,
		onChange: PropTypes.func.isRequired,
		checked: PropTypes.bool.isRequired,
		classes: PropTypes.object //style overrides
	};
	id: any;

	constructor(props) {
		super(props);
		autoBind(this);
		this.id = this.props.id || uuid();
	}

	onChange(e) {
		e.stopPropagation();
		this.props.onChange(!this.props.checked);
	}

	handleKeyDown(event) {
		// event.preventDefault();
		if (event.keyCode == 32 || event.key === "Enter") {
			//32 = space
			this.onChange(event);
		}
	}

	render() {
		const checked = !!this.props.checked;
		const disabled = this.props.disabled;
		return (
			<label
				className={this.props.className}
				styleName="_cbox_root"
				onClick={e => e.stopPropagation()}
				tabIndex={this.props.tabIndex}
				haslabel={(!!this.props.label).toString()}
				onKeyDown={this.handleKeyDown}
				checkbox="1"
			>
				<input
					ref={this.props.forwardedRef}
					name={this.props.name}
					checked={checked}
					id={this.id}
					onChange={this.onChange}
					type="checkbox"
					disabled={disabled}
					onFocus={this.props.onFocus}
					onBlur={this.props.onBlur}
				/>
				{!checked && (
					<svg
						styleName={`_cbox_mark ${this.props.boxClass}`}
						focusable="false"
						viewBox="0 0 24 24"
						aria-hidden="true"
						width={this.props.size}
						height={this.props.size}
						fill={disabled ? "#aaa" : this.props.boxColor}
						strokeWidth={0}
					>
						<path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z" />
					</svg>
				)}
				{checked && (
					<svg
						styleName={`_cbox_mark ${this.props.checkClass}`}
						focusable="false"
						viewBox="0 0 24 24"
						aria-hidden="true"
						width={this.props.size}
						height={this.props.size}
						fill={disabled ? "#aaa" : this.props.color}
					>
						<path d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
					</svg>
				)}
				{this.props.label && (
					<span htmlFor={this.id} styleName={this.props.labelClass} formlabel="1">
						{this.props.label}
					</span>
				)}
			</label>
		);
	}
}

interface State {}
