import * as React from "react";
import * as styles from "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as PropTypes from "prop-types";
import autoBind from "libs/react-autobind/index";
import { shouldUpdate, MergeStyles, string, number } from "utils/index";
import ModalImpl from "components/BaseModal/ModalImpl/ModalImpl";
import Button from "components/Button/Button";
import { CommonComponentProps } from "redi-types";

/**
 * Default zIndex: 99.
 * 
 * Use: 

 			<Modal isOpen={true} bodyText="noobs" title="Hey" onClose={this.closeModal} onConfirm={this.confirm} />
  OR
	
 			 <Modal
				isOpen={this.props.activityOpen}
				onClose={() => this.props.onCancel()}
				title="whatever">
				... elems
				</Modal>

OR

 			<Modal>
					<ModalHeader>
							...	 //..show these elems as the header
					</ModalHeader>
					...  //body content
				</Modal>
 */
@MergeStyles(styles)
export default class Modal extends React.Component<Props, {}> {
	static defaultProps = {
		isOpen: false,
		onClose: null,
		title: "",

		bodyText: "", //if no children els are passed, show text with an Ok, cancel Dialog instead
		onConfirm: e => null, //used if no children props are present,

		rootClass: "", //class to put on root div (resize modal etc)
		headerClass: "",
		bodyClass: "",
		classes: null //styleName overrides
	};
	static propTypes = {
		onClose: PropTypes.func.isRequired,
		isOpen: PropTypes.bool.isRequired,
		title: PropTypes.string,
		bodyText: PropTypes.string,
		onConfirm: PropTypes.func
	};

	constructor(props) {
		super(props);
		autoBind(this);
	}

	onClose() {
		this.props.onClose();
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["onClose", "onConfirm"]);
	}

	render() {
		const children = React.Children.toArray(this.props.children);
		const modalHeader = children.find((x: any) => x.type === ModalHeader);
		const modalBody = children.filter((x: any) => x.type !== ModalHeader);

		return (
			<ModalImpl
				isOpen={this.props.isOpen}
				styleName={`_modal_root ${this.props.rootClass}`}
				onClose={this.onClose}
				zIndex={this.props.zIndex}
				hashUrl={this.props.hashUrl}
			>
				<div styleName="_modal_modal" className={this.props.className}>
					<div styleName={`_modal_modal-header ${this.props.headerClass}`}>
						{modalHeader || <div>{this.props.title}</div>}
						<div styleName="_modal_icon" onClick={this.onClose}>
							<FontAwesomeIcon icon="times" />
						</div>
					</div>
					{(modalBody && modalBody.length > 0 && modalBody) || (
						<div styleName={`_modal_body ${this.props.bodyClass}`}>
							{this.props.bodyText}
							<div styleName="_modal_button-wrap">
								<Button onClick={this.props.onConfirm}>Ok</Button>
								<Button theme="secondary" onClick={this.onClose}>
									Cancel
								</Button>
							</div>
						</div>
					)}
				</div>
			</ModalImpl>
		);
	}
}

export class ModalHeader extends React.PureComponent {
	render() {
		return this.props.children;
	}
}

interface Props extends CommonComponentProps {
	onClose: () => void;
	isOpen: boolean;
	title?: string;
	bodyText?: string;
	onConfirm?: (e?: Event) => void;
	rootClass?: string;
	zIndex?: number;
	/**Add a hash url so back button closes modal instead of just routing back */
	hashUrl?: boolean;

	headerClass?: string;
	bodyClass?: string;
}
