import config from "config/config";
import { DateTime, http } from "../../utils/index";
import showToast from "components/Toast/Toast";
import { offlinerequest } from "services/offline";

const route = "security/";

export default class SecurityService {
	public static iframeToken = "";
	public static iframeExpiry = "";

	static login(username, password) {
		if (!username || !password) {
			return { error: "Username or password empty" };
		}

		let url = `${config.apiBaseUrl + route}oauth/token`;

		return http({
			url,
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
			data: `grant_type=password&password=${password}&username=${username}`
		})
			.then(data => data)
			.catch(error => {
				return Error(`Username or password incorrect`);
			});
	}

	static getDetails() {
		if (config.getDetailsFromJwt) {
			try {
				const token = SecurityService.iframeToken ? SecurityService.iframeToken : localStorage.getItem(config.tokenKey);
				return { data: parseJwt(token) };
			} catch (e) {
				return { error: e };
			}
		} else {
			let url = `${config.baseUrl + route}GetDetails`;
			return http({ url, method: "GET" })
				.then(data => data)
				.catch(error => {
					showToast("error", error.error);
					return error;
				});
		}
	}

	static ResetPassword(userName) {
		let url = `${config.baseUrl + route}ResetPassword`;

		return http({ url, method: "POST", data: `'${userName}'` })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static ChangePassword(data) {
		let url = `${config.baseUrl + route}ChangePassword`;

		return http({ url, method: "POST", data })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static createUser(user) {
		let url = `${config.baseUrl + route}Register`;

		const data = { ...user, phoneNumber: user.phone, confirmPassword: user.password };

		return http({ url, method: "POST", data })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static checkUserName(username) {
		let url = `${config.baseUrl + route}checkUserName`;

		return http({ url, method: "GET", username })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static async storeBearer(token) {
		localStorage.setItem(config.tokenKey, token.access_token);
		localStorage.setItem(config.tokenExpDateKey, DateTime.add("second", new Date(), token.expires_in).toISOString());
		offlinerequest.setToken(token.access_token);
	}
	static async removeBearer() {
		localStorage.removeItem(config.tokenKey);
		localStorage.removeItem(config.tokenExpDateKey);
		offlinerequest.clearToken();
	}

	/**
	 * Returns token if exists, else returns null
	 */
	static async checkTokenExists(): Promise<string | null>  {
		let token = SecurityService.iframeToken ? SecurityService.iframeToken : localStorage.getItem(config.tokenKey);
		let exp = SecurityService.iframeExpiry ? SecurityService.iframeExpiry : localStorage.getItem(config.tokenExpDateKey);

		if (!exp || new Date() > DateTime.parse(exp)) {
			//expired
			token = null;
			await SecurityService.removeBearer();
		}

		return token;
	}
}

function parseJwt(token: string) {
	var base64Url = token.split(".")[1];
	var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	return JSON.parse(window.atob(base64));
}
