import * as React from "react";
import * as PropTypes from "prop-types";
import * as styles from "./styles.scss";
import { CommonComponentProps } from "redi-types";
import { MergeStyles, shouldUpdate, RegisterTheme, autoBind, unwindFragments } from "redi-component-utils";
import { ElementAnchor } from "redi-element-anchor";
import { Writeable, ElementRecursive } from "redi-types";

export interface TypeaheadProps<T> extends CommonComponentProps {
	/** Additional filters for the typeahead */
	filters?: any[];
	/**Call the service with this. Called whenever the user types or focuses the input */
	getData: (val: string, ...args: any[]) => Promise<{ data?: T[]; error?: any }>;
	/**Called when the user selects a row */
	onChange: (s: T | string) => void;
	/**Show selected value in input field */
	value?: T;
	/**zindex of popup */
	zIndex?: number;
	/**extract string from object value */
	titleTransform?: (s: T) => React.ReactNode;
	disabled?: boolean;
	/** Popup width of input. default true */
	widthOfInput?: boolean;
	onFocus?: (e: React.FocusEvent) => void;
	onBlur?: (e: React.FocusEvent) => void;
	/** useShellPortal on `ElementAnchor` */
	useShellPortal?: boolean;
	/** Clear input text on selecting a value */
	clearOnChange?: boolean;
	/**
	 * Apply a transformation to a selected item before it is passed to `onChange`.
	 */
	dataTransform?: (item: T | string) => T;
	autoFocus?: boolean;
	/** Called when the user types in the input field */
	onQueryChange?: (newQuery: string) => void;
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;

	/** A functional child that is used to construct the menu items, based on the data and query */
	children: (data: T[], query: string) => ElementRecursive<TypeaheadMenuItemProps<T>>;

	placeHolder?: string;

	popupClass?: string;
	rowClass?: string;

	tabIndex?: number;
    /**
     * Allow value typed in to be selectable
     */
	allowBlankSelections?: (item: string) => T;
	
	/** spread any other props to the templates */
	[x: string]: any;
}

/**

Use:

							<Typeahead
								onChange={query => {
									return new Promise((resolve, reject) => {
										... get/filter data
										resolve({ data: data });
									});
								}}
								onChange={val => this.setState({ currentArea: val })}
								titleTransform={x => x && x.name}
								value={this.state.currentArea}
							>
								{data =>
									data.map(item => (
										<TypeaheadMenuItem key={item.id} value={item}>
											{item.name}
										</TypeaheadMenuItem>
									))
								}
							</Typeahead>

*/

@RegisterTheme("redi-common-inputs/typeahead")
@MergeStyles(styles)
export default class Typeahead<T> extends React.Component<TypeaheadProps<T>, State<T>> {
	static defaultProps: Partial<TypeaheadProps<any>> = {
		titleTransform: (x) => x,
		zIndex: 5,
		widthOfInput: true,
		useShellPortal: true,

		popupClass: "",
		rowClass: "",
		tabIndex: 0,
		filters: []
	};
	static propTypes = {
		classes: PropTypes.object //style overrides
	};
	popupRef: React.RefObject<HTMLDivElement>;
	inputRef: HTMLInputElement;
	_unmounted: boolean;
	rootRef: React.RefObject<HTMLDivElement>;
	ignoreNextBlur: boolean;
	dirty: boolean;
	menuItemCount: number;
	popupData: T[];

	constructor(props: TypeaheadProps<T>) {
		super(props);
		autoBind(this);
		this.state = {
			currentData: [],
			focusIndex: 0,
			open: false
		};

		this.popupRef = React.createRef<HTMLDivElement>();
		this.rootRef = React.createRef<HTMLDivElement>();
	}

	componentDidMount() {
		if (this.props.autoFocus) {
			setTimeout(() => {
				if (this.inputRef) {
					this.inputRef.focus();
				}
			});
		}
	}

	onInputRef(ref: HTMLInputElement) {
		this.inputRef = ref;
		if (this.inputRef) {
			if (this.props.value) {
				const val = this.props.titleTransform(this.props.value);
				if (val == null) {
					this.inputRef.value = "";
				} else {
					this.inputRef.value = typeof val === "string" ? val : val.toString();
				}
			}
		}
		if (this.props.forwardedRef) {
			if (typeof this.props.forwardedRef === "function") {
				this.props.forwardedRef(ref);
			} else if ("current" in this.props.forwardedRef) {
				this.props.forwardedRef.current = ref;
			} else {
				throw new Error("Incorrect ref argument passed to Select");
			}
		}
	}

	onChange(e?: React.ChangeEvent<HTMLInputElement> | React.MouseEvent) {
        this.props.getData(this.inputRef.value, ...this.props.filters).then((data) => {
			if (data.data && this.inputRef) {
				let arr: T[] = data.data.slice();
				if (this.props.allowBlankSelections && this.inputRef.value) {
					let val = this.props.allowBlankSelections(this.inputRef.value);
					arr.unshift(val);
				}
				this.open(arr);
			}
		});

		if (this.props.allowBlankSelections && this.inputRef.value) {
			let val = this.props.allowBlankSelections(this.inputRef.value);
			let arr = this.state.currentData.length ? this.state.currentData.slice() : new Array(1);
			arr[0] = val;
			this.open(arr);  
		}
	}

	clickRow(row: T | string) {
		this.close();

		if (this.props.dataTransform) {
			// transform the data before passing upwards
			row = this.props.dataTransform(row);
		}

		this.props.onChange(row);

		if (this.props.clearOnChange) {
			this.inputRef.value = "";
		} else {
			const val = this.props.titleTransform(row as T);
			if (val == null) {
				this.inputRef.value = "";
			} else {
				this.inputRef.value = typeof val === "string" ? val : val.toString();
			}
		}
	}

	handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
		this.props.onKeyDown && this.props.onKeyDown(event);
		if (this.menuItemCount) {
			if (event.key === "Enter" && this.state.open) {
				//32 = space
				this.clickRow(this.popupData[this.state.focusIndex]);
			} else {
				switch (event.key) {
					case "ArrowDown":
						{
							let newfocus = this.state.focusIndex + 1;
							if (newfocus >= this.menuItemCount) {
								newfocus = 0;
							}
							this.setState({ focusIndex: newfocus });
						}
						break;
					case "ArrowUp":
						{
							let newfocus = this.state.focusIndex - 1;
							if (newfocus < 0) {
								newfocus = this.menuItemCount - 1;
							}
							this.setState({ focusIndex: newfocus });
						}
						break;
					case "Enter":
						if (!this.state.open) {
							this.setState({ open: true });
						}
						break;

					case "Tab":
						if (this.state.open) {
							this.clickRow(this.popupData[this.state.focusIndex]);
						}
						break;
				}
			}
		}
	}

	open(data: any[]) {
		this.setState({ currentData: data, open: true });
	}

	close() {
		this.dirty = false;
		this.setState({ focusIndex: 0, open: false });
	}

	renderMenuItems() {
		if (this.state.open) {
			const kids = unwindFragments(
				React.Children.toArray(this.props.children(this.state.currentData, this.inputRef.value)) as React.ReactElement[]
			);
			this.menuItemCount = 0;
			this.popupData = [];

			return React.Children.map(kids, (el: React.ReactElement<InternalTypeaheadMenuItemProps<T>>, index) => {
				if (el.type === TypeaheadMenuItem) {
					this.menuItemCount++;
					this.popupData[index] = el.props.value;

					const existingMouseDown = el.props.onMouseDown;
					const existingMouseEnter = el.props.onMouseEnter;
					return React.cloneElement(el, {
						className: [
							this.props.styles["_popup-row"],
							el.props.className,
							...this.props.rowClass.split(" ").map((x) => this.props.styles[x])
						].join(" "),
						onMouseDown: (e: React.MouseEvent<HTMLDivElement>) => {
							this.clickRow(el.props.value);
							existingMouseDown && existingMouseDown(e);
						},
						onMouseEnter: (e: React.MouseEvent<HTMLDivElement>) => {
							this.setState({ focusIndex: index });
							existingMouseEnter && existingMouseEnter(e);
						},
						styles: this.props.styles,
						query: this.inputRef.value,
						isFocussed: index === this.state.focusIndex
					});
				} else {
					return el;
				}
			});
		}
	}

	render() {
		let open = this.state.open && !this.props.disabled && this.popupRef.current && !!this.inputRef;
		let menuItems: React.ReactElement[];
		if (open) {
			menuItems = this.renderMenuItems();
		}

		open = menuItems?.length > 0;

		return (
			<div
				className={this.props.className}
				ref={this.rootRef}
				styleName="_root"
				disabled={this.props.disabled ? "1" : undefined}
				typeahead="1"
				isopen={open ? "true" : "false"}
				tabIndex={this.props.tabIndex}
			>
				<input
					ref={this.onInputRef}
					onChange={(e) => {
						this.dirty = true;
						this.onChange(e);
						this.props.onQueryChange && this.props.onQueryChange(e.target.value);
					}}
					onFocus={(e) => {
						this.dirty = false;
						this.props.onFocus && this.props.onFocus(e);
						this.onChange(e);
					}}
					onBlur={(e) => {
						if (!this.ignoreNextBlur && !this._unmounted) {
							this.props.onBlur && this.props.onBlur(e);
						}
						setTimeout(() => {
							if (!this.ignoreNextBlur && !this._unmounted) {
								if (this.dirty) {
									// input was changed but no value selected. set to null
									this.inputRef.value = "";
									this.props.onChange(null);
								}
								if (open) {
									this.close();
								}
							}
							this.ignoreNextBlur = false;
						});
					}}
					onKeyDown={this.handleKeyDown}
					disabled={this.props.disabled}
					styleName="_input"
					placeholder={this.props.placeHolder}
					tabIndex={this.props.tabIndex}
				/>
				<ElementAnchor
					show={open}
					position="bottom-left"
					zIndex={this.props.zIndex}
					anchor={this.rootRef}
					useShellPortal={this.props.useShellPortal}
					widthOfAnchor={this.props.widthOfInput}
				>
					<div ref={this.popupRef} open={open} styleName={`_popup-root ${this.props.popupClass}`}>
						{menuItems}
					</div>
				</ElementAnchor>
			</div>
		);
	}

	shouldComponentUpdate(nextProps: TypeaheadProps<T>, nextState: State<T>) {
		return shouldUpdate(this, nextProps, nextState, ["getData", "onChange", "titleTransform"]);
	}

	componentWillUnmount() {
		this._unmounted = true;
	}

	componentDidUpdate(prevProps: TypeaheadProps<T>) {
		if (prevProps.value !== this.props.value && this.props.value) {
			const val = this.props.titleTransform(this.props.value);
			if (val == null) {
				this.inputRef.value = "";
			} else {
				this.inputRef.value = typeof val === "string" ? val : val.toString();
			}
		}
	}
}

interface State<T> {
	currentData: T[];
	focusIndex: number;
	open: boolean;
}

export interface TypeaheadMenuItemProps<T> extends React.HTMLAttributes<HTMLDivElement> {
	key: React.ReactText;
	value: T;
	forwardedRef?: Writeable<React.RefObject<any>> | ((ref: any) => void);
}

interface InternalTypeaheadMenuItemProps<T> extends TypeaheadMenuItemProps<T> {
	query: string;
}

export class TypeaheadMenuItem<T> extends React.PureComponent<TypeaheadMenuItemProps<T>> {
	/**
	 * A function that extends the given template to underline text that matches the query.
	 * add attribute 'nounderline' to any element to not underline text within it.
	 */
	private underlineChildren() {
		const mergedStyles = this.props.styles;

		const arr = React.Children.toArray(this.props.children) as React.ReactElement<any>[];
		const query = this.props.query.toLowerCase();
		let id = 0;

		//iterate thru all elements returned and look for children that is plain text.
		//find any charactors that match the query and replace them with a span that has an underline on it
		//add attribute 'nounderline' to any element to not underline text within it
		const iterate = (elems: React.ReactElement<any>[] | string[]) => {
			for (let index = 0; index < elems.length; index++) {
				const el = elems[index];
				if (typeof el === "string") {
					let result = [];
					let lowerd = el.toLowerCase();
					let copy = el;
					for (let index = lowerd.indexOf(query); index > -1; index = lowerd.indexOf(query)) {
						if (index > 0) {
							result.push(copy.slice(0, index));
							copy = copy.substr(index);
							lowerd = lowerd.substr(index);
						}
						const matchedString = copy.slice(0, query.length);
						copy = copy.substr(query.length);
						lowerd = lowerd.substr(query.length);
						result.push(
							React.createElement(
								"span",
								{
									className: mergedStyles["typeahead-underlined-text"],
									key: id++
								},
								matchedString
							)
						);
					}
					if (copy.length > 0) {
						result.push(copy);
					}
					elems[index] = React.createElement("span", { key: index }, result);
				} else if (el.props && el.props.children && !("nounderline" in el.props)) {
					const subarr = React.Children.toArray(el.props.children) as React.ReactElement<any>[];
					elems[index] = React.cloneElement(el, { children: iterate(subarr) });
				}
			}
			return elems;
		};

		return this.props.query.length > 0 ? iterate(arr) : this.props.children;
	}

	render() {
		const { value, isFocussed, forwardedRef, ...props } = this.props;
		return (
			<div {...props} ref={forwardedRef} isfocussed={isFocussed ? "true" : "false"}>
				{this.underlineChildren()}
			</div>
		);
	}
}
