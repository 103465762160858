import React, { PureComponent, Component } from "react";
import PropTypes from "prop-types";
import CSSModules from "react-css-modules";
import styles from "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { shouldUpdate, MergeStyles, dom } from "utils/index";
import autoBind from "../../libs/react-autobind/index";
import Checkbox from "components/Checkbox/checkbox";
import ShellPortal from "components/ShellPortal/ShellPortal";

/**
 * Action for <MultiSelect />
 */
export class MltiSelectAction {
	constructor(item, checked = false) {
		this.item = item;
		this.checked = checked;
	}
}

/**

Use:
		<MultiSelect
			styleName="select-account"
			value={this.props.currentAccount}
			titleTransform={(value, actions) => value.name}
			onChange={value => this.props.setCurrentAccount(value.accountId)}
			actions={this.props.accounts.map(
									x =>
										new MltiSelectAction(
											x,
											checked
										)
								)}
			titleClass="select-box-title"
			dropdownIcon={KeyboardArrowDown}
			classes={styles}
			disabled={boolean}
		/>

*/
@MergeStyles(styles)
export default class MultiSelect extends Component {
	static defaultProps = {
		actions: [], //Array<MltiSelectAction>
		titleTransform: (item, actions) => item, //select title or name from value
		zIndex: 5,
		showIcon: true,
		titleComponent: null, //component
		widthOfRoot: false, //make popup width of root elem
		onChange: null,
		onClose: null,
		showAllSelection: true, //add an all checkbox to the list
		absolutePosition: false, //use getAbsoluteBoundingRect to get select pos
		disabled: false,
		titleClass: "",
		menuWrapClass: "",
		menuClass: "",
		rowClass: ""
	};
	static propTypes = {
		actions: PropTypes.arrayOf(PropTypes.instanceOf(MltiSelectAction)).isRequired,
		zIndex: PropTypes.number,
		titleTransform: PropTypes.func,
		showAllSelection: PropTypes.bool,
		onChange: PropTypes.func.isRequired,
		onClose: PropTypes.func,
		classes: PropTypes.object //style overrides
	};

	constructor(props) {
		super(props);
		autoBind(this);
		this.state = {
			open: false,
			pos: null
		};

		this.TitleComponent =
			this.props.titleComponent &&
			CSSModules(
				this.props.titleComponent,
				{ ...styles, ...this.props.classes },
				{
					allowMultiple: true
				}
			);

		this.popup = React.createRef();
		this.rowRefs = {};
		this.currentFocus = 0;

		this.allItem = { title: "All", _isAll: true, checked: props.actions.every(x => x.checked) }; //create a menu item for all selected
	}

	onChange(val, index) {
		this.changed = true;

		if (!val) {
			this.allItem.checked = false;
		} else if (this.props.actions.every((x, i) => x.checked || i === index)) {
			//all items should be checked except the current item, which will be checked, then also check all
			this.allItem.checked = true;
		}

		const selected = this.props.actions[index];
		this.props.onChange(selected.item);
	}

	checkAll(val) {
		this.changed = true;
		this.allItem.checked = val;
		const items = this.props.actions.filter(x => x.checked !== val);
		for (let index = 0; index < items.length; index++) {
			const item = items[index];
			item.checked = val;
		}
		this.props.onChange(items.map(x => x.item));
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["titleComponent", "onChange", "onClose"]);
	}

	toggle() {
		if (this.state.open) {
			this.close(true);
		} else {
			this.open();
		}
	}

	open(callback) {
		let style = {};
		if (this.popup.current && this.root) {
			const pos = this.props.absolutePosition ? dom.getAbsoluteBoundingRect(this.root) : this.root.getBoundingClientRect();

			const xLimit = pos.left + this.popup.current.clientWidth + 10;
			const yLimit = pos.bottom + this.popup.current.clientHeight + 10;
			style.top = pos.bottom;
			style.left = pos.left;

			if (yLimit > window.innerHeight) {
				style.top -= yLimit - window.innerHeight;
			}
			if (xLimit > window.innerWidth) {
				style.left -= xLimit - window.innerWidth;
			}
		}

		this.setState({ open: true, pos: style }, callback);
	}

	rootRef(ref) {
		this.root = ref;
		if (ref) {
			this.root.addEventListener("focus", this.onFocus);
			this.root.addEventListener("blur", this.onBlur);
		}
	}

	onFocus(e) {
		this.ignoreNextBlur = true;
		if (!this.ignoreNextFocus && !this.state.open) {
			// Opening the menu is going to blur the. It will be focused back when closed.
			this.open(this.focusFirstEl);
		}
		this.ignoreNextFocus = false;
	}

	close(focus) {
		this.setState({ open: false }, () => {
			this.props.onClose && this.props.onClose(this.changed);
			this.changed = false;
		});
		if (focus) {
			this.ignoreNextFocus = true;
			//reset form tab focus back to root
			this.root.focus();
		}
	}

	onBlur(e) {
		if (!this.ignoreNextBlur) {
			this.close(false);
		}
		this.ignoreNextBlur = false;
	}

	focusFirstEl() {
		this.currentFocus = 0;
		this.focusItem();
	}

	focusItem() {
		if (this.state.open && this.props.actions.length) {
			if (this.rowRefs[this.currentFocus]) {
				this.rowRefs[this.currentFocus].focus();
			}
		}
	}

	handleKeyDown(event) {
		if (this.state.open) {
			event.preventDefault();
			this.ignoreNextBlur = true;
			if (event.keyCode == 32 || event.key === "Enter") {
				//32 = space
				if (this.currentFocus === 0) {
					this.checkAll(!this.allItem.checked);
				} else {
					const index = this.props.showAllSelection ? this.currentFocus - 1 : this.currentFocus;
					this.onChange(event, index);
				}
			} else {
				switch (event.key) {
					case "Tab":
						this.close(true);
						this.ignoreNextFocus = true;
						this.popup.current && this.popup.current.focus();
						break;
					case "ArrowDown":
						this.currentFocus++;
						if (this.currentFocus <= this.props.actions.length) {
							this.focusItem();
						} else {
							this.currentFocus = 0;
							this.close(true);
						}
						break;
					case "ArrowUp":
						this.currentFocus--;
						if (this.currentFocus >= -1) {
							this.focusItem();
						} else {
							this.currentFocus = 0;
							this.close(true);
						}
						break;
				}
			}
		}
	}

	render() {
		let style = this.state.open ? this.state.pos : {};
		let menustyle = {};

		if (this.props.widthOfRoot && this.root) {
			menustyle.width = this.root.clientWidth;
			style.width = this.root.clientWidth;
		}

		let actions = this.props.actions.slice();

		if (this.props.showAllSelection) {
			actions.unshift(this.allItem);
		}

		return (
			<React.Fragment>
				<div styleName="close-area" onClick={this.close} open={this.state.open} style={{ zIndex: this.props.zIndex }} />

				<div className={this.props.className} styleName="_root" ref={this.rootRef} onKeyDown={this.handleKeyDown} tabIndex={0} multi-select="1">
					<div onClick={this.toggle} styleName={`_title ${this.props.titleClass}`}>
						{this.TitleComponent ? (
							<this.TitleComponent {...this.state} {...this.props} actions={actions} onClick={this.toggle} />
						) : (
							<FontAwesomeIcon icon="ellipsis-v" onClick={this.toggle} />
						)}
					</div>
					<ShellPortal>
						<div
							styleName={`_menu-wrap ${this.props.menuWrapClass}`}
							open={this.state.open}
							style={{ zIndex: this.props.zIndex ? this.props.zIndex + 1 : undefined, ...style }}
							ref={this.popup}
						>
							<div styleName={`_menu ${this.props.menuClass}`}>
								{actions.map((x, i) => {
									//all selection isnt part of props.actions so decrement index by 1
									const thisindex = this.props.showAllSelection ? i - 1 : i;
									return (
										<div
											styleName={`_row ${this.props.rowClass}`}
											onFocus={e => {
												this.currentFocus = i;
											}}
											tabIndex={-1}
											ref={ref => (this.rowRefs[i] = ref)}
											key={i}
											// onClick={() => {
											// 	if (x._isAll) {
											// 		this.checkAll(!x.checked);
											// 	} else {
											// 		this.onChange(!x.checked, thisindex);
											// 	}
											// }}
										>
											{x._isAll ? (
												<Checkbox
													onChange={val => this.checkAll(val)}
													checked={x.checked}
													label={x.title}
													tabIndex={-1}
													classes={{ ...styles, ...this.props.classes }}
													disabled={this.props.disabled}
												/>
											) : (
												<Checkbox
													onChange={val => this.onChange(val, thisindex)}
													checked={x.checked}
													tabIndex={-1}
													label={this.props.titleTransform(x.item, actions)}
													classes={{ ...styles, ...this.props.classes }}
													disabled={this.props.disabled}
												/>
											)}
										</div>
									);
								})}
								{!actions.length && (
									<div styleName={`_row ${this.props.rowClass}`} onClick={this.toggle}>
										<i styleName="none">None</i>
									</div>
								)}
							</div>
						</div>
					</ShellPortal>
				</div>
			</React.Fragment>
		);
	}

	componentWillUnmount() {
		if (this.popup.current) {
			this.popup.current.removeEventListener("focus", this.onFocus);
			this.popup.current.removeEventListener("blur", this.onBlur);
		}
	}
}
