import React, { PureComponent } from "react";
import { Field, reduxForm, formValueSelector, reset } from "redux-form";
import styles from "./styles.scss";
import CSSModules from "react-css-modules";
import PropTypes from "prop-types";
import RadioButton from "components/Radio/radio";
import Select from "components/Select/select";
import { DateTime, TimeRange, array, shouldUpdate, MergeStyles } from "utils/index";
import TimeSliderImpl from "components/TimeSlider/timeslider";
import Checkbox from "components/Checkbox/checkbox";
import { Calendar, DateRangePicker } from "libs/react-date-range/index";
import Button from "components/Button/Button";
import MultiSelect from "components/MultiSelect/multiselect";
import Radio from "components/Radio/radio";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ShellPortal from "components/ShellPortal/ShellPortal";

class FormCalander extends React.Component {
	static defaultProps = {
		inputClass: "",
		actions: [],
		titleTransform: undefined,
		showClearBtn: false,
		format: "dd MMM yyyy",
		widthOfRoot: true, //for collapsable mode only
		collapsable: true
	};
	constructor(props) {
		super(props);
		this.state = {
			open: false
		};

		this.popup = React.createRef();
		this.root = React.createRef();
		this.calenderRef = React.createRef();
	}
	render() {
		let {
			input,
			label,
			required,
			placeholder,
			type,
			actions,
			inputClass,
			showClearBtn,
			maxDate,
			minDate,
			format,
			collapsable,
			disabled,
			meta: { touched, error },
			classes,
			...other
		} = this.props;

		if (input.value === "true") {
			//use default value
			input.onChange( new Date());
			//return nothing until rerender with default val
			return null;
		}

		input.value = input.value || null;

		//Disable date selected except for selected value
		if (disabled) {
			maxDate = input.value || new Date();
			minDate = input.value || new Date();
		}

		let style = {};
		let menustyle = {};

		if (collapsable) {
			//calculate floating divs window limits and position accordingly
			if (this.popup.current && this.state.open && this.root.current) {
				const pos = this.root.current.getBoundingClientRect();

				const xLimit = pos.left + this.popup.current.clientWidth;
				const yLimit = pos.bottom + this.popup.current.clientHeight;
				style.top = pos.bottom;
				style.left = pos.left;

				if (yLimit > window.innerHeight) {
					style.top -= yLimit - window.innerHeight;
				}
				if (xLimit > window.innerWidth) {
					style.left -= xLimit - window.innerWidth;
				}
			}
			if (this.props.widthOfRoot && this.root.current) {
				menustyle.width = this.root.current.clientWidth;
				style.width = this.root.current.clientWidth;
			}
		}

		const calandar = (
			<form name="calander" styleName="__flex calander-root">
				<Calendar
					onChange={val => {
						this.setState({ open: false });
						input.onChange(val);
						this.ignoreNextFocus = true;
						//reset form tab focus back to root
						this.root.current && this.root.current.focus();
					}}
					buttonRef={this.calenderRef}
					noTab={!this.state.open}
					date={input.value}
					maxDate={maxDate}
					minDate={minDate}
					styleName="__calacnder-class"
					disabled={disabled}
				/>
				{showClearBtn && (
					<Button theme="secondary" onClick={e => input.onChange(null)}>
						Clear
					</Button>
				)}
			</form>
		);

		return (
			<div className={other.className} styleName="input-group">
				<div formlabel="1">
					{label}
					{required && <span> *</span>}
				</div>
				{collapsable ? (
					<React.Fragment>
						<div
							styleName="close-area"
							onClick={() => this.setState({ open: false })}
							open={this.state.open}
							style={{ zIndex: this.props.zIndex }}
						/>
						<div
							styleName="_calandar-wrap"
							open={this.state.open}
							ref={this.root}
							tabIndex={0}
							onFocus={() => {
								if (!this.ignoreNextFocus) {
									this.setState({ open: true }, () => {
										this.calenderRef.current.focus();
									});
								}
								this.ignoreNextFocus = false;
							}}
						>
							<div onClick={e => this.setState({ open: !this.state.open })} styleName="_form_input calandar-header">
								<div styleName="__flex">{input.value && DateTime.format(input.value, format)}</div>
								{this.state.open ? <FontAwesomeIcon icon="chevron-up" /> : <FontAwesomeIcon icon="chevron-down" />}
							</div>
							<ShellPortal>
								<div
									styleName="_calandar-shrink"
									open={this.state.open}
									tabIndex={0}
									onFocus={e => {
										e.stopPropagation();
									}}
									ref={this.popup}
									style={{ zIndex: this.props.zIndex ? this.props.zIndex + 1 : undefined, ...style }}
								>
									{calandar}
								</div>
							</ShellPortal>
						</div>
					</React.Fragment>
				) : (
					calandar
				)}
				{touched && error && <div styleName="_input-error">{error}</div>}
			</div>
		);
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["onBlur", "onChange"]);
	}
}

export const RenderCalender = MergeStyles(styles, { forwardRef: false })(FormCalander);
