import { http, DateTime, string } from "../utils/index";
import config from "config/config";
import showToast from "components/Toast/Toast";
import { JobSummaryDto } from "redi-types";
import { HttpResult } from "utils/http";

const route = "Job/";

export default class jobservice {
	static GetJob(jobId: string) {
		let url = `${config.baseUrl + route}GetJob`;

		return http({ url, method: "GET", jobId })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static DeleteJob(jobId: string) {
		let url = `${config.baseUrl + route}Delete`;

		return http({ url, method: "POST", data: { jobId } })
			.then(data => {
				showToast("success", "Job Deleted");
				return data;
			})
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static UpdateStatus(jobId: string, workflowStatusCode: string){
		let url = `${config.baseUrl + route}UpdateStatus`;

		return http({ url, method: "GET", jobId, workflowStatusCode })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static UpdateCompany(jobId: string, companyId: string){
		let url = `${config.baseUrl + route}UpdateCompany`;

		return http({ url, method: "GET", jobId, companyId })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static UpdateTechnician(jobId: string, technicianId: string){
		let url = `${config.baseUrl + route}UpdateTechnician`;
		return http({ url, method: "GET", jobId, technicianId })
			.then(data => data)
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}
}
