import { DateTime, string, number } from "utils";
import showToast, { Toasts } from "components/Toast/Toast";
import { getState } from "boot/configureStore";
import { FullJobDto } from "redi-types";

/** this object holds all the functions available to call within FunctionTransformations */
const functionDefinitions = {
	/** expose all the DateTime functions */
	DateTime: DateTime,
	/** show a toast */
	notify: function(type: Toasts, message: any) {
		if (message && typeof message !== "string" && "toString" in message) {
			message = message.toString();
		}
		showToast(type, message, { forceShow: true });
	},
	hasRole: function(...allowedRoles: Array<string>): boolean{
		let currentUserRoles = getState(x => x.login.currentDetails.RoleClaims);
		for (const iterator of currentUserRoles) {
			if(allowedRoles.some(s => s.toLowerCase() === iterator.toLowerCase())){
				return true;
			}
		}
		return false;
	},
	formValid: function(){
		
	},
	jobInStatus: function(...allowedStatuses: Array<string>): boolean {
		let inStatus: boolean = false;
		let job: FullJobDto = getState(x => x.job.job);
		if(job && job.workflowStatusCode && allowedStatuses && allowedStatuses.length){
			for(var ii = 0; ii < allowedStatuses.length; ii++){
				let wkStatus: string = allowedStatuses[ii].toLowerCase();
				if(job.workflowStatusCode.toLowerCase() === wkStatus){
					inStatus = true;
					break;
				}
			}
		}
		return inStatus;
	}
};

export class TransformationFunctions {
	transformationRaw: string;
	actions: Action[];
	getValueForId: (id: string) => string;
	rowIndex: number;
	/**
	 * @param transformation string to parse
	 */
	constructor(transformation: string, rowIndex: number, getValueForId: (id: string) => string) {
		this.transformationRaw = transformation;
		this.getValueForId = getValueForId;
		this.rowIndex = rowIndex;

		//strip off 'f( )' charactors.
		let func = transformation.substr(2);
		func = func.substr(0, func.length - 1);

		// split the func into each operation
		this.actions = new Splitter(func).split();
	}

	/** call this after updating the  getValueForId func*/
	regenerate(rowIndex: number, getValueForId: (id: string) => string) {
		this.getValueForId = getValueForId;
		this.rowIndex = rowIndex;
	}

	private doEvaluate(actions: Action[], inputValue: any) {
		let currentValue = inputValue;
		let operateVal = null;
		let operate = false;
		for (let index = 0; index < actions.length; index++) {
			const prev = actions[index - 1];
			const action = actions[index];
			const next = actions[index + 1];

			if (action.isValue && action.subActions) {
				action.value = this.doEvaluate(action.subActions[action.subActionKey.split("_")[1]], inputValue);
			}

			if (action.isFunction) {
				const func: Function = string.path(functionDefinitions, action.funcToCall);
				const pathToObj = action.funcToCall.replace(/\.?[^\.]+$/, ""); //strip off last '.member' from path
				const thisarg: any = pathToObj ? string.path(functionDefinitions, pathToObj) : {};
				// call the specified func with this object being the parent object in cases like "DateTime fnctions" or an empty object for anything else
				// map the args to get current field values or pass the given strings
				currentValue = func.apply(
					thisarg,
					action.functionParams.map(arg => {
						if (arg.toLowerCase() === "{value}") {
							return inputValue;
						} else if (/^{.+?}$/.test(arg)) {
							//is a field id. get value for that instead;
							const id = arg.replace(/^{|}$/g, "");
							return this.getValueForId(id);
						} else if (arg.startsWith("$$subaction_")) {
							return this.doEvaluate(action.subActions[arg.split("_")[1]], inputValue);
						} else {
							return arg;
						}
					})
				);
			} else if (action.isOperator) {
				//set this to true then do the operation on the next iteration
				operate = true;
				operateVal = currentValue;
				continue;
			} else if (next && next.isOperator) {
				if (currentValue === null || currentValue === undefined) {
					currentValue = action.value;
				}
			} else if (!operate) {
				showToast("error", `Unknown Function Transformation '${action.value}' in '${this.transformationRaw}'. Cannot evaluate`, {
					forceShow: true
				});
				return null;
			}

			if (operate) {
				operate = false;
				let right = action.isValue ? action.value : currentValue;
				if (typeof right === "string" && /^{.+?}$/.test(right)) {
					const id = right.replace(/^{|}$/g, "");
					right = this.getValueForId(id);
				}
				if (typeof operateVal === "string" && /^{.+?}$/.test(operateVal)) {
					const id = operateVal.replace(/^{|}$/g, "");
					operateVal = this.getValueForId(id);
				}
				switch (prev.value as Operators) {
					case "+":
						currentValue = operateVal + right;
						break;
					case "-":
						currentValue = operateVal - right;
						break;
					case "/":
						currentValue = operateVal / right;
						break;
					case "*":
						currentValue = operateVal * right;
						break;
					case "==":
						currentValue = operateVal == right;
						break;
					case "||":
						currentValue = operateVal || right;
						break;
					case "&&":
						currentValue = operateVal && right;
						break;
				}
				operateVal = null;
			}
		}

		return currentValue;
	}

	evaluate(inputValue: any) {
		return this.doEvaluate(this.actions, inputValue);
	}
}

class Splitter {
	private str: string;
	private inParentheses: number;
	private inString: boolean;
	private currentIndex: number;

	constructor(str: string) {
		//reg 1 removes all whitespace from around parentheses
		this.str = str.trim().replace(/\s*?(\)|\()\s*?/g, "$1");
		// add spaces around any operators that dont have space
		this.str = this.str.replace(/([^\s]?)(\+|\/|\*|\-|==|\&\&|\|\|)([^\s]?)/g, "$1 $2 $3");
		while (this.str.startsWith("(") && this.str.endsWith(")")) {
			this.str = this.str.slice(0, this.str.length - 1).substr(1);
		}
		this.currentIndex = 0;
		this.inParentheses = 0;
	}

	reset() {
		this.currentIndex = 0;
	}

	/** split string into all actions */
	split() {
		let rtn: Action[] = [];
		let val = "";

		for (; this.currentIndex <= this.str.length; this.currentIndex++) {
			const char = this.str[this.currentIndex];
			if ((!this.inString && this.inParentheses === 0 && char === " " && val) || this.currentIndex === this.str.length) {
				val = val.trim();
				if (!val) {
					continue;
				}

				//only split on whitespace if not inside parentheses
				let split: Action = {
					value: val,
					isFunction: val.startsWith("#"),
					isOperator: /^(\+|\/|\*|\-|==|\&\&|\|\|)$/.test(val),
					isValue: false
				};

				split.isValue = !split.isFunction && !split.isOperator;

				if (split.isFunction) {
					//												skip first ( . it belongs to this current function
					const functions = string.matchCharGroupings(val.substr(val.indexOf("(") + 1), "(", ")", false);
					//do this replace so if a dot appears inside a function transformation, the split wont see it
					functions.forEach((x, i) => (val = val.replace(x, "$$$$isfunction_" + i))); // $$ equals $

					//calculate these once so we dont have to every time `evaluate()` is called
					split.functionParams = val
						//match all text inside function call ( ... )
						.match(/\(\s*?(.*?)\)/)[1]
						.split(",")
						.map(x => {
							//remove whitespace and quotes from ends of strings
							const trimmed = x.replace(/^("|\s)*|("|\s)*$/g, "");
							if (trimmed.includes("$$isfunction")) {
								const index = parseInt(trimmed.match(/\$\$isfunction_(\d+)/)[1], 10);
								const sub = trimmed.replace(/\$\$isfunction_\d+/, functions[index]);
								//is a sub function
								split.subActions = split.subActions || [];
								split.subActions.push(new Splitter(sub).split());
								split.subActionKey = `$$subaction_${split.subActions.length - 1}`;
								return split.subActionKey;
							} else {
								return trimmed;
							}
						});

					split.funcToCall = val.substr(1).replace(/\(.*?\)/, "");
				} else if (split.isValue) {
					split.value = split.value.trim();
					if (split.value.startsWith('"')) {
						//is string
						while (split.value.startsWith('"') && split.value.endsWith('"')) {
							//remove quotes from ends of strings
							split.value = split.value.slice(0, split.value.length - 1).substr(1);
						}
					} else if (/^{.+?}$/.test(split.value)) {
						split.isField = true;
					} else {
						const num = parseFloat(split.value);
						if (!isNaN(num)) {
							//is number
							split.value = num;
						} else {
							if (split.value.startsWith("(")) {
								const functions = string.matchCharGroupings(split.value, "(", ")", false);
								//do this replace so if a dot appears inside a function transformation, the split wont see it
								functions.forEach((x, i) => (split.value = split.value.replace(x, "$$$$issub_" + i))); // $$ equals $

								//remove whitespace and quotes from ends of strings
								const trimmed = split.value.replace(/^("|\s)*|("|\s)*$/g, "");
								if (trimmed.includes("$$issub_")) {
									const index = parseInt(trimmed.match(/\$\$issub_(\d+)/)[1], 10);
									const sub = trimmed.replace(/\$\$issub_\d+/, functions[index]);
									//is a sub function
									split.subActions = split.subActions || [];
									split.subActions.push(new Splitter(sub).split());
									split.subActionKey = `$$subaction_${split.subActions.length - 1}`;
								}
							}
						}
					}
				}

				rtn.push(split);
				val = "";
				continue;
			} else if (char === "(") {
				this.inParentheses++;
			} else if (char === ")") {
				this.inParentheses--;
			} else if (char === "'" || char === '"') {
				this.inString = !this.inString;
			}

			val += char;
		}

		//find all the div and multiply actions next to each other and move them into a sub action list so they get processed before surrounding math operators
		rtn = this.processOrderOfOperations(rtn, x => x.isOperator && (x.value === "*" || x.value === "/"));
		// now sort plus minus operations
		rtn = this.processOrderOfOperations(rtn, x => x.isOperator && (x.value === "+" || x.value === "-"));

		return rtn;
	}

	processOrderOfOperations(actions: Action[], isValid: (ac: Action) => boolean) {
		let indexOfHigherOperationOrder = actions.findIndex(x => isValid(x));

		while (~indexOfHigherOperationOrder) {
			let endIndex = indexOfHigherOperationOrder;

			for (let action = actions[endIndex]; endIndex < actions.length && isValid(action); endIndex += 2, action = actions[endIndex]) {}

			const startRange = indexOfHigherOperationOrder - 1;
			const endRange = endIndex;
			const sublist = actions.splice(startRange, endRange - startRange, {
				value: null,
				isFunction: false,
				isOperator: false,
				isValue: true,
				subActionKey: "$$subaction_0"
			});
			actions[startRange].subActions = [sublist];

			indexOfHigherOperationOrder = actions.findIndex(x => isValid(x));
		}
		return actions;
	}
}

type Operators = "+" | "-" | "*" | "/" | "==" | "||" | "&&";

interface Action {
	value: any;
	/** is an actual function to call like `#DateTime.parse()` etc. functions start with # */
	isFunction: boolean;
	/** is + - / * etc */
	isOperator: boolean;
	/** not operator and not fucntion */
	isValue: boolean;
	isField?: boolean;

	subActionKey?: string;
	subActions?: Action[][];

	functionParams?: string[];
	funcToCall?: string;
}
