import React, { PureComponent } from "react";
import { Provider } from "react-redux";
import Shell from "ui/Shell/shell";
import configureStore from "./configureStore";
import sendError from "./sendError";
import config from "config/config";
import * as offlineservice from "services/offline";
import "config/fa_icons";
import Splash from 'ui/Shell/splashscreen';
import { ChannelEventProvider } from "react-channel-event";

export default class Setup extends PureComponent {
	constructor(props) {
		console.log("\nStarted.. \n");

		super(props);

		this.state = {
			config: null,
			width: null,
			height: null,
			error: null
		};
	}

	componentDidCatch(error, info) {
		// catch DOM errors
		console.error(error);
		console.error(info.componentStack);

		sendError(this.state.config.store.getState(), { payload: { message: error, stackTrace: info.componentStack } }, "REACT DOM ERROR");
		this.setState({ error: error + "\nAt:\n" + info.componentStack });
	}

	updateDimensions() {
		//trigger rerenders on screen size changes
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

	componentDidMount() {
		window.addEventListener("resize", this.updateDimensions.bind(this));
		configureStore().then(config => {
			//add post init logic here

			offlineservice.database.initialize().then(() => {
				offlineservice.offlinerequest.initialize();

				//show splash screen for 0.5 second then show real app
				setTimeout(() => {
					this.setState({ config, width: window.innerWidth, height: window.innerHeight });
				}, 500);
			});
		});
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions.bind(this));
	}

	render() {
		if (this.state.error) {
			return <div>{config.debug && <pre style={{ padding: 30 }}>{this.state.error}</pre>}</div>;
		}

		return this.state.config ? (
			<ChannelEventProvider hub={this.state.config.hub}>
				<Provider store={this.state.config.store}>
					<Shell history={this.state.config.history} width={this.state.width} height={this.state.width} />
				</Provider>
			</ChannelEventProvider>
		) : (
			<Splash />
		);
	}
}
