import React from "react";
import ReactDOM from "react-dom";
import Setup from "./boot/setup";
import * as serviceWorker from "boot/registerServiceWorker";
import config from "config/config";
import securityservice from "services/security/security";

function checkIncomingAuthToken(){
    var url = new URL(location.href);
    var token = url.searchParams.get("jwt");
    var expiry = url.searchParams.get("expiry");
    if(token){
        securityservice.iframeToken = token;
        securityservice.iframeExpiry = expiry;
        config.restrictedMode = true;
    }
}
checkIncomingAuthToken();


//add polyfills to config/polyfills.js

ReactDOM.render(<Setup />, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({ client: window });
