import "./theme/global.scss";

//import these files so webpack processes them
import "config/$env";

const config = {
	defaultRoute: "/Form/List",
	persistVersion: 1,
	...window.REDI_enviromentConfig,
	apiPrefix: "api/",
	clientSiteUrl: window.REDI_enviromentConfig.clientSiteUrl,
	get baseUrl() {
		return window.REDI_enviromentConfig.apiBaseUrl + this.apiPrefix;
	},
	tokenKey: "token-key",
	tokenExpDateKey: "tokenExpDateKey",
	getDetailsFromJwt: true,
	indexedDbName: "cs_form_ui_db",
	//roles required to do anything
	defaultRoles: ["formsapp", "clientformsapp", "clientformsadmin"],
	supportEmail: "support@connectsource.com.au",
	restrictedMode: false
};

export default config;
