import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Route } from "react-router";
import routes from "config/routeConfig";
import Header from "components/Header/Header";
import { ConnectedRouter } from "connected-react-router";
import CSSModules from "react-css-modules";
import styles from "./styles.scss";
import background from "assets/background.png"
import toast, { ToastDOM } from "components/Toast/Toast";
// import Menu from "components/Menu/Menu";
import "./unhide.css";
import Drawer from "components/Drawer/Drawer";
import SideMenu from "components/SideMenu/SideMenu";
import Authorize from "components/Auth/Auth";
import config from "config/config";
import JobDeleter from "../../services/offline/jobDeleter";


@CSSModules(styles, { allowMultiple: true })
class Shell extends PureComponent {
	constructor(props) {
		super(props);

		this.toggleMenu = this.toggleMenu.bind(this);
		this.state = {
			menuOpen: false
		};
		JobDeleter.start();
	}

	toggleMenu() {
		this.setState({ menuOpen: !this.state.menuOpen });
	}

	renderRoutes = () => {
		const rtn = routes.map((s, index) => (
			<Route
				style={{ flex: 1, display: "flex", flexDirection: "column" }}
				{...s}
				key={index + window.location.href}
			/>
		));
		console.warn(rtn);
		return rtn;
	};

	render() {
		return (
			<ConnectedRouter history={this.props.history}>
				<div styleName="wraps" id="shell">
					{/* header */}
					{!config.restrictedMode &&
						<Header height={this.props.height} width={this.props.width}  toggleMenu={this.toggleMenu} />
					}
					{/* content */}
					<div styleName="content-wraps">
						{window.innerWidth < styles.small && ( //only show on mobile
							<Drawer open={this.state.menuOpen} floatOn="always" zIndex={24} onClose={this.toggleMenu}>
								<SideMenu onClose={this.toggleMenu} />
							</Drawer>
						)}
						<div id="content" styleName="content-class" style={{backgroundSize: 'cover', backgroundImage: `url(${(this.props.clientConfig.defaultBackgroundUrl) || background})`}}>
							{this.renderRoutes()}
						</div>
					</div>
					<div>{/* footer */}</div>
					<div id="shell-portal" styleName="portal" />
					<ToastDOM />
				</div>
			</ConnectedRouter>
		);
	}
}

const mapStateToProps = state => ({
	clientConfig: state.client.clientConfig
});

export default connect(
	mapStateToProps
)(Shell);
