import * as React from "react";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config/config";
import { HttpError } from "utils/http";

//https://fkhadra.github.io/react-toastify/

/*

USE:
	//import showToast from 'components/Toast/Toast';

	showToast('error', "message", params);
or
	showToast('error', error);


TYPES:
	info
	success
	warning
	error
	default




*/

const DURATION = 4500;

const _defaultParams = {
	position: "bottom-right",
	autoClose: DURATION,
	hideProgressBar: true,
	closeOnClick: true,
	pauseOnHover: true,
	draggable: true,
	draggablePercent: 65,
	pauseOnVisibilityChange: true,
	forceShow: false
};

export class ToastDOM extends React.PureComponent {
	render() {
		return (
			<div>
				<ToastContainer
					// position={"bottom-right"}
					autoClose={DURATION}
					hideProgressBar
					newestOnTop={false}
					closeOnClick={true}
					rtl={false}
					// pauseOnVisibilityChange={true}
					draggable={true}
					pauseOnHover={true}
					transition={Slide}
					draggablePercent={65}
				/>
			</div>
		);
	}
}

export type Toasts = "info" | "success" | "warning" | "error" | "default";

export default function showToast(type: Toasts, message: HttpError | Error | string | any, params?: Partial<typeof _defaultParams>) {
	const _params = { ..._defaultParams, ...params };

	if (!_params.forceShow && type === "error" && !config.showErrorToasts) {
		return;
	}

	if (!_params.forceShow && type === "success" && !config.showSuccessToasts) {
		return;
	}

	if (message instanceof Error) {
		try {
			const err = JSON.parse(message.message);
			if (err.modelState && err.modelState[""][0]) {
				message = err.modelState[""][0];
			} else if (err.exceptionMessage) {
				message = err.exceptionMessage;
			} else if (message.message) {
				message = message.message;
			} else {
				message = "An error has occurred";
			}
		} catch (e) {
			if (message.message) {
				message = message.message;
			} else {
				message = "An error has occurred";
			}
		}
	} else if (message instanceof HttpError) {
		try {
			let err;
			if (typeof message.response === "string") {
				err = JSON.parse(message.response);
			} else {
				err = message.response;
			}
			if (err.modelState && err.modelState[""][0]) {
				message = err.modelState[""][0];
			} else if (err.exceptionMessage) {
				message = err.exceptionMessage;
			} else if (err.message) {
				message = err.message;
			} else {
				message = "An error has occurred";
			}
		} catch (e) {
			if (message.response) {
				message = message.response;
			}
			if (message.message) {
				message = message.message;
			} else {
				message = "An error has occurred";
			}
		}
	}

	toast[type || "default"](message, _params);
}
