//initial redux
import { put, call, takeEvery, fork, all, take, cancel, select, takeLatest } from "redux-saga/effects";
import securityservice from "services/security/security";
import { types as setuptypes } from "boot/setupRedux";
import * as router from "connected-react-router";
import { persist, string } from "utils/index";
import { Action } from "redux";
import { ReduxAction, FormDto, FormVersionDto, JobTypeDto, WorkflowSummaryDto, CompanyScheduleDto } from "redi-types";
import scheduleservice from "services/schedule";
import config from "config/config";

const initialState = {
    companySchedule: [],
	selectedCompanySchedule: {},
    error: "",
};

const persistConf = {
    whitelist: ["selectedJobType"],
	expireInNumHours: 0 //dont expire
};

/////////
//types
export const types = {
    LOAD_COMPANY_SCHEDULE: "companyschedule/LOAD_COMPANY_SCHEDULE",
	SET_COMPANY_SCHEDULE: "companyschedule/SET_COMPANY_SCHEDULE",
	SET_SELECTED_COMPANY_SCHEDULE: "companyschedule/SET_SELECTED_COMPANY_SCHEDULE",
	EDIT_JOB: "companyschedule/EDIT_JOB",

    ON_ERROR: "companyschedule/ON_ERROR"
};

///////////
//reducers
const reducers = {
	reducer(state = initialState, action: ReduxAction) {
		switch (action.type) {
			case types.LOAD_COMPANY_SCHEDULE:
				return state;
			case types.SET_COMPANY_SCHEDULE:
				return {...state, companySchedule: action.payload.companySchedule};
			case types.SET_SELECTED_COMPANY_SCHEDULE:
				return {...state, selectedCompanySchedule: action.payload.selectedCompanySchedule};
            case types.ON_ERROR:
                return {...state, error: action.payload.error}
			default:
				return state;
		}
	}
};
export const reducer = persist("companyschedule", reducers.reducer, persistConf);

//////////
//sagas
export const sagas = {
	*rootSaga() {
		yield all([
            this.loadCompanySchedule(),
			this.onSetCompanySchedule(),
			this.editJob()
		]);
    },
    *loadCompanySchedule(){
        yield takeLatest<ReduxAction>(types.LOAD_COMPANY_SCHEDULE, function*({payload}){
            let data = yield call(scheduleservice.LoadCompanyScheduleSummary);
            if(data.error){
				console.error(data.error);
				yield put(actions.onError(data.error));
			}else{
                yield put(actions.setCompanySchedule(data.data));
			}
        });
    },
	*editJob(){
		yield takeLatest<ReduxAction>(types.EDIT_JOB, function*({payload}){
			let jobId: string = payload.jobId;
			let uri = "/Job/" + jobId;
			yield put(router.push(uri));
		});
	},
	*onSetCompanySchedule(){
		yield takeLatest<ReduxAction>([types.SET_COMPANY_SCHEDULE], function*({payload}){
            let selectedCompanySchedule: CompanyScheduleDto = yield select<any>(x => x.companyschedule.selectedCompanySchedule);
            let companySchedule: Array<CompanyScheduleDto> = (yield select<any>(x => x.companyschedule.companySchedule)) as Array<CompanyScheduleDto>;
            if(companySchedule && companySchedule.length){
                if(selectedCompanySchedule){
                    let existing = companySchedule.find(s => s.statusCategory == selectedCompanySchedule.statusCategory);
                    if(existing){
                        yield put(actions.setSelectedCompanySchedule(existing));
                    }else{
                        yield put(actions.setSelectedCompanySchedule(companySchedule[0]));
                    }
                }else{
                    yield put(actions.setSelectedCompanySchedule(companySchedule[0]));
                }
            }
		});
	},
};

////////
//actions
export const actions = {
    loadScheduleSummary(){
        return {
            type: types.LOAD_COMPANY_SCHEDULE,
            payload: {}
        };
    },
	editJob(jobId: string){
		return {
			type: types.EDIT_JOB,
			payload: {jobId}
		};
	},
	setCompanySchedule(companySchedule){
		return {
			type: types.SET_COMPANY_SCHEDULE,
			payload: {companySchedule}
		};
	},
	setSelectedCompanySchedule(selectedCompanySchedule){
		return {
			type: types.SET_SELECTED_COMPANY_SCHEDULE,
			payload: {selectedCompanySchedule}
		};
	},
	onError(error) {
		return {
			type: types.ON_ERROR,
			payload: error
		};
	}
};
