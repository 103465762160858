import securityservice from "services/security/security";
import config from "config/config";
import * as uuid4 from "uuid/v4";
import { getState } from "boot/configureStore";
import { ManualPromise, ImageUtil, number } from "utils";
import ImageCompressor from "image-compressor.js";
import { offlinerequest } from "services/offline";
import { MediaFileLink, MediaImage, MediaExtensions, MediaFileWithThumbNail } from "./mediatypes";

interface MediaFileLinkOptions {
	image?: {
		maxHeight?: number;
		maxWidth?: number;
		quality?: number;
		useCompression?: boolean;
	};
}

const defaultMediaLinkCOnfig: MediaFileLinkOptions = {
	image: {
		maxHeight: 1200,
		maxWidth: 1200,
		quality: 0.9,
		useCompression: true
	}
};

export default class mediaservice {
	static supportedThumbnailTypes = ["jpg", "jpeg", "png", "gif", "mp4", "pdf", "mov"];

	/**
	 * Upload and return a standard file link object representing a given file
	 * @param file
	 * @param options see MediaFileLinkOptions
	 */
	static createFileLink(file: File, options?: MediaFileLinkOptions): Promise<MediaFileLink | MediaImage> {
		options = { ...defaultMediaLinkCOnfig, ...options };

		return new Promise((resolve, reject) => {
			let promises = [];

			const extension = this.getFileExtension(file.name);

			const amazonFileNameNoExtension = uuid4();
			const amazonFileName = amazonFileNameNoExtension + "." + extension;
			const amazonDirectory = getState(x => x.login.currentDetails.ClientId) || "no_client";

			let fileLink: MediaImage | MediaFileWithThumbNail = new MediaFileLink();

			fileLink.name = file.name;
			fileLink.size = file.size;
			fileLink.amazonUrl = amazonDirectory + "/" + amazonFileName;
			fileLink.extension = extension;

			//Can a  thuumbnail be created for this file?
			//Thumbnail url, or null if no thumbnail can be created for this file type
			if (this.canCreateThumbnail(extension)) {
				fileLink = new MediaFileWithThumbNail(fileLink);
				fileLink.thumbnailUrl = amazonDirectory + "/" + amazonFileNameNoExtension + "-thumbnail.jpg";
			}

			//see if it's a video, if so get duration.
			//Only set type to video if it's an mp4.
			//only get imensions if it's an mp4
			if (file.type.indexOf("video") >= 0) {
				throw new Error("video upload not implemented");
			}

			//set as pdf type if pdf
			if (file.type.indexOf("pdf") >= 0) {
				throw new Error("pdf upload not implemented");
			}

			//see if it's an image. If so, get dimensions.
			if (file.type.indexOf("image") >= 0) {
				fileLink = new MediaImage(fileLink);
				const dimensionsDeferred = new ManualPromise();
				promises.push(dimensionsDeferred.promise);
				ImageUtil.load(file).then(image => {
					const dimensions = ImageUtil.getDimensions(image);
					(<MediaImage>fileLink).width = dimensions.width;
					(<MediaImage>fileLink).height = dimensions.height;
					dimensionsDeferred.Resolve();
				});
			}

			//read the media and convert it to a string so we can send it in http or store in db
			const fileReaderProm = new ManualPromise();
			promises.push(fileReaderProm.promise);

			let fileReader = new FileReader();
			fileReader.onload = function(e: any) {
				fileLink.data = e.target.result;
				fileReaderProm.Resolve();
			};
			fileReader.onerror = function(e) {
				fileReaderProm.Reject(e);
				reject(e);
			};

			//if its an image then compress and then upload
			if (options.image.useCompression && file.type.indexOf("image") >= 0) {
				let browserAutoRotates = getComputedStyle(document.body)['imageOrientation'] == 'from-image';
				new ImageCompressor(file, {
					quality: options.image.quality,
					convertSize: 201400, // Now 100k, was 1mb
					maxWidth: options.image.maxWidth,
					maxHeight: options.image.maxHeight,
					checkOrientation: !browserAutoRotates,
					
					success: function(result) {
						fileReader.readAsArrayBuffer(result);
					}
				});
			} else {
				fileReader.readAsArrayBuffer(file);
			}

			Promise.all(promises)
				.then(() => {
					//now upload the file to amazon
					this.uploadFile(amazonDirectory, amazonFileName, fileLink)
						.then(() => {
							//convert data to a url so img tag can display it now
							const urlCreator = (<any>window).URL || (<any>window).webkitURL;
							const imageUrl = urlCreator.createObjectURL(new Blob([fileLink.data]));
							fileLink.data = imageUrl;
							resolve(fileLink);
						})
						.catch(reject);
				})
				.catch(reject);
		});
	}

	private static uploadFile(amazonDirectory: string, amazonFileName: string, fileLink: MediaFileLink): Promise<any> {
		return new Promise((resolve, reject) => {
			const url = `${config.baseUrl}FileManager/UploadToAmazon`;

			const createThumbnail = !!(<MediaFileWithThumbNail>fileLink).thumbnailUrl;

			const headers = {
				"Content-Disposition": 'attachment; filename="' + fileLink.name + '"'
			};

			const fileReader = new FileReader();
			fileReader.onload = function(event) {
				offlinerequest
					.saveRequest(
						{
							url,
							method: "POST",
							headers,
							amazonDirectory,
							amazonFileName,
							createThumbnail,
							data: fileReader.result
						},
						null,
						false,
						60000
					)
					.then(resolve, reject);
			};
			fileReader.readAsArrayBuffer(new Blob([fileLink.data]));
		});
	}

	static dataURLtoFile(dataurl, filename) {
		let arr = dataurl.split(","),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);
		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}
		let blob: any = new Blob([u8arr], { type: mime });

		blob.lastModifiedDate = new Date();
		blob.name = filename;

		return blob as File;
	}

	//given a file extension returns true if thumbnail creation is suported, else false if not
	private static canCreateThumbnail(extension) {
		let canCreate = false;
		for (let ii = 0; ii < this.supportedThumbnailTypes.length; ii++) {
			if (this.supportedThumbnailTypes[ii].toLowerCase() == extension.toLowerCase()) {
				canCreate = true;
				break;
			}
		}
		return canCreate;
	}

	private static getFileExtension(fileName): MediaExtensions {
		let extension: MediaExtensions = "";
		if (fileName) {
			let index = fileName.lastIndexOf(".");
			if (index >= 0 && index < fileName.length - 1) {
				extension = fileName.substring(index + 1);
			}
		}
		return extension;
	}
}

// const mediaservice = new mediaservice_();

// export default mediaservice;
