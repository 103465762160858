import React from "react";
import PropTypes from "prop-types";
import { audio as Audio } from "./loading/audio";
import { ballTrangle as BallTrangle } from "./loading/ball-triangle";
import { bars as Bars } from "./loading/bars";
import { circles as Circles } from "./loading/circles";
import { grid as Grid } from "./loading/grid";
import { hearts as Hearts } from "./loading/hearts";
import { oval as Oval } from "./loading/oval";
import { puff as Puff } from "./loading/puff";
import { rings as Rings } from "./loading/rings";
import { tailSpin as TailSpin } from "./loading/tail-spin";
import { threeDots as ThreeDots } from "./loading/three-dots";
import theme from "config/theme/theme.scss";

/**
 * Available types: http://samherbert.net/svg-loaders/
 */
export default class Spinner extends React.Component {
	static defaultProps = {
		color: theme.primaryColor,
		type: "TailSpin",
		size: 40,
		visible: true
	};
	static propTypes = {
		color: PropTypes.string,
		type: PropTypes.string,
		height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
	};
	svg = (type, visible) => {
		const props = { height: this.props.size, width: this.props.size, ...this.props };

		if (type === "Audio") {
			return Audio(props);
		} else if (type === "Ball-Triangle") {
			return BallTrangle(props);
		} else if (type === "Bars") {
			return Bars(props);
		} else if (type === "Circles") {
			return Circles(props);
		} else if (type === "Grid") {
			return Grid(props);
		} else if (type === "Hearts") {
			return Hearts(props);
		} else if (type === "Oval") {
			return Oval(props);
		} else if (type === "Puff") {
			return Puff(props);
		} else if (type === "Rings") {
			return Rings(props);
		} else if (type === "TailSpin") {
			return TailSpin(props);
		} else if (type === "ThreeDots") {
			return ThreeDots(props);
		}

		throw new Error("Spinner type " + type + " does not exist.");
	};
	render() {
		const { color, type, height, width } = this.props;
		const style = {
			fill: color,
			height,
			width
		};
		return this.svg(type);
	}
}
