import { string } from "utils";

export default class {
	static capitalizeFirstLetter(string: string) {
		if (string) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		} else {
			return null;
		}
	}

	static toCamelCase(value: string | object): string | any {
		if (Array.isArray(value)) {
			for (let index = 0; index < value.length; index++) {
				const item = value[index];
				value[index] = this.toCamelCase(item);
			}
		} else if (value && typeof value === "object") {
			for (let k in value) {
				if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
					value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
					delete value[k];
				}
			}
		} else if (typeof value === "string") {
			return value.charAt(0).toLowerCase() + value.substring(1);
		}
		return value;
	}

	/**
	 * ACROText -> ACRO Text
	 * 
		UserNameTest -> User Name Test
	 * @param {string} string 
	 */
	static insertSpaces(string: string) {
		string = string.replace(/([a-z])([A-Z])/g, "$1 $2");
		string = string.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
		return string;
	}

	static operator(operator: "gt" | "gte" | "lt" | "lte" | "eq" | "neq" | "range") {
		switch (operator) {
			case "gt":
				return "Greater Than";
			case "gte":
				return "Greater Than or Equal";
			case "lt":
				return "Less Than";
			case "lte":
				return "Less Than or Equal";
			case "eq":
				return "Equal";
			case "neq":
				return "Not Equal";
			case "range":
				return "Range";
			default:
				return "";
		}
	}

	/**
	 * Return value at path in given object
	 * @param obj
	 * @param path
	 */
	static path<T>(obj: T, path: string): any {
		let rtns = obj;
		const paths = path.split(".");
		for (let index = 0; index < paths.length && rtns; index++) {
			let p = paths[index];
			const arr = p.match(/\[(\d+)\]/g);
			if (arr && arr.length) {
				for (let index = 0; index < arr.length; index++) {
					//get array prop then index of item
					p = p && /^[^\[]*/.exec(p)[0];
					if (p) {
						rtns = rtns[p][arr[index].match(/\d+/)[0]];
						p = null;
					} else {
						rtns = rtns[arr[index].match(/\d+/)[0]];
					}
				}
			} else {
				rtns = rtns[p];
			}
		}
		return rtns;
	}

	/**
	 * Matches all chars between startGroup and endGroup and returns a list of groupings
	 * @param str
	 * @param startGroup
	 * @param endGroup
	 * @param nested return nested groups. behaves like regex groups
	 */
	static matchCharGroupings(str: string, startGroup: string, endGroup: string, nested = false) {
		let copy = str;
		let resultGroups: string[] = [];
		let currentGroups: string[] = [];
		let nestCount = 0;
		for (; copy.length; copy = copy.substr(1)) {
			for (let cgi = 0; cgi < currentGroups.length; cgi++) {
				currentGroups[cgi] += copy[0];
			}
			if (copy.startsWith(startGroup)) {
				if (nested || currentGroups.length === 0) {
					currentGroups.push(copy[0]);
				}
				nestCount++;
			}
			if (copy.startsWith(endGroup)) {
				nestCount = Math.max(0, nestCount - 1);
				if (nested || nestCount === 0) {
					const end = currentGroups.splice(currentGroups.length - 1, 1);
					if (end[0] !== undefined) {
						resultGroups.unshift(end[0]);
					}
				}
			}
		}
		return resultGroups;
	}
}
