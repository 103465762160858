import * as React from "react";
export const FormEntryContext = React.createContext<IFormEntryContext>({} as any);

import * as router from "connected-react-router";
import { connect } from "react-redux";

import { AuthHoC } from "components/Auth/Auth";

import autoBind from "libs/react-autobind/index";
import { deepClone, array, FormDefaultValues, string, number } from "utils/index";
import { actions as formactions } from "redux/formRedux";
import { actions as formsubactions } from "redux/formSubmissionRedux";
import {
	CommonUIProps,
	FormVersionDto,
	FormSubmissionDto,
	FormSubmissionField,
	FormSubmissionData,
	FormDataField,
	LogicData,
	ActionData,
	WorkflowSequenceDto
} from "redi-types";
import { InjectedFormProps, reduxForm, formValueSelector } from "redux-form";
import { validate } from "./validate";
import { MediaFileLink } from "services/media/mediatypes";
import { ReduxState } from "config/reduxRoot";
import { FormEntry_ } from "./FormEntry";
import { any } from "prop-types";

/*  ==========================================================
 * 									hoc
 *  ==========================================================*/
//this wrapper hoc is used to get form from server or persist and add dynamic form id `form={this.props.currentForm.entryFormId}`
class EntryPage extends React.PureComponent<Props_, { FormComponent: any }> {
	actions: FormEntryActions;
	initialValues: FormVersionDto;
	_throttleblock: boolean;
	_throttleAction: { action: any; args: any[] };

	constructor(props) {
		super(props);
		autoBind(this);
		this.actions = this.props.exposeActions(this);
		let selector: any;
		if (this.props.inFormVersionDto || (this.props.location.state && this.props.location.state.form)) {
			this.actions.setCurrentForm(this.props.inFormVersionDto || this.props.location.state.form);
		} else { 
			this.actions.getForm(this.props.match.params.formId);
		} 

		const formSubmission = this.props.pendingFormSubmissions[this.props.inFormSubmissionId || this.props.match.params.formSubmissionId];
		if (!formSubmission) {
			this.actions.getFormSubmission(this.props.inFormSubmissionId || this.props.match.params.formSubmissionId);
		}

		selector = formValueSelector("formsubmission-" + (this.props.inFormSubmissionId || this.props.match.params.formSubmissionId));

		const FormEntry = reduxForm<FormSubmissionDto, Props_>({
			// enableReinitialize: true,
			destroyOnUnmount: true,
			validate: (v, props) => {
				//because we not using enableReinitialize: true, do this check
				if (props.initialized) {
					let errors = validate(v, props);
					if (this.props.updateFormValidity) {
						let valid: boolean = true;
						if(errors && errors.data && errors.data.sections && errors.data.sections.length){
							for(var ii = 0; ii < errors.data.sections.length; ii++){
								let wkSection = errors.data.sections[ii];
								if(wkSection && wkSection.fields && wkSection.fields.length){
									for(var jj = 0; jj < wkSection.fields.length; jj++){
										let wkField = wkSection.fields[jj];
										if(wkField && wkField.value){
											valid = false;
											break;
										}
									}
								}
								if(!valid){
									break;
								}
							}
						}
						this.props.updateFormValidity(valid);
					}
					return errors
				} else {
					return {};
				}
			},
			onChange: values => {
				fillSummary(values as FormSubmissionDto);

				//Update the pending submissions list
				this.actions.updatePendingFormSubmissions(values as FormSubmissionDto);

				//limit saves to once evry 5 secs
				this.throttle(5000, this.actions.save, values);
			}
		})(FormEntry_);

		
		this.state = {
			FormComponent: connect(
				(state: ReduxState) => ({
					data: selector(state, "data"),
					getValue: (path: string) => selector(state, path)
				}),
				() => ({})
			)(FormDefaultValues(FormEntry, (props, prev) => props.initialValues !== prev.initialValues, [x => x.initialValues]))
		};
	}

	throttle(ms, action, ...args) {
		if (!this._throttleAction) {
			this._throttleAction = { action, args };
			setTimeout(() => {
				if (this._throttleAction) {
					this._throttleAction.action(...this._throttleAction.args);
					this._throttleAction = null;
				}
			}, ms);
		} else {
			this._throttleAction = { action, args };
		}
	}

	componentWillUnmount() {
		const formSubmission = this.props.pendingFormSubmissions[this.props.inFormSubmissionId || this.props.match.params.formSubmissionId];
		if (formSubmission && formSubmission.completedOn) {
			//remove completed form from redux to prevent mem leaks
			this.actions.removeFormSubmission(this.props.match.params.formSubmissionId);
		}

		this.actions.setCurrentForm(null);
	}

	/**
	 * called by child form component. only moved up into HoC so `this._throttleAction` can be set to null
	 */
	submit(data: FormSubmissionDto, sendCopy: boolean) {
		//set this to null so any pending saves dont override the submit
		this._throttleAction = null;

		const dto = deepClone(data);

		const recurseFields = (f: FormSubmissionField) => {
			if (f.value instanceof MediaFileLink) {
				//dont need data in json
				delete f.value.data;
			}
			if (f.fieldType === "RepeatingGroup") {
				f.subGroupedValues.forEach(x => x.forEach(recurseFields));
			}
		};

		dto.completedOn = new Date();
		dto.data.sendCopy = sendCopy;
		dto.data.sections.forEach(sect => {
			sect.fields.forEach(recurseFields);
		});

		fillSummary(dto);

		this.actions.submit(dto);
	}

	render() {
		const Elem = this.state.FormComponent;
		let formSubmission = null;
		let disableDefaultControlButtons = false;
		let showDeleteButton = true;
		if(this.props.inFormSubmissionId){
			disableDefaultControlButtons = true; //For Jobs
			formSubmission = this.props.pendingFormSubmissions[this.props.inFormSubmissionId];
		}else{
			formSubmission = this.props.pendingFormSubmissions[this.props.match.params.formSubmissionId];
		}
		//set initialValues true to create the data structure. set enableReinitialize false to keep values when leave-revisit page
		return Elem && this.props.currentForm && formSubmission ? (
			<Elem {...this.props} form={formSubmission.entryFormId} showDeleteButton={showDeleteButton} disableDefaultControlButtons={disableDefaultControlButtons} initialValues={formSubmission} submitForm={this.submit} callOnChange={this.props.callOnChange} />
		) : null;
	}
}

/*  ==========================================================
 * 									redux
 *  ==========================================================*/
const bindAction = dispatch => {
	return {
		exposeActions: (component: FormEntry_ | EntryPage) => {
			return {
				getForm: id => dispatch(formactions.getForm(id)),
				setCurrentForm: query => dispatch(formactions.setCurrentForm(query)),
				submit: data => dispatch(formsubactions.submit(data)),
				save: data => dispatch(formsubactions.save(data)),
				getFormSubmission: id => dispatch(formsubactions.getFormSubmission(id)),
				deleteFormSubmission: id => dispatch(formsubactions.delete(id)),
				removeFormSubmission: id => dispatch(formsubactions.removeFormSubmission(id)),
				updatePendingFormSubmissions: data => dispatch(formsubactions.updatePendingFormSubmissions(data)),
				navigate: (uri, data) => dispatch(router.push(uri, data)),
				goBack: () => dispatch(router.goBack()),
				getFormVersion: (formVersionId) => dispatch(formactions.getFormVersion(formVersionId))
			};
		}
	};
};

const mapStateToProps = (state: ReduxState, ownProps) => {
	return {
		currentForm: state.forms.currentForm,
		name: state.login.currentDetails.DisplayName,
		pendingFormSubmissions: state.formsubmission.pendingFormSubmissions,
		leftButtons: state.job.leftButtons,
		rightButtons: state.job.rightButtons,
		nextStatuses: state.job.nextStatuses,
		jobBackRoute: state.job.backRoute
	};
};

export default AuthHoC(
	connect(
		mapStateToProps,
		bindAction
	)(EntryPage)
);

/*  ==========================================================
 * 									TYPES
 *  ==========================================================*/

export interface Props_ extends CommonUIProps<{ formSubmissionId: string; formId: string }> {
	exposeActions: (component: FormEntry_ | EntryPage) => FormEntryActions;
	getValue: (path: string) => any;
	isBusy: boolean;
	pendingFormSubmissions: { [x: string]: FormSubmissionDto };
	currentForm: FormVersionDto;
	submitForm: (data: FormSubmissionDto, sendCopy: boolean) => void;
	name: string;
	disable: boolean;
	inFormVersionDto?: FormVersionDto;
	callOnChange(fieldId: string, value: any): void;
	inFormSubmissionId?: string;
	updateFormValidity?: (formValid: boolean) => void;
	leftButtons?: Array<HeaderButton>;
	rightButtons?: Array<HeaderButton>;
	nextStatuses?: object;
	disableDefaultControlButtons?: boolean;
	showDeleteButton?: boolean;
	jobId: string;
	jobBackRoute?: string;
}
export interface HeaderButton {
	theme: "default" | "primary" | "secondary";
	text: string;
	onClick: (e: any) => void;
	style: any;
	disabled: boolean;
	//literalyl here cos it makes it work i have nfi why
	nfi: string;
}
//merge with form props
export type FormEntryProps = Props_ & InjectedFormProps<FormSubmissionDto, Props_>;
export interface FormEntryState {
	loaded?: boolean;
	showDeleteModal: boolean;
	sendCopy: boolean;
	context: IFormEntryContext;
}

export type RegisteredField = { id: string; logic?: LogicData[]; actions?: ActionData[], repeatingGroupRow?: number };

export interface IFormEntryContext {
	currentForm: FormVersionDto;
	setContext: (context: Partial<IFormEntryContext>) => void;
	registerField: (path: string, data: RegisteredField) => void;
	fieldIsRegistered: (path: string) => boolean;
	unRegisterField: (path: string) => void;
	getValue: (path: string) => any;
	getPath: (fieldId: string) => string;
	callOnChange(fieldId: string, value: any): void;
	formId: string;
	coords?: Coordinates;
	disabled: boolean;
	data: FormSubmissionData;
	change: (field: string, value: any) => void;
	performAction: (thisFieldPath: string, action: ActionData) => void;
}
export interface FormEntryActions {
	getForm: (id) => void;
	setCurrentForm: (form: FormVersionDto) => void;
	getFormSubmission: (id: string) => void;
	deleteFormSubmission: (id: string) => void;
	removeFormSubmission: (id: string) => void;
	submit: (form: FormSubmissionDto) => void;
	save: (form: FormSubmissionDto) => void;
	updatePendingFormSubmissions: (form: FormSubmissionDto) => void;
	navigate: (uri, data?) => void;
	goBack: () => void;
	getFormVersion: (formVersionId: string) => void;
}

function fillSummary(dto: FormSubmissionDto) {
	dto.summary = array
		.selectMany(dto.data.sections, x => x.fields)
		.filter(x => x.includeInSummary && x.value != null && typeof x.value === "string")
		.map(x => x.value)
		.join(", ");

	return dto;
}
