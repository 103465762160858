//initial redux
import { put, call, takeEvery, fork, all, take, cancel, select, takeLatest } from "redux-saga/effects";
import securityservice from "services/security/security";
import { types as setuptypes } from "boot/setupRedux";
import * as router from "connected-react-router";
import { persist, string } from "utils/index";
import { Action } from "redux";
import { ReduxAction, FormDto, FormVersionDto, JobTypeDto, WorkflowSummaryDto } from "redi-types";
import scheduleservice from "services/schedule";
import config from "config/config";

const initialState = {
    jobTypes: [],
	selectedJobType: {},
	workflowSummary: {
		statusSummaries: []
	},
	selectedStatusSummary: {},
    error: "",
};

const persistConf = {
    whitelist: ["selectedJobType"],
	expireInNumHours: 0 //dont expire
};

/////////
//types
export const types = {
    LOAD_JOB_TYPES: "schedule/LOAD_JOB_TYPES",
    SET_JOB_TYPES: "schedule/SET_JOB_TYPES",
	SET_SELECTED_JOB_TYPE: "schedule/SET_SELECTED_JOB_TYPE",
	LOAD_WORKFLOW_SUMMARY: "schedule/LOAD_WORKFLOW_SUMMARY",
	SET_WORKFLOW_SUMMARY: "schedule/SET_WORKFLOW_SUMMARY",
	SET_SELECTED_STATUS_SUMMARY: "schedule/SET_SELECTED_STATUS_SUMMARY",
	START_JOB: "schedule/START_JOB",
	EDIT_JOB: "schedule/EDIT_JOB",

    ON_ERROR: "schedule/ON_ERROR"
};

///////////
//reducers
const reducers = {
	reducer(state = initialState, action: ReduxAction) {
		switch (action.type) {
            case types.SET_SELECTED_JOB_TYPE:
                return { ...state, selectedJobType: action.payload.selectedJobType };
            case types.LOAD_JOB_TYPES:
                return state;
            case types.SET_JOB_TYPES:
				return {...state, jobTypes: action.payload.jobTypes};
			case types.LOAD_WORKFLOW_SUMMARY:
				return state;
			case types.SET_WORKFLOW_SUMMARY:
				return {...state, workflowSummary: action.payload.workflowSummary};
			case types.SET_SELECTED_STATUS_SUMMARY:
				return {...state, selectedStatusSummary: action.payload.selectedStatusSummary};
			case types.START_JOB:
				return state;
            case types.ON_ERROR:
                return {...state, error: action.payload.error}
			default:
				return state;
		}
	}
};
export const reducer = persist("schedule", reducers.reducer, persistConf);

//////////
//sagas
export const sagas = {
	*rootSaga() {
		yield all([
			this.loadJobTypes(), 
			this.onSelectJobType(), 
			this.onSetWorkflowSummary(),
			this.startJob(),
			this.editJob()
		]);
	},
	*editJob(){
		yield takeLatest<ReduxAction>(types.EDIT_JOB, function*({payload}){
			let jobId: string = payload.jobId;
			let uri = "/Job/" + jobId;
			yield put(router.push(uri));
		});
	},
	*startJob(){
		yield takeLatest<ReduxAction>(types.START_JOB, function*({payload}){
			let data = yield call(scheduleservice.StartJob, payload.jobTypeCode);
			if(data.error){
				console.error(data.error);
				yield put(actions.onError(data.error));
			}else{
				let jobId: string = data.data;
				let uri = "/Job/" + jobId;
				yield put(router.push(uri));
			}
		});
	},
	*loadJobTypes() {
		yield takeEvery<ReduxAction>(types.LOAD_JOB_TYPES, function*({ payload }) {
            let data = yield call(scheduleservice.GetJobTypes);
			if (data.error) {
				console.error(data.error);
				yield put(actions.onError(data.error));
			} else {
				yield put(actions.setJobTypes(data.data));
				let selectedJobType = yield select<any>(x => x.schedule.selectedJobType);
				if((!selectedJobType || !selectedJobType.jobTypeCode) && data.data && data.data.length){
					yield put(actions.setSelectedJobType(data.data[0]));
				}
			}
		});
	},
	*onSelectJobType(){
		yield takeLatest<ReduxAction>([types.SET_SELECTED_JOB_TYPE, types.LOAD_WORKFLOW_SUMMARY], function*({payload}){
			let selectedJobType = yield select<any>(x => x.schedule.selectedJobType);
			if(selectedJobType && selectedJobType.jobTypeCode){
				let data = yield call(scheduleservice.GetWorkflowSummary, selectedJobType.jobTypeCode);
				if(data.error){
					console.error(data.error);
					yield put(actions.onError(data.error));
				}else{
					yield put(actions.setWorkflowSummary(data.data));
				}
			}
		});
	},
	*onSetWorkflowSummary(){
		yield takeLatest<ReduxAction>([types.SET_WORKFLOW_SUMMARY], function*({payload}){
			let selectedStatusSummary = yield select<any>(x => x.schedule.selectedStatusSummary);
			let workflowSummary: WorkflowSummaryDto = payload.workflowSummary;
			if(workflowSummary && workflowSummary.statusSummaries && workflowSummary.statusSummaries.length){
				if(selectedStatusSummary && selectedStatusSummary.workflowStatusCode){
					let existing = workflowSummary.statusSummaries.find(s => s.workflowStatusCode == selectedStatusSummary.workflowStatusCode);
					if(existing){
						yield put(actions.setSelectedStatusSummary(existing));
					}
				}else{
					yield put(actions.setSelectedStatusSummary(workflowSummary.statusSummaries[0]));
				}
			}
		});
	},
};

////////
//actions
export const actions = {
	loadJobTypes() {
		return {
			type: types.LOAD_JOB_TYPES,
			payload: {  }
		};
	},
	startJob(jobTypeCode: string){
		return {
			type: types.START_JOB,
			payload: {jobTypeCode}
		};
	},
    setJobTypes(jobTypes: JobTypeDto[]){
        return {
            type: types.SET_JOB_TYPES,
            payload: {jobTypes}
        };
	},
	editJob(jobId: string){
		return {
			type: types.EDIT_JOB,
			payload: {jobId}
		};
	},
	setSelectedJobType(selectedJobType: JobTypeDto){
		return {
			type: types.SET_SELECTED_JOB_TYPE,
			payload: {selectedJobType}
		};
	},
	loadWorkflow(){
		return {
			type: types.LOAD_WORKFLOW_SUMMARY,
			payload: {}
		};
	},
	setWorkflowSummary(workflowSummary){
		return {
			type: types.SET_WORKFLOW_SUMMARY,
			payload: {workflowSummary}
		};
	},
	setSelectedStatusSummary(selectedStatusSummary){
		return {
			type: types.SET_SELECTED_STATUS_SUMMARY,
			payload: {selectedStatusSummary}
		};
	},
	onError(error) {
		return {
			type: types.ON_ERROR,
			payload: error
		};
	}
};
