import { http, DateTime, string } from "../utils/index";
import config from "config/config";
import showToast from "components/Toast/Toast";
import { FormSubmissionDto } from "redi-types";
import { offlinerequest } from "./offline";
import { HttpResult } from "utils/http";
import { dispatch } from "boot/configureStore";
import { actions as formsubmissionactions } from "redux/formSubmissionRedux";

const routeoffline = "Queue/Add/";
const route = "FormSubmission/";

offlinerequest.subscribe(data => {
	if (data.type === "request_success") {
		//remove submissions from outbox when the offline queue sends them
		dispatch(formsubmissionactions.removeFromOutbox(data.key));
	}
});

export default class formsubmissionservice {
	static Submit(dto: FormSubmissionDto) {
		let url = `${config.baseUrl + routeoffline}FormSubmissionSubmit`;

		return offlinerequest
			.saveRequest({ url, method: "POST", data: dto }, dto.formSubmissionId)
			.then(data => {
				showToast("success", "Submission Saved");
				return data;
			})
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static Save(dto: FormSubmissionDto) {
		let url = `${config.baseUrl + routeoffline}FormSubmissionSave`;

		return offlinerequest
			.saveRequest({ url, method: "POST", data: dto })
			.then(data => {
				return data;
			})
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static Delete(formSubmissionId: string) {
		let url = `${config.baseUrl + routeoffline}FormSubmissionDelete`;

		return offlinerequest
			.saveRequest({ url, method: "POST", data: { formSubmissionId } })
			.then(data => {
				return data;
			})
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static Get(formSubmissionId: string) {
		let url = `${config.baseUrl + route + formSubmissionId}/Get`;

		return http({ url, method: "GET" })
			.then(data => {
				return data;
			})
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static GetPending(query?: string) {
		let url = `${config.baseUrl + route}GetPending`;

		return http({ url, method: "GET", query })
			.then(data => {
				return data;
			})
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static GetCompleted(pagingParams: PagingParameters): Promise<HttpResult<DataSourceResult<FormSubmissionDto>>> {
		let url = `${config.baseUrl + route}GetCompleted`;

		return http({ url, method: "GET", pagingParams })
			.then(data => {
				return data;
			})
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static GetTypeaheadFieldValues(fieldId: string, formId: string, query?: string): Promise<HttpResult<string[]>> {
		let url = `${config.baseUrl + route}GetTypeaheadFieldValues`;

		return http({ url, method: "GET", query, fieldId, formId })
			.then(data => {
				return data;
			})
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static GenericTypeaheadFetch(fieldId: string, formId: string, query: string, endpointUri: string): Promise<HttpResult<string[]>> {
		//remove starting slashes
		endpointUri = endpointUri.replace(/^\/+/, "");
		let url = config.baseUrl + endpointUri;

		return http({ url, method: "GET", query, fieldId, formId })
			.then(data => {
				return data;
			})
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}

	static GenericTypeaheadObjectFetch(fieldId: string, formId: string, query: string, endpointUri: string, data: any): Promise<HttpResult<ITypeaheadObject[]>> {
		//remove starting slashes
		endpointUri = endpointUri.replace(/^\/+/, "");
		let url = config.baseUrl + endpointUri;

		return http({ url, method: "POST", query, fieldId, formId, data })
			.then(data => {
				return data;
			})
			.catch(error => {
				showToast("error", error.error);
				return error;
			});
	}
}



export interface ITypeaheadObject {
	id: string;
	displayValue: string;
}