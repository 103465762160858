import { EventIterable, IChannel } from "channel-event";
import { storeEvents, IStoreEvents } from "channel-store";


/**
 * Block and wait for changes in the app state, and return those changes
 * @param selector Watch a slice of the app state
 */
export function watch(selector?: (state: any) => any): EventIterable {
	return {
		function: "watch",
		value: selector
	};
}

/**
 *	Block until one of the passed promises resolves or until one of the passed EventIterable runs to completion.
 * The value returned will the result of the first race item to complete
 * @param raceItems Any number of promises or EventIterable to race
 */
export function race(...raceItems: Array<EventIterable | Promise<any>>) {
	return {
		function: "race",
		value: raceItems
	};
}

export const customChannelGeneratorActions = {
	/** Implementation for `watch` */
	watch: function(data: EventIterable<(state: any) => any>, channel: IChannel<IStoreEvents<any>>): Promise<any> {
		// store the initial value of the slice of app state so we can compare when it changes
		const state = channel.send(storeEvents.GET_STATE);
		const initialSliceValue = data.value ? data.value(state) : state;

		return new Promise((resolve, reject) => {
			const unsub = channel.listen(storeEvents.STATE_UPDATED, result => {
				// if the value of the state at the slice changed, then resolve
				const newSlice = data.value ? data.value(result.payload) : result.payload;
				if (newSlice !== initialSliceValue) {
					unsub();
					resolve(newSlice);
				}
			});
			channel.onDispose(reject);
		});
	},
	/** race implementation */
	race: function(data: EventIterable<Array<EventIterable | Promise<any>>>, channel: IChannel<IStoreEvents<any>>): Promise<any> {
		if (!data.value?.length) {
			//nothing to race
			return Promise.resolve();
		}

		// create an isolated channel that will be disposed on completion
		// all listeners that did not win the race need to be rejected and cleaned up to prevent mem leaks
		const chan = channel.hub.newChannel();

		return Promise.race(
			data.value.map(item => {
				if (item instanceof Promise) {
					return item;
				} else {
					// is iterator. yield it and see what happens
					return new Promise((resolve, reject) => {
						chan.runGenerator(function*() {
							return yield item;
						}, resolve);
						chan.onDispose(reject);
					});
				}
			})
		).finally(() => {
			chan.dispose();
		});
	}
};
