import * as React from "react";
import * as styles from "./styles.scss";
import * as CSSModules from "react-css-modules";
import * as PropTypes from "prop-types";
import { CommonComponentProps, FormSubmissionDto, Omit } from "redi-types";
import { WrappedFieldProps } from "redux-form";
import { MergeStyles, shouldUpdate, array } from "utils";
import vehicleservice from "services/vehicle";
import Button from "components/Button/Button";
import autoBind from "libs/react-autobind/index";
import SwitchButton from "components/Switch/switch";
import { Typeahead, TypeaheadMenuItem, TypeaheadProps } from "redi-common-inputs";
//import Typeahead, { TypeaheadProps } from "components/Typeahead/Typeahead";
//import { TypeaheadMenuItem } from "components/Typeahead/Typeahead";
/**
 * Action Button For Vehicle (Registration and Number) typeahead dropdown to
 * switch between searching for Vehicles closest/nearest to any and all.
 */
class VehicleActionButton extends React.PureComponent<{
	refresh?: () => void;
	toggleSearchAll: (then?: () => void) => void;
	searchAll: boolean;
	coordinates: Coordinates;
}> {
	render() {
		return this.props.coordinates ? (
			<SwitchButton
				onChange={val => {
					this.props.toggleSearchAll(this.props.refresh);
				}}
				checked={this.props.searchAll}
				label="Search All Vehicles"
				labelLeft="Search Nearest Vehicles"
				size={20}
			/>
		) : null;
	}
}

/**
 * Type ahead for Vehicle search (Display ID and Registration)
 */
class FormVehicleTypeahead extends React.Component<RenderVehicleTypeaheadProps<any> & WrappedFieldProps, State> {
	static defaultProps = {
		typeaheadClass: "",
		errorClass: ""
	};

	constructor(props) {
		super(props);
		autoBind(this);
		this.state = {
			searchAll: false
		};
	}

	toggleSearchAll(then?: () => void) {
		this.setState({ searchAll: !this.state.searchAll }, then);
	}

	getData(val) {
		//Check coordinates exists encase location has been disabled
		if (this.props.coords) {
			return vehicleservice.GetClosestVehicles(
				{ lat: this.props.coords.latitude, lng: this.props.coords.longitude },
				this.state.searchAll,
				val
			);
		} else {
			//Send dummy lat/lng coordinates as location has been disabled
			return vehicleservice.GetClosestVehicles({ lat: 0, lng: 0 }, this.state.searchAll, val);
		} 
	}

	render() {
		let {
			input,
			meta: { touched, error },
			classes,
			label,
			className,
			typeaheadClass,
			errorClass,
			required,
			titleTransform,
			...other
		} = this.props;

		return (
			<div className={className} styleName="input-group">
				<div formlabel="1">
					{label}
					{required && <span> *</span>}
				</div>
				<Typeahead
					{...other}
					name={input.name}
					value={input.value}
					onChange={input.onChange}
					lastRowTemplate={VehicleActionButton}
					styleName={`__typohead ${typeaheadClass}`}
					titleTransform={titleTransform}
					getData={this.getData}
					//props for last row
					toggleSearchAll={this.toggleSearchAll}
					searchAll={this.state.searchAll}
					coordinates={this.props.coords}
				>
					{data =>
						data.map(item => (
							<TypeaheadMenuItem key={item.assetId} value={item}>
								{item.value}
							</TypeaheadMenuItem>
						))
					}
				</Typeahead>
				{touched && error && <div styleName={`_input-error ${errorClass}`}>{error}</div>}
			</div>
		);
	}

	shouldComponentUpdate(nextProps: RenderVehicleTypeaheadProps<any> & WrappedFieldProps, nextState: State) {
		return shouldUpdate(this, nextProps, nextState, ["onBlur", "onChange", "getData"]);
	}
}

export const RenderVehicleTypeahead = MergeStyles(styles, { forwardRef: false })(FormVehicleTypeahead);

export interface RenderVehicleTypeaheadProps<T> extends Omit<TypeaheadProps<T>, WrappedFieldProps["input"] & { getData: any }> {
	label?: string;
	titleTransform: (obj: string | T) => string;
	typeaheadClass?: string;
	errorClass?: string;
	required?: boolean;
	disbaled?: boolean;
	coords: Coordinates;
}

interface State {
	searchAll: boolean;
}
