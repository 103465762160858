//initial redux
import { put, call, takeEvery, fork, all, take, select } from "redux-saga/effects";


const initialState = {
   isOpen: window.innerWidth > 960,
};

/////////
//types
export const types = {
   TOGGLE_OPEN: "screens/menu/TOGGLE_OPEN",

};

///////////
//reducers
export const reducers = {
   reducer(state = initialState, action) {
      switch (action.type) {
         case types.TOGGLE_OPEN:
            return { ...state, isOpen: !state.isOpen };


         default:
            return state;
      }
   }
};

//////////
//sagas
export const sagas = {
	*rootSaga() {
		yield all([]);
	}
};


////////
//actions
export const actions = {
   toggleOpen() {
      return {
         type: types.TOGGLE_OPEN,
         payload: {}
      };
   },

   genericAction(type, data) {
      return {
         type,
         payload: data
      };
   }
};
