import vehicleservice from "services/vehicle";
import { put, call, takeEvery, all } from "redux-saga/effects";
import { persist } from "utils/index";
import { ReduxAction, LocationDto, AssetDto } from "redi-types";

const initialState = {
    currentVehicle: { 
        assetMakeDescription: "",
        assetModel: ""   
    },
    closestVehiclesList: [],

    errors: [],
    isBusy: false
}

const persistConf = {
	whitelist: ["vehicle"],
	expireInNumHours: 0
};

/////////
//types
export const types = {
    SET_VEHICLE: "vehicle/SET_VEHICLE",
	GET_CLOSEST_VEHICLES_LIST: "vehicle/GET_CLOSEST_VEHICLES_LIST",
	ON_GET_CLOSEST_VEHICLES_LIST: "vehicle/ON_GET_CLOSEST_VEHICLES_LIST",

	ON_ERROR: "vehicle/ON_ERROR"
};

///////////
//reducers
const reducers = {
	reducer(state = initialState, action: ReduxAction) {
		switch (action.type) {
            case types.SET_VEHICLE:
                return {...state, currentVehicle: action.payload.selectedVehicle }
			case types.GET_CLOSEST_VEHICLES_LIST:
				return { ...state, isBusy: true };
			case types.ON_GET_CLOSEST_VEHICLES_LIST:
				return { ...state, isBusy: false, closestVehiclesList: action.payload.closestVehiclesList };
			case types.ON_ERROR:
				return { ...state, errors: state.errors.concat(action.payload).slice(-10), isBusy: false };
			default:
				return state;
		}
	}
};
export const reducer = persist("vehicle", reducers.reducer, persistConf);

//////////
//sagas
export const sagas = {
	*rootSaga() {
		yield all([this.getClosestVehicles()]);
    },

    *getClosestVehicles() {
        yield takeEvery<ReduxAction>(types.GET_CLOSEST_VEHICLES_LIST, function*({payload}) {
            let data = yield call(vehicleservice.GetClosestVehicles, payload.location);
            if (data.error) {
                console.error(data.error);
                yield put(actions.onError(data.error));
            } else {
                yield put(actions.onGetClosestVehicles(data.data));
            }
        });
    }
};


////////
//actions
export const actions = {
    getClosestVehicles(location: LocationDto) {
        return {
            type: types.GET_CLOSEST_VEHICLES_LIST,
            payload: { location }
        }
    },
    onGetClosestVehicles(closestVehiclesList: AssetDto[]) {
        return {
            type: types.ON_GET_CLOSEST_VEHICLES_LIST,
            payload: { closestVehiclesList }
        }
    },
    setVehicle(selectedVehicle: AssetDto) {
        return {
            type: types.SET_VEHICLE,
            payload: { selectedVehicle }
        }
    },
    onError(error) {
		return {
			type: types.ON_ERROR,
			payload: error
		};
	}
};