import { jobInstance } from "./database";
import { TIMEOUT } from "dns";
import { DbJobDto } from "redi-types";

//time between checking for ddeleted crap
const PROCESS_INTERVAL: number = 60 * 1000;

//max allowed age of jobs before we delete em
const MAX_JOB_AGE: number = 24 * 60 * 60 * 1000;

export default class JobDeleter {
    private static _running: boolean = false;

    static start(){
        JobDeleter._running = true;
        jobInstance.initialize().then(JobDeleter.startLoop);
    }

    static stop(){
        JobDeleter._running = false;
    }

    private static timeout(milliseconds: number): Promise<any>{
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    private static async startLoop(){
        while(JobDeleter._running){
            await Promise.all([
                JobDeleter.removeOldJobs(), 
                JobDeleter.timeout(PROCESS_INTERVAL)
            ]);
        }
    }

    private static async removeOldJobs(): Promise<any>{
        let promise: Promise<any> = new Promise<any>((resolve, reject) => {
            jobInstance.getKeysForIndex("job", "jobId").then((result: Array<string>) => {
                if(result && result.length){
                    for(var ii = 0; ii < result.length; ii++){
                        jobInstance.getValueForIndex("job", "jobId", result[ii]).then((dbJob: DbJobDto) => {
                            if(dbJob.lastUpdateTimeTicks + MAX_JOB_AGE < Date.now()){
                                jobInstance.deleteForKey("job", result[ii]).then(resolve, reject);
                            }
                        }, reject);
                    }
                }else{
                    resolve();
                }
            }, reject);
        });
        return promise;
    }
}