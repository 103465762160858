import { LogicQueryOperator, FormSubmissionField } from "redi-types";

export class ProcessFieldLogic {
	static compare(fieldValue: FormSubmissionField, value: any, operator: LogicQueryOperator, inputValue: any): boolean {
		switch (operator) {
			case "eq":
				return value == inputValue; //soft eq '=='
			case "neq":
				return value != inputValue; //soft neq '!='
			case "lt":
				return value < inputValue;
			case "lte":
				return value <= inputValue;
			case "gt":
				return value > inputValue;
			case "gte":
				return value >= inputValue;
			case "null":
				return !value;
			case "notnull":
				return !!value;
			case "startswith":
				return value && value.startswith(inputValue);
			case "endswith":
				return value && value.endswith(inputValue);
			case "contains":
				return value && value.includes(inputValue);
			case "doesnotcontain":
				return value && !value.includes(inputValue);
			case "isRequired":
				return fieldValue.fieldOptions.required;
			case "isNotRequired":
				return !fieldValue.fieldOptions.required;
		}
	}
}
