import * as React from "react";
export const FormEntryContext = React.createContext<IFormEntryContext>({} as any);

import * as styles from "./styles.scss";

import * as CSSModules from "react-css-modules";

import autoBind from "libs/react-autobind/index";
import { geo, array, string, DateTime, deepClone } from "utils/index";
import Checkbox from "components/Checkbox/checkbox";
import Button from "components/Button/Button";
import {
	FormSubmissionDto,
	FormSubmissionData,
	FormDataField,
	LogicData,
	ActionData,
	FormSubmissionField,
	PropertiesOfType,
	FormDataSection,
	FormMatrixRow
} from "redi-types";
import Spinner from "libs/spinner";
import { FieldArray, FormAction } from "redux-form";
import Modal from "components/BaseModal/Modal";
import Popup, { MenuAction } from "components/PopupMenu/menu";
import { RenderFormSections } from "./RenderSectionsGroups";
import config from "config/config";
import { getState, subscribeAction } from "boot/configureStore";
import { ProcessFieldLogic } from "./actions";
import { IFormEntryContext, FormEntryProps, FormEntryState, FormEntryActions, RegisteredField, HeaderButton } from "./Hoc";
import { TransformationFunctions } from "./transformationFunctions";
import showToast from "components/Toast/Toast";

/**
 * Page ui
 */
@CSSModules(styles, { allowMultiple: true })
export class FormEntry_ extends React.PureComponent<FormEntryProps & { data: FormSubmissionData}, FormEntryState> {
	static defaultProps = {
		disableDefaultControlButtons: false,
		showDeleteButton: false
	};
	actions: FormEntryActions;
	_mobileButton: MenuAction[];
	completed: boolean;
	private __frameContext: IFormEntryContext;

	private fieldDefinitionStore: {
		[id: string]: FormDataField | FormDataSection;
	};
	private fieldPathStore: {
		[path: string]: RegisteredField;
	};

	unsub: () => void;
	_unmounted: boolean;
	constructor(props) {
		super(props);
		autoBind(this, { wontBind: ["registerField", "unRegisterField", "performAction", "performLogic"] });
		this.actions = this.props.exposeActions(this);

		//Set value based whether the form is completed
		this.completed = !!this.props.initialValues.completedOn;
		//bind these manually becuase they get spam called
		this.registerField = this.registerField.bind(this);
		this.unRegisterField = this.unRegisterField.bind(this);
		this.performAction = this.performAction.bind(this);
		this.performLogic = this.performLogic.bind(this);
		this.fieldIsRegistered = this.fieldIsRegistered.bind(this);
		this.callOnChange = this.callOnChange.bind(this);

		this.fieldDefinitionStore = flattenFields(deepClone(this.props.currentForm.formDefinition.sections)).reduce((prev, next) => {
			prev[next.id] = next;
			return prev;
		}, {});
		this.fieldPathStore = {};

		this.state = {
			showDeleteModal: false,
			loaded: false,
			sendCopy: true,
			context: {
				setContext: this.setContext,
				currentForm: this.props.currentForm,
				formId: this.props.initialValues && this.props.initialValues.formId,
				disabled: this.completed,
				data: this.props.data,
				getValue: this.props.getValue,
				getPath: this.getPath,
				callOnChange: this.callOnChange,
				change: this.props.change,
				registerField: this.registerField,
				unRegisterField: this.unRegisterField,
				performAction: this.performAction,
				fieldIsRegistered: this.fieldIsRegistered,
			}
		};
		//Ignore adding menu buttons when form is completed
		if (!this.completed) {
			this._mobileButton = [
				new MenuAction("Submit", this.props.handleSubmit(data => this.submit(data, this.state.sendCopy))),
				new MenuAction("Clear", this.props.reset),
				new MenuAction("Delete", () => !this._unmounted && this.setState({ showDeleteModal: true }))
			];
		}

		//this event is fired whenever the redux form change action is dispatched
		this.unsub = subscribeAction("@@redux-form/CHANGE", (action: FormAction) => {
			if (this.props.initialValues && action.meta.form === this.props.initialValues.entryFormId) {
				const registeredField = this.fieldPathStore[action.meta.field];
				if (registeredField) {
					//wait until the end of the frame so redux state has been properly updated
					setTimeout(() => {
						//check if this field has any actions
						if (registeredField.actions && registeredField.actions.length) {
							const performNow = registeredField.actions.filter(x => x.when === "onChange");
							for (let index = 0; index < performNow.length; index++) {
								const act = performNow[index];
								this.performAction(action.meta.field, act);
							}
						}

						//check if other fields logic points to this field
						array.forEachProps(this.fieldDefinitionStore, (otherField, id) => {
							if (otherField.logic && otherField.logic.some(f => f.fieldIds && f.fieldIds.includes(registeredField.id))) {
								//this definition has logic that points to this field that just changed.

								otherField.logic.forEach(l => this.performLogic(otherField, l, action.meta.field, registeredField));
							}
						});
					});
				}
			}
		});
	}

	getPath(fieldId: string): string {
		return Object.entries(this.fieldPathStore).find(x => x[1].id === fieldId)?.[0];
	}

	callOnChange(fieldId: string, value: any) {
		this.props.callOnChange?.(fieldId, value);
	}

	componentWillUnmount() {
		this.unsub();
		this._unmounted = true;
	}

	static getDerivedStateFromProps(props: FormEntryProps, state: FormEntryState) {
		let newContext = {};
		let changed = false;
		//merged changed props into context state. only works for props whose name matches the property in the context
		for (const prop in state.context) {
			if (state.context.hasOwnProperty(prop) && props.hasOwnProperty(prop)) {
				if (state.context[prop] !== props[prop]) {
					newContext[prop] = props[prop];
					changed = true;
				}
			}
		}

		if (changed) {
			//create new context and update child componts
			return { ...state, context: { ...state.context, ...newContext }};
		} else {
			return state;
		}
	}

	componentDidUpdate(prevProps: FormEntryProps & { data: FormSubmissionData }, prevState: FormEntryState) {
		if (
			prevProps.initialValues !== this.props.initialValues &&
			(!this.props.initialValues || this.state.context.formId !== this.props.initialValues.formId)
		) {
			this.setContext({ formId: this.props.initialValues && this.props.initialValues.formId });
		}
	}

	/**this field is excluded from autobind due to performance */
	registerField(path: string, data: RegisteredField) {
		this.fieldPathStore[path] = data;
	}

	/**this field is excluded from autobind due to performance */
	fieldIsRegistered(path: string) {
		return path in this.fieldPathStore;
	}

	/**this field is excluded from autobind due to performance */
	unRegisterField(path: string) {
		if (!isNaN(this.fieldPathStore[path].repeatingGroupRow)) {
			let rowsAfter = array.filterProps(
				this.fieldPathStore,
				x => x.id === this.fieldPathStore[path].id && x.repeatingGroupRow > this.fieldPathStore[path].repeatingGroupRow
			);

			//decrement all folowing rows
			for (let index = 0; index < rowsAfter.length; index++) {
				const row = rowsAfter[index];
				row.value.repeatingGroupRow--;
				if (row.value.logic) {
					for (let li = 0; li < row.value.logic.length; li++) {
						const logic = row.value.logic[li];
						if (logic.memoizedTransformationFunction) {
							logic.memoizedTransformationFunction.splice(this.fieldPathStore[path].repeatingGroupRow, 1);
						}
					}
				}
				if (row.value.actions) {
					for (let li = 0; li < row.value.actions.length; li++) {
						const action = row.value.actions[li];
						if (action.memoizedTransformationFunction) {
							action.memoizedTransformationFunction.splice(this.fieldPathStore[path].repeatingGroupRow, 1);
						}
					}
				}
			}
		}

		delete this.fieldPathStore[path];
	}

	/**
	 * this field is excluded from autobind due to performance
	 * @param thisFieldPath path to the field that will be effected by the action
	 * @param action the action
	 * @param value if passed, will use this value instead of any automaticly fetched
	 */
	performAction(thisFieldPath: string, action: ActionData, value?: any) {
		let pathMatcher: RegExp = null;
		const registeredField = this.fieldPathStore[thisFieldPath];
		const isRepeatingField = /\.subGroupedValues\[\d+\]\[\d+\]\.value$/.test(thisFieldPath);

		if (isRepeatingField) {
			//create a regex that only matches paths in this repeating group row.
			const reg = `^${thisFieldPath.match(/^(.*\[\d+\])\[\d+\]\.value$/)[1]}[(\\d+)].value$`;
			//escape all [ ] and .
			const escapedreg = reg.replace(/[\[\]\.]/g, x => "\\" + x);
			pathMatcher = new RegExp(escapedreg);
		}

		if (!value) {
			if (action.action === "prefillFromRedux") {
				value = getState();
			} else {
				value = this.props.getValue(thisFieldPath);
			}
		}

		if (action.transformation) {
			// get value at given path
			value = this.applyTransformations(
				action,
				action.transformation,
				value,
				pathMatcher,
				registeredField.repeatingGroupRow,
				"memoizedTransformationFunction"
			);
		}

		//set the value of the given ids in teh action
		for (let index = 0; index < action.fieldIds.length; index++) {
			const id = action.fieldIds[index];
			const paths = array.mapProps(this.fieldPathStore, (x, path) => (id === x.id ? path : undefined)).filter(x => !!x);
			for (let p = 0; p < paths.length; p++) {
				const path = paths[p];
				this.props.change(path, value && typeof value === "object" ? { ...value } : value);
			}
		}
	}

	/**
	 * this field is excluded from autobind due to performance
	 * @param fieldWithLogic the definition of the field that will be effected by the logic
	 * @param logic the logic
	 * @param triggeringFieldPath path to the field that triggered this logic
	 * @param fieldAtPath Registered field of the triggering field
	 */
	performLogic(
		fieldWithLogic: FormDataField | FormDataSection,
		logic: LogicData,
		triggeringFieldPath?: string,
		fieldAtPath?: RegisteredField
	) {
		let path = Object.keys(this.fieldPathStore).find(x => this.fieldPathStore[x].id === fieldWithLogic.id);
		let pathMatcher: RegExp = null;
		let value = logic.value;
		let input = logic.input;
		if(!path){
			return;
		}
		//flags to detirmine whether is repeating
		const triggeredByRepeatingField = !isNaN(fieldAtPath?.repeatingGroupRow);
		const isRepeatingField = /\.subGroupedValues\[\d+\]\[\d+\]\.value$/.test(path);
		const rowIndex = fieldAtPath?.repeatingGroupRow;

		const fieldType: "Field" | "Section" | "Group" =
			// matrix and RG dont have a .value member
			"isField" in fieldWithLogic && fieldWithLogic.fieldType !== "Matrix" && fieldWithLogic.fieldType !== "RepeatingGroup"
				? "Field"
				: "isSection" in fieldWithLogic
				? "Section"
				: "Group";

		if (triggeredByRepeatingField && isRepeatingField) {
			//make sure both fields are inside the same repeating group
			if (
				triggeringFieldPath && triggeringFieldPath.slice(0, triggeringFieldPath.lastIndexOf(".subGroupedValues[")) !==
				path.slice(0, path.lastIndexOf(".subGroupedValues["))
			) {
				throw new Error(
					`Cannot compare a field from one Repeating group to a field inside a different Repeating group. Ids: ${
						fieldWithLogic.id
					}, ${fieldAtPath.id}`
				);
			} else {
				path = path.replace(/\[\d+\]\[(\d+)\]\.value$/, (sub, group) => `[${rowIndex}][${group}].value`);
			}
		}

		if (isRepeatingField) {
			//create a regex that only matches paths in this repeating group row.
			const reg = `^${path.match(/^(.*\[\d+\])\[\d+\]\.value$/)[1]}[(\\d+)].value$`;
			//escape all [ ] and .
			const escapedreg = reg.replace(/[\[\]\.]/g, x => "\\" + x);
			pathMatcher = new RegExp(escapedreg);
		}

		if (value === "true" || value === "false") {
			value = value === "true";
		} else if (typeof value === "string" && /^{.+?}$/.test(value)) {
			//is a field id. get value for that instead;
			const id = value.replace(/^{|}$/g, "");
			const paths = this.getPathsForFieldId(id, pathMatcher);
			if (paths) {
				value = this.props.getValue(paths);
			} else {
				value = null;
			}
		} else if (/^\s*f\(/i.test(value)) {
			value = this.applyTransformations(logic, value, null, pathMatcher, rowIndex, "memoizedValueTransformationFunctions");
		}

		if (input === "true" || input === "false") {
			input = input === "true";
		} else if (input && /^{.+?}$/.test(input)) {
			const id = input.replace(/^{|}$/g, "");
			const inputPath = this.getPathsForFieldId(id, pathMatcher);
			if (inputPath) {
				input = this.props.getValue(inputPath); //remove '.value'
			} else {
				input = null;
			}
		} else if (/^\s*f\(/i.test(input)) {
			input = this.applyTransformations(logic, input, null, pathMatcher, rowIndex, "memoizedInputTransformationFunctions");
		}else if(input.startsWith("redux ")){
			const path = input.replace(/^redux /, "");
			let state = getState();
			input = this.applyTransformations(
				logic,
				path,
				state,
				pathMatcher,
				rowIndex,
				"memoizedTransformationFunction"
			);
		}

		
		const fieldValue: FormSubmissionField = this.props.getValue(fieldType === "Field" ? path.slice(0, path.length - 6) : path); //remove '.value'
		const isPass = ProcessFieldLogic.compare(fieldValue, input, logic.operator, value);

		if (isPass) {
			switch (logic.action) {
				case "readonly":
					if(fieldType === "Section"){
						let childFields = Object.keys(this.fieldPathStore).filter((p) => p.startsWith(path) && p !== path);
						childFields.forEach(field => {
							this.props.change(field.replace(/value$/, "fieldOptions.readonly"), true);
						});
					}else if(fieldType === "Field"){
						this.props.change(path.replace(/value$/, "fieldOptions.readonly"), true);
					}else{
						throw "You may only add the readonly flag to sections and fields";
					}
				break;
				case "isRequired":
					this.props.change(
						fieldType === "Field"
							? path.replace(/value$/, "fieldOptions.requiredOverride")
							: path + "fieldOptions.requiredOverride",
						true
					);
					break;
				case "isNotRequired":
					this.props.change(
						fieldType === "Field"
							? path.replace(/value$/, "fieldOptions.requiredOverride")
							: path + "fieldOptions.requiredOverride",
						false
					);
					break;
				case "hide":
					this.props.change(
						fieldType === "Field" ? path.replace(/value$/, "fieldOptions.hide") : path + "fieldOptions.hide",
						true
					);
					break;
				case "show":
					this.props.change(
						fieldType === "Field" ? path.replace(/value$/, "fieldOptions.hide") : path + "fieldOptions.hide",
						false
					);
					break;
				case "clear":
					this.props.change(path, null);
					break;
				case "prefillFromRedux": {
					let value = getState();
					if (logic.transformation) {
						// get value at given path
						value = this.applyTransformations(
							logic,
							logic.transformation,
							value,
							pathMatcher,
							rowIndex,
							"memoizedTransformationFunction"
						);
					}
					this.props.change(path, value);
					break;
				}
				case "runTransformation":
				case "prefill": {
					if (typeof value === "string" && /^{.?}$/.test(value)) {
						//is a field id. get value for that instead;
						const id = value.replace(/^{|}$/g, "");
						const paths = this.getPathsForFieldId(id, pathMatcher);
						if (paths) {
							value = this.props.getValue(paths);
						} else {
							value = null;
						}
					}

					if (logic.transformation) {
						// get value at given path
						value = this.applyTransformations(
							logic,
							logic.transformation,
							value,
							pathMatcher,
							rowIndex,
							"memoizedTransformationFunction"
						);
					}

					if (logic.action === "prefill") {
						//only add value if set to prefill
						this.props.change(path, value);
					}
					break;
				}
			}
		} else {
			//failed pass, revert these certian actions
			switch (logic.action) {
				case "readonly":
					if(fieldType === "Section"){
						let childFields = Object.keys(this.fieldPathStore).filter((p) => p.startsWith(path) && p !== path);
						childFields.forEach(field => {
							this.props.change(field.replace(/value$/, "fieldOptions.readonly"), false);
						});
					}else if(fieldType === "Field"){
						this.props.change(path.replace(/value$/, "fieldOptions.readonly"), false);
					}else{
						throw "You may only add the readonly flag to sections and fields";
					}
					break;
				case "isNotRequired":
				case "isRequired":
					this.props.change(
						fieldType === "Field"
							? path.replace(/value$/, "fieldOptions.requiredOverride")
							: path + "fieldOptions.requiredOverride",
						undefined
					);
					break;
				case "show":
					this.props.change(
						fieldType === "Field" ? path.replace(/value$/, "fieldOptions.hide") : path + "fieldOptions.hide",
						true
					);
					break;
				case "hide":
					this.props.change(
						fieldType === "Field" ? path.replace(/value$/, "fieldOptions.hide") : path + "fieldOptions.hide",
						null
					);
					break;
			}
		}
	}

	applyTransformations(
		logic: LogicData,
		transformation: string,
		value: any,
		pathMatcher: RegExp,
		rowIndex: number,
		memoize: keyof PropertiesOfType<LogicData, TransformationFunctions[]>
	): any;
	applyTransformations(
		logic: ActionData,
		transformation: string,
		value: any,
		pathMatcher: RegExp,
		rowIndex: number,
		memoize: keyof PropertiesOfType<ActionData, TransformationFunctions[]>
	): any;
	applyTransformations(
		logic: LogicData | ActionData,
		transformation: string,
		value: any,
		pathMatcher: RegExp,
		rowIndex: number,
		memoize: string
	): any {
		let rtns = value;
		if (transformation) {
			const functions = string.matchCharGroupings(transformation, "(", ")", false);
			//do this replace so if a dot appears inside a function transformation, the split wont see it
			functions.forEach((x, i) => (transformation = transformation.replace("f" + x, "$$$$isfunction_" + i))); // $$ equals $
			const paths = transformation.split(".");

			for (let index = 0; index < paths.length; index++) {
				let p = paths[index];
				const arr = p.match(/\[(\d+)\]/g);
				if (rtns && arr && arr.length) {
					for (let index = 0; index < arr.length; index++) {
						//get array prop then index of item
						p = p && /^[^\[]*/.exec(p)[0];
						if (p) {
							rtns = rtns[p][arr[index].match(/\d+/)[0]];
							p = null;
						} else {
							rtns = rtns[arr[index].match(/\d+/)[0]];
						}
					}
				} else if (p.startsWith("$$isfunction")) {
					const tf = "f" + functions[parseInt(p.split("_")[1], 10)];
					//is a function evaluation
					logic[memoize] = logic[memoize] || [];
					logic[memoize][rowIndex] = logic[memoize][rowIndex] || this.generateTransformFunctions(tf, pathMatcher, rowIndex);

					if (logic[memoize][rowIndex].rowIndex !== rowIndex) {
						// row index changed. apply new value
						logic[memoize][rowIndex].regenerate(rowIndex, id => {
							const p = this.getPathsForFieldId(id, pathMatcher);
							if (p) {
								return this.props.getValue(p);
							} else {
								return null;
							}
						});
					}

					rtns = logic[memoize][rowIndex].evaluate(value);
				} else if (rtns) {
					rtns = rtns[p];
				}
			}
		}
		return rtns;
	}

	generateTransformFunctions(transform: string, pathMatcher: RegExp, rowIndex: number) {
		return new TransformationFunctions(transform, rowIndex, id => {
			const p = this.getPathsForFieldId(id, pathMatcher);
			if (p) {
				const val = this.props.getValue(p);
				return val;
			} else {
				return null;
			}
		});
	}

	/** return field with FieldId. */
	getPathsForFieldId(id: string, pathMatcher: RegExp) {
		const mapped = array.mapProps(this.fieldPathStore, (x, path) => (id === x.id ? path : undefined));
		const paths = mapped.filter(x => !!x && (!pathMatcher || pathMatcher.test(x)));
		if (paths.length) {
			return paths[0];
		} else if (pathMatcher) {
			return mapped.find(x => !!x);
		} else {
			return null;
		}
	}

	setContext(context: Partial<IFormEntryContext>, callback?: () => void) {
		//do this so if multiple updates happen in the same stack frame, we dont override the prev changes
		this.__frameContext = { ...this.state.context, ...this.__frameContext, ...context };
		if (!this._unmounted) {
			this.setState({ context: this.__frameContext }, () => {
				this.__frameContext = null;
				callback && callback();
			});
		}
	}

	componentDidMount() {
		geo.getPosition().then(coords => this.setContext({ coords }));
		setTimeout(() => {
			array.forEachProps(this.fieldDefinitionStore, (otherField, id) => {
				otherField.logic?.filter(x => x.runOnLoad).forEach(l => this.performLogic(otherField, l, null, null));
			});
			this.setState({loaded: true});
		});
	}

	render() {
		return (
			<div styleName="root">
				{!this.state.loaded && 
					<div style={{marginTop: "50px"}}>
						<h1>Loading...</h1>
					</div>
				}
				<div styleName="page" style={this.state.loaded ? {} : {opacity: 0}}>
					{(this.props.isBusy || !this.props.currentForm || !this.props.currentForm.formDefinition) && (
						<div styleName="center">
							<Spinner />
						</div>
					)}
					{this.props.currentForm && this.props.currentForm.formDefinition && (
						<React.Fragment>
							{!this.completed && (
								<div styleName="header" style={{zIndex: 9999}}>
									<div styleName="form-row flex-100">

									{this.props.leftButtons && this.props.leftButtons.map(btn => {
										return (
											<Button key={btn.text} theme={btn.theme} styleName="btn" onClick={btn.onClick} disabled={btn.disabled}>
												{btn.text}
											</Button>
										);
									}
									)}

									{!this.props.disableDefaultControlButtons &&
										<React.Fragment>
											<Button
											styleName="btn"
											raised={false}
											onClick={this.props.handleSubmit(data => this.submit(data, this.state.sendCopy))}>
												Submit
											</Button>
											<Checkbox
											styleName="check-box"
											checked={this.state.sendCopy}
											label="Send copy"
											onChange={() => this.setState({ sendCopy: !this.state.sendCopy })}/>
										</React.Fragment>
									}

									<div styleName="subreference">{this.props.initialValues.submissionReference}</div>

									{this.props.rightButtons && this.props.rightButtons.map(button => 
										<Button key={button.nfi} theme={button.theme} styleName="btn" onClick={button.onClick} disabled={button.disabled}>
											{button.text}
										</Button>
									)}

									{!this.props.disableDefaultControlButtons ?
										<React.Fragment>
											<Button theme="secondary" styleName="btn" raised={false} onClick={this.props.reset}>
												Clear
											</Button>
											<Button
											theme="secondary"
											raised={false}
											onClick={() => this.setState({ showDeleteModal: true })}
											styleName="btn">
												Delete
											</Button>
										</React.Fragment>
									: this.props.showDeleteButton ?
									<Button
										theme="secondary"
										raised={false}
										onClick={() => this.setState({ showDeleteModal: true })}
										styleName="btn">
											Delete
										</Button> : null
									}
										
									<Popup actions={this._mobileButton} styleName="mobile-menu" />
									</div>
								</div>
							)}
							<div styleName="content">
								<div styleName="title">{this.props.currentForm.name}</div>
								<FormEntryContext.Provider value={{ ...this.state.context }}>
									<FieldArray<{}> name="data.sections" component={RenderFormSections} props={{statuses: this.props.nextStatuses}} />
								</FormEntryContext.Provider>
							</div>
							{!this.completed && !this.props.disableDefaultControlButtons && (
								<Button
									styleName="btn"
									raised={false}
									onClick={this.props.handleSubmit(data => this.submit(data, this.state.sendCopy))}
								>
									Submit
								</Button>
							)}
						</React.Fragment>
					)}
				</div>
				<Modal
					isOpen={this.state.showDeleteModal}
					bodyText="Are You Sure?"
					title="Delete Form Submission"
					onClose={() => !this._unmounted && this.setState({ showDeleteModal: false })}
					onConfirm={() => {
						this.actions.deleteFormSubmission(this.props.initialValues.formSubmissionId);
						setTimeout(() => {
							const backRoute = this.props.jobBackRoute;
							if (backRoute) {
								this.actions.navigate(backRoute);
							} else if (this.props.jobId) {
								this.actions.goBack();
							} else {
								this.actions.navigate(config.defaultRoute);
							}
						}, 600); //Add a bit of a delay encase we are online and the form has time to be deleted on backend and remove from list
					}}
				/>
			</div>
		);
	}

	submit(data: FormSubmissionDto, sendCopy: boolean) {
		this.props.submitForm(data, sendCopy);
	}
}

function flattenFields<T extends FormDataField | FormDataSection>(items: T[]): T[] {
	if (isfields(items)) {
		let rtns: FormDataField[] = items;
		for (let index = 0; index < items.length; index++) {
			const field = items[index];
			if (field.matrixFields && field.matrixFields.length) {
				rtns = rtns.concat(flattenFields(array.selectMany(field.matrixFields, x => x.fields)));
			}
			if (field.subFields && field.subFields.length) {
				rtns = rtns.concat(flattenFields(field.subFields));
			}
		}
		return rtns as T[];
	} else if (isSection(items)) {
		let rtns: (FormDataSection | FormDataField)[] = items;
		for (let index = 0; index < items.length; index++) {
			const section = items[index];
			if (section.fields && section.fields.length) {
				rtns = rtns.concat(flattenFields(section.fields));
			}
		}
		return rtns as T[];
	} else {
		return [];
	}
}

function isfields(items: any[]): items is FormDataField[] {
	return "isField" in items[0];
}

function isSection(items: any[]): items is FormDataSection[] {
	return "isSection" in items[0];
}
