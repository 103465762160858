import * as React from "react";
import * as router from "connected-react-router";
import { connect } from "react-redux";
import { actions as loginactions } from "redux/loginRedux";
import * as CSSModules from "react-css-modules";
import * as styles from "./styles.scss";
import Button from "components/Button/Button";
import { CommonComponentProps } from "redi-types";
import { string, number, MergeStyles } from "utils";

@MergeStyles(styles)
export default class Drawer extends React.PureComponent<Props, State> {
	static defaultProps = {
		width: 300,
		drawerClass: "",
		zIndex: 5,
		floatOn: "",
		position: "left",
		closeOnOutsideClick: true
	};

	render() {
		let style: React.StyleHTMLAttributes<HTMLDivElement> = { width: this.props.width + "px" };
		if (!this.props.open) {
			if (this.props.position === "left") {
				style.right = this.props.width;
			} else {
				style.left = this.props.width;
			}
		}

		return (
			<React.Fragment>
				{this.props.onClose && this.props.open && (
					<div
						styleName={`_close-area ${this.props.floatOn}`}
						onClick={this.props.onClose}
						style={{ zIndex: this.props.zIndex - 1 }}
					/>
				)}
				<div
					styleName={`_root ${this.props.floatOn}`}
					open={this.props.open}
					position={this.props.position}
					className={this.props.className}
					style={style}
				>
					<div styleName={`_drawer ${this.props.drawerClass}`} style={{ zIndex: this.props.zIndex, width: this.props.width }}>
						{this.props.children}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

interface Props extends CommonComponentProps {
	open: boolean;
	/**
	 * What breakpoint should this div be floated
	 */
	floatOn?: "sm" | "md" | "xs" | "lg" | "always";
	width?: number;
	position: "left" | "right";
	onClose?: (e?: React.MouseEvent<HTMLDivElement>) => void;
	children: JSX.Element | JSX.Element[];
	drawerClass?: string;
	zIndex?: number;
}

interface State {}
