import { getState } from "boot/configureStore";
import { string } from "utils/index";
// import { BaseAuthConfType } from "./Auth";
import config from "config/config";
import { ReduxState } from "config/reduxRoot";

/**
 *
 * @param {string[]} roles array
 * @param {object} authConf
 */
export function __authImp(roles: string | string[], authConf: any ) {
	if (authConf.disable) {
		return true;
	}

	const state = getState((state: ReduxState) => ({
		route: state.router.location.pathname,
		isLoggedIn: state.login.isLoggedIn,
		loaded: state.login.loaded,
		currentDetails: state.login.currentDetails,
		roles: state.login.currentDetails.RoleClaims
	}));

	//If roles were passed in then it means the auth check needs to only check them, ignore default roles.
	let rolesarr = [];
	if (!roles) {
		rolesarr = config.defaultRoles.concat(roles || []);
	} else if (typeof roles === "string") {
		rolesarr = [roles];
	} else {
		rolesarr = roles;
	}

	let authed = true;

	if (!state.isLoggedIn && authConf.checkLogin) {
		authed = false;
	}

	if (authed && rolesarr && rolesarr.length) {
		authed = rolesarr.some(x => state.roles.some(d => d.toLowerCase() === x.toLowerCase()));
	}

	return authed;
}
