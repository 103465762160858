import * as React from "react";

import * as styles from "./styles.scss";

import * as CSSModules from "react-css-modules";

import {
	FormVersionDto,
	FormDataField,
	FormDataSection,
	FormSubmissionData,
	FormSubmissionField,
	FormMatrixRow,
	AssetDto
} from "redi-types";
import { WrappedFieldArrayProps, FieldArray } from "redux-form";
import { FormEntryContext } from "./FormEntry";
import { RenderFormFields, RenderFormFieldsProps } from "./RenderField";
import { string, array, createFormSubmissionField, number } from "utils";
import Button from "components/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PortalAnchor, Portal } from "components/Portal/portal";
import { IFormEntryContext } from "./Hoc";

/**
 * render sections
 */
@CSSModules(styles, { allowMultiple: true })
export class RenderFormSections extends React.PureComponent<{} & WrappedFieldArrayProps<any>, {}> {
	static contextType = FormEntryContext;
	context: IFormEntryContext;
	path: string;

	constructor(props){
		super(props);
		let showSections = {
		};
		if(this.props[0] && this.props[0].linkedFormSections){		
			let linkedSectionIds: Array<string> = this.props[0].linkedFormSections.split(',');
			for(var ii = 0; ii < linkedSectionIds.length; ii++){
				showSections[linkedSectionIds[ii]] = true;
			}
		}
		this.state = {
			showSections: showSections
		};
		this.toggleSection = this.toggleSection.bind(this);
	}

	registerSection(section: FormDataSection) {
		if (!this.context.fieldIsRegistered(this.path)) {
			this.context.registerField(this.path, {
				id: section.id,
				logic: section.logic,
				actions: null,
				repeatingGroupRow: null
			});
		}
	}

	componentWillUnmount() {
		if (this.context.fieldIsRegistered(this.path)) {
			this.context.unRegisterField(this.path);
		}
	}

	toggleSection(sectionId: string){
		this.setState(prevState => {
			let newState = {
				showSections: {...(prevState as any).showSections, [sectionId]: !(prevState as any).showSections[sectionId]}
			};
			return newState;
		});
	}


	render() {
		const {
			fields,
			meta: { error, submitFailed }
		} = this.props;
		return (
			<div>
				{fields.map((member, index) => {
					this.path = member;
					const section: FormDataSection = this.context.currentForm.formDefinition.sections[index];
					let hide: boolean = false;
					if (section.logic) {
						this.registerSection(section);
						const sectionValue: FormSubmissionData["sections"][0] = this.context.getValue(member);
						if (sectionValue.fieldOptions && sectionValue.fieldOptions.hide) {
							return null;
						}
					}
					return (
						<div styleName="section-wrapper" key={index} style={(this.state as any).showSections[section.id] ? {} : {}} >
							<div styleName="section-header" style={{}}
							onClick={(e: any) => this.toggleSection(section.id)}>
								<span>{section.sectionName}</span>
								{(this.state as any).showSections[section.id] ? <span>&#11205;</span> : <span>&#11206;</span>}
							</div>
							<div styleName="section-sections" style={(this.state as any).showSections[section.id] ? {} : {transform: 'translateY(-100%)', height: '0px'} } >
								<FieldArray<RenderFormFieldsProps>
									name={`${member}.fields`}
									component={RenderFormFields}
									props={{
										section: section
									}}
								/>
							</div>
							<div styleName="transition-hack" style={(this.state as any).showSections[section.id] ? {height: '200px'} : {height: '0px'} }>

							</div>
						</div>
					);
				})}
				{submitFailed && error && <div styleName="input-error">{error}</div>}
			</div>
		);
	}
}

export type RenderFormTableFieldsProps = { groupData: FormDataField; path: string; repeatingGroupRow?: number };

/**
 * render repeating group/ matrix outer table and rows
 */
@CSSModules(styles, { allowMultiple: true })
export class RenderFormTableFields extends React.PureComponent<
	RenderFormTableFieldsProps & WrappedFieldArrayProps<any>,
	{
		rowOptions: Array<{ index: number; collapsed: boolean }>;
	}
> {
	static contextType = FormEntryContext;
	context: IFormEntryContext;

	hasColumnOperations: boolean;
	hasRowOperations: boolean;
	ids: number;
	constructor(props, context: IFormEntryContext) {
		super(props);
		this.hasColumnOperations = !!this.props.groupData.subFields
			? this.props.groupData.subFields.some(x => !!x.operations)
			: !!this.props.groupData.matrixFields
			? this.props.groupData.matrixFields.some(x => x.fields.some(f => !!f.operations))
			: false;

		this.hasRowOperations = !!this.props.groupData.matrixFields ? this.props.groupData.matrixFields.some(x => !!x.operations) : false;
		this.ids = 0;
		this.state = {
			rowOptions: []
		};

		context.registerField(this.props.path, {
			id: this.props.groupData.id,
			logic: this.props.groupData.logic,
			actions: this.props.groupData.actions,
			repeatingGroupRow: this.props.repeatingGroupRow
		});

		const actions = this.props.groupData.actions;
		if (actions && actions.length) {
			const performNow = actions.filter(x => x.when === "onLoad");
			for (let index = 0; index < performNow.length; index++) {
				const action = performNow[index];
				context.performAction(this.props.path, action);
			}
		}
	}

	componentWillUnmount() {
		this.context.unRegisterField(this.props.path);
	}

	fieldTypeStyles(field: FormDataField) {
		switch (field.fieldType) {
			case "Date":
				return {
					width: 240
				};
			case "HtmlRender":
			case "Label":
			case "Text":
			case "TextMultiLine":
			case "Dropdown":
			case "DropdownMultiChoice":
			case "Photo":
			case "Radio":
			case "Checkbox":
			case "Number":
			case "GPS":
			case "Time":
			case "ButtonList":
			case "ButtonListMulti":
			case "RepeatingGroup":
			case "YesNo":
			case "YesNoNA":
			case "Signature":
			case "VehicleRegoId":
			default:
				return undefined;
		}
	}

	render() {
		const fieldValues: FormSubmissionField = string.path({ data: this.context.data }, this.props.path);

		if (fieldValues.fieldOptions && fieldValues.fieldOptions.hide) {
			return null;
		}

		if (this.props.groupData.fieldType === "RepeatingGroup") {
			return this.renderRepeatingGroup(fieldValues);
		} else if (this.props.groupData.fieldType === "Matrix") {
			return this.renderMatrix(fieldValues);
		} else {
			throw new Error("type not mapped in render table field type");
		}
	}

	/**returns a structure that can access cells from row->col->cell or from col->row->cell */
	createCellLookup(cells: FormDataField[][], columnCount: number) {
		const lookup = { rows: cells.slice(), columns: array.fill(columnCount, i => new Array<FormDataField>(cells.length)) };
		for (let rowIndex = 0; rowIndex < cells.length; rowIndex++) {
			const row = cells[rowIndex];
			for (let colIndex = 0; colIndex < row.length; colIndex++) {
				const cell = row[colIndex];
				lookup.columns[colIndex][rowIndex] = cell;
			}
		}
		return lookup;
	}

	renderMatrix(fieldValues: FormSubmissionField) {
		const {
			fields,
			meta: { error, submitFailed }
		} = this.props;

		const cells = this.props.groupData.matrixFields.map(x => x.fields);
		const columns = cells.reduce((prev, next) => Math.max(prev, next.length), 0);
		const lookup = this.createCellLookup(cells, columns);

		const isMobile = window.innerWidth < styles.small;
		/**activate the portals that rearrange the ui to make matrix table groupByColumns */
		const isPorted = isMobile && this.props.groupData.groupByColumns && !this.props.groupData.allowAddRows;

		return (
			<div>
				<div>{this.props.groupData.label}</div>
				<table styleName="matrix-table">
					<thead>
						<tr>
							<th>{/* for label column */}</th>
							{array.fill(columns + (this.hasRowOperations ? 1 : 0), index => {
								const field = this.props.groupData.matrixFields[0].fields[index];
								if (field) {
									return (
										<th key={index} style={this.fieldTypeStyles(field)}>
											{field.label} {field.isRequired && field.label && <span> *</span>}
										</th>
									);
								} else {
									return <th key={index} />;
								}
							})}
							{this.props.groupData.allowAddRows && <th styleName="remove-cross" desktop="1" />}
						</tr>
					</thead>
					<tbody useportal={isPorted ? "true" : "false"}>
						{lookup.columns.map((cells, i) => {
							const field = cells.find(x => !!x);
							const portalId = field ? field.id : i.toString();
							const rowOption = this.state.rowOptions.find(ff => ff.index === i);
							const collapsed = rowOption && rowOption.collapsed;

							return (
								//these trs represent the cards when on mobile and groupByColumns is true;
								//one row for each column instead of a row for each row, in the form json template
								<PortalAnchor disable={collapsed} id={portalId} key={i}>
									<tr mobile="1" key={i}>
										<td styleName="card-header">
											<b>{field.label}</b>
											<Toggler
												collapsed={collapsed}
												onChange={val => {
													const rows = this.state.rowOptions.slice();
													const rowIndex = this.state.rowOptions.findIndex(ee => ee.index === i);
													if (~rowIndex) {
														rows[rowIndex] = { ...rows[rowIndex], collapsed: val };
													} else {
														rows.push({ index: i, collapsed: val });
													}
													this.setState({ rowOptions: rows });
												}}
											/>
										</td>
									</tr>
								</PortalAnchor>
							);
						})}

						{fields.map((member, index) => {
							const field: FormDataField = this.props.groupData;
							const row = field.matrixFields[index];
							const hasOp = !!row.operations;
							const rowOption = this.state.rowOptions.find(ff => ff.index === index);
							const collapsed = isMobile && rowOption && rowOption.collapsed;

							return (
								<tr key={index} desktop="1">
									{field.allowAddRows && !field.groupByColumns && (
										<td key="ops1" styleName="remove-cross" mobile="1">
											<div
												onClick={() => {
													fields.remove(index);
												}}
											>
												<FontAwesomeIcon icon="times" />
											</div>
										</td>
									)}
									<td key="label" desktop="1">
										{row.label}
									</td>
									<td key="labelmobile" mobile="1" styleName="card-header">
										<b>{row.label}</b>
										<Toggler
											collapsed={collapsed}
											onChange={val => {
												const rows = this.state.rowOptions.slice();
												const rowIndex = this.state.rowOptions.findIndex(ee => ee.index === index);
												if (~rowIndex) {
													rows[rowIndex] = { ...rows[rowIndex], collapsed: val };
												} else {
													rows.push({ index: index, collapsed: val });
												}
												this.setState({ rowOptions: rows });
											}}
										/>
									</td>
									{!collapsed && (
										<React.Fragment>
											<FieldArray<RenderFormFieldsProps>
												name={member}
												// name={`${member}.fields`}
												component={RenderFormFields}
												props={{
													groupData: field,
													matrixRow: row,
													isPorted,
													portalIds: lookup.columns.map(x => x.find(f => !!f).id)
												}}
											/>
											{!isPorted && (
												//non groupByColumns operations
												<React.Fragment>
													{this.hasRowOperations && (
														<td key="ops2" operations="1" hasop={hasOp ? "true" : "false"}>
															{hasOp &&
																fieldValues &&
																this.renderOp(row, fieldValues.subGroupedValues[index].filter(x => !!x))}
														</td>
													)}
													{/* allowAddRows only applies to non groupByColumns */}
													{field.allowAddRows && (
														<td key="delete" styleName="remove-cross" desktop="1">
															<div
																onClick={() => {
																	fields.remove(index);
																}}
															>
																<FontAwesomeIcon icon="times" />
															</div>
														</td>
													)}
												</React.Fragment>
											)}
										</React.Fragment>
									)}
								</tr>
							);
						})}
					</tbody>
					{this.hasColumnOperations && (
						<tfoot hide={isPorted ? "true" : undefined}>
							<tr>
								{!isMobile && <td>{/* for label column */}</td>}
								{lookup.columns.map((column, index) => {
									const field = column.find(x => x && !!x.operations);
									const hasOp = fieldValues && field && !!field.operations;
									const portalId = column.find(x => !!x).id;

									return (
										<Portal to={portalId} key={index} disable={!isPorted}>
											<td operations="1" key={index} hasop={hasOp ? "true" : "false"}>
												{hasOp &&
													this.renderOp(field, fieldValues.subGroupedValues.map(x => x[index]).filter(x => !!x))}
											</td>
										</Portal>
									);
								})}
								{this.props.groupData.allowAddRows && !isPorted && <td />}
							</tr>
						</tfoot>
					)}

					{this.hasRowOperations && isPorted && (
						<tfoot>
							<tr>
								{!isMobile && <td>{/* for label column */}</td>}
								{this.props.groupData.matrixFields.map((row, index) => {
									const hasOp = fieldValues && row && !!row.operations;
									return (
										<td operations="1" key={index} hasop={hasOp ? "true" : "false"}>
											{hasOp && this.renderOp(row, fieldValues.subGroupedValues[index].filter(x => !!x))}
										</td>
									);
								})}
								{this.props.groupData.allowAddRows && <td />}
							</tr>
						</tfoot>
					)}
				</table>
				{submitFailed && error && <div styleName="input-error">{error}</div>}
				{this.props.groupData.allowAddRows && (
					<Button onClick={() => fields.push(this.props.groupData.subFields.map(createFormSubmissionField))}>Add</Button>
				)}
			</div>
		);
	}

	renderRepeatingGroup(fieldValues: FormSubmissionField) {
		const {
			fields,
			meta: { error, submitFailed }
		} = this.props;

		const isMobile = window.innerWidth < styles.small;

		return (
			<div>
				<div>{this.props.groupData.label}</div>
				<table styleName="repeating-group-table">
					<thead>
						<tr>
							{this.props.groupData.subFields.map((field, index) => {
								return (
									<th key={index} style={this.fieldTypeStyles(field)}>
										{field.label} {field.isRequired && field.label && <span> *</span>}
									</th>
								);
							})}
							{this.props.groupData.allowAddRows && <th styleName="remove-cross" desktop="1" />}
						</tr>
					</thead>
					<tbody>
						{fields.map((member, index) => {
							const field: FormDataField = this.props.groupData;
							const rowOption = this.state.rowOptions.find(ff => ff.index === index);
							const collapsed = isMobile && rowOption && rowOption.collapsed;

							return (
								<tr key={index}>
									{this.props.groupData.allowAddRows && (
										<td styleName="remove-cross" mobile="1">
											<Toggler
												collapsed={collapsed}
												onChange={val => {
													const rows = this.state.rowOptions.slice();
													const rowIndex = this.state.rowOptions.findIndex(ee => ee.index === index);
													if (~rowIndex) {
														rows[rowIndex] = { ...rows[rowIndex], collapsed: val };
													} else {
														rows.push({ index, collapsed: val });
													}
													this.setState({ rowOptions: rows });
												}}
											/>

											<div
												onClick={() => {
													fields.remove(index);
													if (rowOption) {
														const filtered = this.state.rowOptions.filter(gg => gg.index !== index);
														filtered.forEach(w => {
															if (w.index > index) {
																w.index--;
															}
														});
														this.setState({
															rowOptions: filtered
														});
													}
												}}
											>
												<FontAwesomeIcon icon="times" />
											</div>
										</td>
									)}
									{!collapsed && (
										<React.Fragment>
											<FieldArray<RenderFormFieldsProps>
												name={member}
												component={RenderFormFields}
												props={{
													groupData: field,
													repeatingGroupRow: index
												}}
											/>
											{this.props.groupData.allowAddRows && (
												<td styleName="remove-cross" desktop="1">
													<div
														onClick={() => {
															fields.remove(index);
														}}
													>
														<FontAwesomeIcon icon="times" />
													</div>
												</td>
											)}
										</React.Fragment>
									)}
								</tr>
							);
						})}
					</tbody>
					{this.hasColumnOperations && (
						<tfoot>
							<tr>
								{this.props.groupData.subFields.map((field, index) => {
									const hasOp = fieldValues && !!field.operations;
									return (
										<td operations="1" key={index} hasop={hasOp ? "true" : "false"}>
											{hasOp && this.renderOp(field, array.selectMany(fieldValues.subGroupedValues, x => x[index]))}
										</td>
									);
								})}
								{this.props.groupData.allowAddRows && <td />}
							</tr>
						</tfoot>
					)}
				</table>
				{submitFailed && error && <div styleName="input-error">{error}</div>}
				{this.props.groupData.allowAddRows && (
					<Button onClick={() => fields.push(this.props.groupData.subFields.map(createFormSubmissionField))}>Add</Button>
				)}
			</div>
		);
	}

	renderOp(field: FormDataField | FormMatrixRow, datas: FormSubmissionField[]) {
		if (instanceOfField(field)) {
			if (field.operations.countColumn) {
				return (
					<div styleName="operation-content">
						<div styleName="operation-label">{field.label}</div>
						<b>Count: </b>
						<span>{datas.filter(x => x.value || Number(x.value)).length}</span>
					</div>
				);
			} else if (field.operations.sumColumn) {
				return (
					<div styleName="operation-content">
						<div styleName="operation-label">{field.label}</div>
						<b>Total: </b>
						<span>{datas.reduce((prev, next) => (next.value ? prev + next.value : prev), 0)}</span>
					</div>
				);
			}
		} else {
			if (field.operations.countRow) {
				return (
					<div styleName="operation-content">
						<div styleName="operation-label">{field.label}</div>
						<b>Count: </b>
						<span>{datas.filter(x => x.value || Number(x.value)).length}</span>
					</div>
				);
			} else if (field.operations.sumRow) {
				return (
					<div styleName="operation-content">
						<div styleName="operation-label">{field.label}</div>
						<b>Total: </b>
						<span>{datas.reduce((prev, next) => (next.value ? prev + next.value : prev), 0)}</span>
					</div>
				);
			}
		}
	}
}

function instanceOfField(item: any): item is FormDataField {
	return item && "fieldType" in item;
}

class Toggler extends React.PureComponent<{ onChange: (val: boolean) => void; collapsed: boolean }> {
	render() {
		if (this.props.collapsed) {
			return (
				<FontAwesomeIcon
					icon="chevron-up"
					onClick={() => {
						this.props.onChange(false);
					}}
				/>
			);
		} else {
			return (
				<FontAwesomeIcon
					icon="chevron-down"
					onClick={() => {
						this.props.onChange(true);
					}}
				/>
			);
		}
	}
}
