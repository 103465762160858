import { combineReducers, Reducer } from "redux";
import { reducer as formReducer } from "redux-form";
import { all, spawn, put, call, fork } from "redux-saga/effects";
import { connectRouter } from "connected-react-router";

import * as smartTableComponentRedux from "components/SmartTable/redux";
import * as setupRedux from "boot/setupRedux";
import * as loginRedux from "redux/loginRedux";
import * as formRedux from "redux/formRedux";
import * as formSubmissionRedux from "redux/formSubmissionRedux";
import * as clientRedux from "redux/clientRedux";
import * as vehicleRedux from "redux/vehicleRedux";
import * as scheduleRedux from "redux/scheduleRedux";
import * as jobRedux from "redux/jobRedux";
import * as techScheduleRedux from "redux/techScheduleRedux";
import * as companyScheduleRedux from "redux/companyScheduleRedux";
import { GetReturnTypesOfMemberFuncs, PickSingle, UnionToIntersection } from "redi-types";
import { history } from "boot/configureStore";

//any reducers returned by persist(...);
let persistedReducers = [
	loginRedux.reducer,
	formSubmissionRedux.reducer,
	formRedux.reducer,
	clientRedux.reducer,
	vehicleRedux.reducer,
	scheduleRedux.reducer,
	jobRedux.reducer,
	techScheduleRedux.reducer,
	companyScheduleRedux.reducer
];

//standard, non persisted, reducers
let rootReducer = {
	smartTable: smartTableComponentRedux.reducers.reducer,

	form: formReducer,
	router: connectRouter(history),
	setup: setupRedux.reducers.reducer
};

const combineSagas = function*() {
	const sagas = [
		setupRedux.sagas.rootSaga.bind(setupRedux.sagas),
		formSubmissionRedux.sagas.rootSaga.bind(formSubmissionRedux.sagas),
		formRedux.sagas.rootSaga.bind(formRedux.sagas),
		smartTableComponentRedux.sagas.rootSaga.bind(smartTableComponentRedux.sagas),
		loginRedux.sagas.rootSaga.bind(loginRedux.sagas),
		clientRedux.sagas.rootSaga.bind(clientRedux.sagas),
		vehicleRedux.sagas.rootSaga.bind(vehicleRedux.sagas),
		scheduleRedux.sagas.rootSaga.bind(scheduleRedux.sagas),
		jobRedux.sagas.rootSaga.bind(jobRedux.sagas),
		techScheduleRedux.sagas.rootSaga.bind(techScheduleRedux.sagas),
		companyScheduleRedux.sagas.rootSaga.bind(companyScheduleRedux.sagas),
	];


	yield all(
		sagas.map(saga =>
			spawn(function*() {
				let isSyncError = false;
				while (!isSyncError) {
					isSyncError = true;
					try {
						setTimeout(() => (isSyncError = false));
						yield call(saga);
					} catch (e) {
						console.error("Saga Error:", e);
						yield put({ type: "SAGA_FAILED", payload: e });
						if (isSyncError) {
							throw e;
						}
					}
				}
			})
		)
	);
};

export const rootSaga = combineSagas;

const merged = MergeReducers(rootReducer, persistedReducers);

export default combineReducers(merged);

export type ReduxState = GetReturnTypesOfMemberFuncs<typeof merged>


/**
 * extract the names of props from [persisted] and put into root. this is so we dont have to type the name of props in 2 places
 */
function MergeReducers<R, T, U = PickSingle<T>>(root: R, persisted: T[]) {
	let rtns: R & Partial<T> = root;
	for (const pers of persisted) {
		for (let prop in pers) {
			if (pers.hasOwnProperty(prop)) {
				if (rtns[prop]) {
					throw new Error("Reducer prop " + prop + " already exists");
				} else {
					(<any>rtns)[prop] = pers[prop];
				}
			}
		}
	}
	return rtns as unknown as UnionToIntersection<U & R>;
}
