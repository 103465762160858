import * as React from "react";
import config from "config/config";
import { MergeStyles, number, shouldUpdate, http } from "utils";
import * as styles from "./styles.scss";
import * as PropTypes from "prop-types";
import Modal from "components/BaseModal/ModalImpl/ModalImpl";
import { CommonUIProps, CommonComponentProps } from "redi-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import autoBind from "libs/react-autobind";
import mediaservice from "services/media/mediaservice";
import ImageLoader from "../ImageLoader";
import FileUpload from "../file/Upload";
import Button from "components/Button/Button";
import { MediaImage } from "services/media/mediatypes";

@MergeStyles(styles)
export default class ImageViewer extends React.Component<Props, State> {
	static defaultProps = {
		images: [],
		onChange: null,
		allowUpload: false,
		open: false,
		onClose: null,
		startingIndex: 0, //index of image that should be displayed when first opened
		disabled: false,
		classes: null //styleName overrides
	};
	static propTypes = {
		onClose: PropTypes.func.isRequired,
		open: PropTypes.bool.isRequired,
		images: PropTypes.array.isRequired,
		onChange: function(props, propName, componentName) {
			//required if sorting or paging (for sorting)
			const test = props.allowUpload ? PropTypes.func.isRequired : PropTypes.func;
			const result = test.apply(this, arguments);
			if (result) {
				result.message = "The prop `onChange` is required in `ImageViewer` if `allowUpload` is set to true";
			}
			return result;
		}
	};

	constructor(props) {
		super(props);
		autoBind(this);

		this.state = {
			currentImageIndex: this.props.startingIndex
		};
	}

	shouldComponentUpdate(nextProps, nextState) {
		return shouldUpdate(this, nextProps, nextState, ["onChange"]);
	}

	changeIndex(amount: number) {
		let newIndex = (this.state.currentImageIndex + amount) % this.props.images.length;
		if (newIndex < 0) {
			newIndex = this.props.images.length - 1;
		}
		this.setState({ currentImageIndex: newIndex });
	}

	render() {
		if (!this.props.images.length || !this.props.images[this.state.currentImageIndex]) {
			return null;
		}
		const image = this.props.images[this.state.currentImageIndex];
		return (
			<Modal isOpen={this.props.open} onClose={this.props.onClose} rootClass="_imgp_modal" styleName="_impg_modal" classes={styles}>
				<div className={this.props.className} styleName="_imgp_root">
					<div styleName="_imp_content-wrap">
						<div styleName="_imgp_sides">
							{this.props.images.length > 1 && (
								<FontAwesomeIcon icon="chevron-left" size="3x" color="#fff" onClick={x => this.changeIndex(-1)} />
							)}
						</div>
						<div styleName="_imgp_content">
							<ImageLoader
								src={config.amazonUrl + image.amazonUrl}
								fallBackSrc={image.data}
								styleName="_imgp_main"
								spinnerColor="#fff"
							/>
							<div styleName="_impg_name">{image.name}</div>
						</div>
						<div styleName="_imgp_sides-right">
							<div styleName="_impg_buttons">
								<FontAwesomeIcon icon="times" size="3x" color="#fff" onClick={this.props.onClose} />
								{this.props.allowUpload && (
									<FileUpload
										onChange={(files: MediaImage[]) => {
											this.props.onChange(this.props.images.concat(files));
										}}
									>
										<FontAwesomeIcon icon="upload" size="3x" color="#fff" />
									</FileUpload>
								)}
								{!!this.props.onChange && !this.props.disabled && (
									<FontAwesomeIcon
										icon="trash-alt"
										size="3x"
										color="#fff"
										onClick={() => {
											if (this.state.currentImageIndex > 0) {
												this.changeIndex(-1);
											}
											this.props.onChange(this.props.images.filter(x => x !== image));
										}}
									/>
								)}
							</div>
							{this.props.images.length > 1 && (
								<FontAwesomeIcon icon="chevron-right" size="3x" color="#fff" onClick={x => this.changeIndex(1)} />
							)}
						</div>
					</div>
					{this.props.images.length > 1 && (
						<div styleName="_imgp_preview">
							{this.props.images.map((x, i) => {
								return (
									<ImageLoader
										fallBackSrc={x.data}
										key={i}
										src={config.amazonUrl + (x.thumbnailUrl || x.amazonUrl)}
										styleName="_imgp_"
										spinnerColor="#fff"
										onClick={() => this.setState({ currentImageIndex: i })}
									/>
								);
							})}
						</div>
					)}
				</div>
			</Modal>
		);
	}
}

interface Props extends CommonComponentProps {
	images: MediaImage[];
	startingIndex?: number;
	onChange?: (newImgs: MediaImage[]) => void;
	onClose: (e?: Event | React.MouseEvent | React.TouchEvent) => void;
	allowUpload?: boolean;
	open: boolean;
	disabled?: boolean;
}

interface State {
	currentImageIndex: number;
}
