import * as React from "react";
import * as router from "connected-react-router";
import { connect } from "react-redux";
import { actions as loginactions } from "redux/loginRedux";
import * as CSSModules from "react-css-modules";
import * as styles from "./styles.scss";
import Button from "components/Button/Button";
import { Auth } from "components/Auth/Auth";
import config from "config/config";
import { CurrentDetailsDto } from "redi-types";

@CSSModules(styles, { allowMultiple: true })
class SideMenu extends React.PureComponent<Props, {}> {
	render() {
		const { route } = this.props;
		return (
			<div styleName="root">
				{this.props.isLoggedIn ? (
					<React.Fragment>
						<Button
							styleName="btn"
							raised={false}
							onClick={() => {
								this.props.navigate("/Form/List");
								this.props.onClose();
							}}>
							<span>Forms</span>
						</Button>
						{!!this.props.currentDetails.ClientId && 
						<Button
							styleName="btn"
							raised={false}
							onClick={() => {
								window.location = config.clientSiteUrl;
								this.props.onClose();
							}}>
							<span>Momentum</span>
						</Button>}
						<Button
							styleName="btn"
							raised={false}
							onClick={() => {
								this.props.navigate("/Account");
								this.props.onClose();
							}}
						>
							<div>
								<span>Account</span>
							</div>
						</Button>
						<Button
							styleName="btn"
							raised={false}
							onClick={() => {
								this.props.logout();
								this.props.onClose();
							}}
						>
							<div>
								<span>Logout</span>
							</div>
						</Button>
					</React.Fragment>
				) : (
					<Button
						styleName="btn"
						raised={false}
						onClick={() => {
							this.props.navigate("/Login");
							this.props.onClose();
						}}
					>
						<div>
							<span>Login</span>
						</div>
					</Button>
				)}
			</div>
		);
	}
}

const bindAction = dispatch => {
	return {
		logout: () => dispatch(loginactions.logout()),
		navigate: (uri, data) => dispatch(router.push(uri, data)),
		goBack: () => dispatch(router.goBack())
	};
};

const mapStateToProps = state => ({
	isLoggedIn: state.login.isLoggedIn,
	currentDetails: state.login.currentDetails,
});

export default connect(
	mapStateToProps,
	bindAction
)(SideMenu);

interface Props {
	route?: string;
	isLoggedIn: boolean;
	logout: () => void;
	onClose: () => void;
	navigate: (uri: string, data?: any) => void;
	goBack: () => void;
	currentDetails: CurrentDetailsDto;
}
